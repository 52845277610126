import React, {useEffect, useState} from "react";
import {Modal, ModalBody, Button, ModalHeader, Input} from "reactstrap";
import {CloseButton, ModalFooter} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useParams, Link} from "react-router-dom";

function DriverRuleModal({options, isDriver, modalShow, setModalShow}) {

    const {t} = useTranslation();
    const params = useParams();

    const [data, setData] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);

    useEffect(() => {
        if(options.length){
            setData(options);
            setSelectedValue(options[0]._id)
        }
    }, [options])

    const toggleModal = () => {
        setModalShow(!modalShow)
    }

    return (
        <Modal isOpen={modalShow} className="driver-rule-modal-container">
            <ModalHeader className='driver-rule-modal pe-5 pt-4'>
                <div>{isDriver ? t("drivers.addDriver") : t("rules.addRule")}</div>
                <CloseButton onClick={toggleModal} variant="white"/>
                <div>
                    <i className='bx bx-hdd bx-md'
                       style={{
                           position: 'absolute',
                           bottom: '-32px',
                           left: '20px',
                           backgroundColor: '#EFF2F7',
                           padding: '13px',
                           borderRadius: '50%',
                           height: '62px',
                           width: '62px'
                       }}
                    />
                </div>
            </ModalHeader>

            <ModalBody>
                <div className='mt-5 mb-3 ps-4 pe-4'>
                    <div><label className='mb-2 p-0' htmlFor={isDriver ? 'driver' : 'rule'}>{isDriver ? t('drivers.add.form.name') : t('rules.add.form.name')}</label></div>
                    <div className='mt-1 col-12'>
                        <div>
                            <Input
                                style={{color: '#74788D'}}
                                type='select'
                                name={isDriver ? 'driver' : 'rule'}
                                id={isDriver ? 'driver' : 'rule'}
                                className='form-control form-select'
                                value={selectedValue}
                                onChange={(e)=> setSelectedValue(e.target.value)}
                            >
                                {data.map((item) => (
                                    <option key={( isDriver ? 'driver' : 'rule')+ "-" +item._id} value={item._id}>{item.name}</option>
                                ))}
                            </Input>
                        </div>
                    </div>
                </div>
            </ModalBody>

            <ModalFooter>
                <div className="text-end form-btn-grp pe-4 mt-1 mb-1">
                    <Link to={`/system/${params.systemId}/${isDriver ? 'driver' : 'rule'}/add/${isDriver ? 'driver' : 'rule'}-config/${selectedValue}`}><Button color="primary" className="me-2">{t("main.continue")}</Button></Link>
                    <Button color="secondary" onClick={toggleModal}>{t("main.cancel")}</Button>
                </div>
            </ModalFooter>

        </Modal>);
}

export default DriverRuleModal