import React, {useEffect, useRef, useState} from 'react'
import {Col, Button, Card, CardBody, CardTitle} from 'reactstrap'
import {Popover, OverlayTrigger} from "react-bootstrap";
import moment from 'moment'

import {ReactComponent as Sensor} from '../../../assets/images/hotspot.svg'
import {useTranslation} from "react-i18next";

const BoatViews = React.memo(props => {

    const {t} = useTranslation();
    const imgRef = useRef(null);

    const [isUpperView, setIsUpperView] = useState(true);
    const [preview, setPreview] = useState({
        topView: null,
        sideView: null
    });

    useEffect(()=>{
        if(props.shipData){
            let topView;
            let sideView;

            if(props.shipData.topView){
                topView = URL.createObjectURL(props.shipData.topView.data)
            }
            if(props.shipData.sideView){
                sideView = URL.createObjectURL(props.shipData.sideView.data)
            }

            setPreview({
                topView: topView ? topView : preview.topView,
                sideView: sideView ? sideView : preview.sideView,
            })
        }
    }, [JSON.stringify(props.shipData)])

    const popover = (item) => {
        return (
            <Popover id={"popover-basic-" + item._id}>
                <Popover.Header as="h3">{item.name}</Popover.Header>
                <Popover.Body>
                    {item.state &&
                        <>
                            <div><span className='fw-bold'>Ultima lettura:</span>
                                <span>{" "}{item.state.value} {item.uom ? item.uom : ''}</span></div>
                            <div>
                                <span>{moment.utc(item.state.time, 'YYYY-MM-DDTHH:mm:ss').local().format('YYYY-MM-DD, HH:mm')}</span>
                            </div>
                        </>
                    }
                </Popover.Body>
            </Popover>
        )
    };

    const RenderSensorPlaces = ({view}) => {
        if (props.shipData?.devicesMap) {
            const Data = props.shipData.devicesMap.map((item, id) => {
                let sensorPlace = item.metadata ? item.metadata : null;
                if (sensorPlace) {
                    return (
                        <OverlayTrigger key={id} placement="top" overlay={popover(item)}>
							<span id='Popovertop' style={{
                                top: view === 'topView' ? sensorPlace.topViewTop + '%' : sensorPlace.sideViewTop + '%',
                                left: view === 'topView' ? sensorPlace.topViewLeft + '%' : sensorPlace.sideViewLeft + '%',
                                animationDuration: (Math.floor(Math.random() * 10) + 10) + 's',
                                WebkitAnimationDuration: (Math.floor(Math.random() * 10) + 10) + 's'
                            }} className='sensor-placements p-0'>
								<Sensor
                                    className={item.alertStatus === 'warning' ? 'sensor-orange' : item.alertStatus === 'danger' ? 'sensor-red' : ''}
                                />
							</span>
                        </OverlayTrigger>
                    )
                }
            })
            return (
                <div className='sensors'>
                    {Data}
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <Card className='boat-card'>
            <CardBody style={{marginTop: 2}} className='d-flex justify-content-between flex-column'>
                <CardTitle style={{textAlign: 'left'}}>{t("dashboard.views.topic")}</CardTitle>
                <div className='text-center'>
                    <div style={{position: 'relative', margin:'auto' }}>
                        <img ref={imgRef} style={{maxHeight: '370px', minWidth: '100%'}} src={isUpperView ? preview.topView : preview.sideView }
                             alt={isUpperView ? "Boat Upper View" : "Boat Side View"}
                             className={isUpperView ? "img-fluid img-upperview" : "img-fluid img-sideview"}/>
                        <RenderSensorPlaces view={isUpperView ? 'topView' : 'sideView'}/>
                    </div>
                </div>
                <div className='text-end'>
                    <Button className='me-2' onClick={() => {setIsUpperView(true)}} color='primary' outline={isUpperView ? false : true}>{t("dashboard.views.topView")}</Button>
                    <Button onClick={() => {setIsUpperView(false)}} color='primary' outline={!isUpperView ? false : true}>{t("dashboard.views.sideView")}</Button>
                </div>
            </CardBody>
        </Card>
    )
})

export default BoatViews;