import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CardBody, Card} from "reactstrap";

import Layout from "../../layout/layout";
import DeviceForm from "../../components/forms/DeviceForm";
import Breadcrumb from "../../components/common/Breadcrumb";

import {getAvailableDeviceTypes, getDeviceData, updateDevice} from "../../redux/actions/deviceActions";
import {getAvailableSystemAreas} from "../../redux/actions/systemAreaActions";
import {useDispatch, useSelector} from "react-redux";
import {config} from "../../config";
import {getSystemData} from "../../redux/actions/systemActions";

const DeviceEdit = () => {
    const TARGET_CODE = config.TARGET_CODE;

    const {t} = useTranslation()
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const deviceData = useSelector((el)=> el.deviceData.deviceData);
    const available = useSelector((el) => el.systemData.availableList)
    const errorData = useSelector((el) => el.loadingData.error);
    const systemareas = useSelector((el)=> el.systemAreaData.availableSystemAreas);
    const deviceTypes = useSelector((el)=> el.deviceData.availableDeviceTypes);
    const systemData = useSelector((el) => el.systemData.systemData)

    const [formState, setFormState] = useState({
        driver: '',
        deviceType: '',
        name: '',
        slugId: '',
        // systemArea: '',
        areas: [],
        topViewTop: '',
        topViewLeft: '',
        sideViewTop: '',
        sideViewLeft: '',
        geoLocation: null
    })

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            history.push(`/systems`)
        } else {
            if(!systemareas.length)
                dispatch(getAvailableSystemAreas(params.systemId))
            if(!deviceTypes.length)
                dispatch(getAvailableDeviceTypes(params.systemId))

            dispatch(getDeviceData(params.deviceId,params.systemId));
            dispatch(getSystemData(params.systemId))
        }
    }, []);

    useEffect(() => {
        if (errorData && errorData.code === 404) {
            history.push(`/system/${params.systemId}/devices`)
        }
    }, [errorData])

    useEffect(() => {
        if (deviceData) {
            let commonData = {};

            commonData._id = deviceData._id;
            commonData.driver = deviceData.driverconfiguration?.driver?.name;
            commonData.deviceType = deviceTypes.find((el)=> el._id === deviceData.devicetype)?.name;
            commonData.name = deviceData.name;
            commonData.slugId = deviceData.slug_id;

            if (TARGET_CODE === 'PLT') {
                setFormState({
                    ...formState,
                    ...commonData,
                    // systemArea: (deviceData.metadata && deviceData.metadata.systemarea) ? deviceData.metadata.systemarea._id : "",
                    areas: (deviceData.metadata?.systemareas) ? deviceData.metadata.systemareas : [],
                    geoLocation: deviceData?.metadata?.geoposition ? {
                        lat: deviceData?.metadata?.geoposition?.coordinates[0],
                        lng : deviceData?.metadata?.geoposition?.coordinates[1]
                    } : null
                })
            } else {
                setFormState({
                    ...formState,
                    ...commonData,
                    topViewTop: deviceData?.metadata?.topViewTop,
                    topViewLeft: deviceData?.metadata?.topViewLeft,
                    sideViewTop: deviceData?.metadata?.sideViewTop,
                    sideViewLeft: deviceData?.metadata?.sideViewLeft,
                })
            }
        }
    }, [deviceData])

    const onSaveClick = (e) => {
        e.preventDefault();

        let data = {};
        data._id = formState._id;

        if(TARGET_CODE === 'PLT'){
            let geoposition = {};
            if(formState.geoLocation) {
                geoposition.type = 'Point';
                geoposition.coordinates = [formState.geoLocation?.lat, formState.geoLocation?.lng]
            }

            // data.systemarea = formState.systemArea
            data.systemareas = formState.areas.map((el)=> {return el._id});;
            data.geoposition = formState.geoLocation ? geoposition : ""
        } else {
             data.topViewTop = formState.topViewTop;
             data.topViewLeft = formState.topViewLeft;
             data.sideViewTop = formState.sideViewTop;
             data.sideViewLeft = formState.sideViewLeft
        }

        dispatch(updateDevice(data, TARGET_CODE !== 'PLT', params.systemId)).then((res)=> {
            if (res) {
                history.push(`/system/${params.systemId}/devices`)
            }
        })
    }

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t('devices.editDevice')}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/devices`, name: t('devices.breadcrumb1')},
                        {
                            path: `system/${params.systemId}/device/edit/${params.deviceId}`,
                            name: t('devices.breadcrumbEdit')
                        }
                    ]}
                />
                <Card>
                    <CardBody>
                        <DeviceForm
                            systemPolygon={systemData?.metadata?.geoposition?.coordinates[0]}
                            systemareas={systemareas}
                            onSaveClick={onSaveClick}
                            formState={formState}
                            setFormState={setFormState}
                        />
                    </CardBody>
                </Card>
            </div>
        </Layout>
    )
}

export default DeviceEdit;