import React, {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import {Container} from "react-bootstrap";
import {useSelector} from "react-redux";


const TableFirst = (props) => {

    const isLoading = useSelector((el)=> el.loadingData.tableLoading)
    const [pageNo, setPageNo] = useState(null);


    useEffect(()=>{
        if(Math.ceil(props.total/5) < pageNo){
            setPageNo(Math.ceil(props.total/5));
            props.getDataList(Math.ceil(props.total/5));
        }
    }, [props.total])


    const pagination = paginationFactory({
        sizePerPage: 5,
        page:pageNo ? pageNo : 1,
        withFirstAndLast: false,
        alwaysShowAllBtns: true,
        hideSizePerPage:true,
        totalSize:props.total
    });

    const onTableChange=(name, e)=>{
        setPageNo(e.page);
        props.getDataList(e.page)
    }

    return(
        <Container
            fluid={true}
            className={'table-container table-container-table1 ' + props.containerClass}
        >
            <BootstrapTable
                keyField='_id'
                data={props.dataList}
                columns={props.columns}
                pagination={pagination}
                bordered={false}
                onTableChange={ onTableChange }
                remote
                loading={isLoading}
                noDataIndication={<></>}
                overlay={
                    overlayFactory({
                        spinner: true,
                        styles: {
                            spinner: (base) => ({...base, '& svg circle': {stroke: '#556ee6'}, width:'50px', marginTop:20}),
                            overlay: (base) => ({...base, background: 'rgba(255,255,255,1)'})
                        }
                    })
                }
                classes="table-responsive table-common"
            />
        </Container>
    )
}

export default TableFirst;