import React, {useState, useEffect} from "react";
import {Card, CardBody, Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useParams, Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import SearchBar from "../../components/others/SearchBar";
import TableFirst from "../../components/tables/TableFirst";

import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";
import DeleteModal from "../../components/modals/DeleteModal";
import {deleteSystemPlant, getSystemPlantList} from "../../redux/actions/systemPlantActions";
import moment from "moment";

const PlantsList = () => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const role = useSelector((state) => state.authData?.userDetails?.role)
    const available = useSelector((el) => el.systemData.availableList)
    const systemPlantData = useSelector((el)=> el.systemPlantData)

    const [deleteId, setDeleteId] = useState(null)
    const [plantSearch, setPlantSearch] = useState('')

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            history.push(`/systems`)
        } else {
            dispatch(getSystemPlantList(params.systemId,1, null))
        }
    }, [location.pathname])

    const getDataList=(page)=>{
        dispatch(getSystemPlantList(params.systemId, page, plantSearch ? plantSearch : null))
    }

    const onDeletePlant=()=>{
        let id = deleteId;
        setDeleteId(null)
        dispatch(deleteSystemPlant(id, params.systemId))
    }

    const onPlantSearch=()=>{
        dispatch(getSystemPlantList(params.systemId,1, plantSearch))
    }

    const tableColumns = [
        {
            key: "details",
            text: "",
            dataField: "name",
            headerStyle: {backgroundColor: "white"},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex">
                        <div className="me-3 d-flex align-items-center">
                            <i className='bx bxs-tree pe-4 bx-md text-semi-dark'/>
                        </div>
                        <div className="row-details">
                            <div className='text-new-dark fw-bold font-size-13'>{row.name}</div>
                            <div>
                                <div className='fw-light text-semi-dark font-size-12'>{t("main.category")}: {row.category?.name}</div>
                                <div className='fw-light text-semi-dark font-size-12'>{t("main.lastUpdate")}: {moment(row.updatedAt).format('YY/MM/DD H:m')}</div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            key: "edit-delete-save",
            text: "",
            dataField: "",
            style: {textAlign: "right"},
            headerStyle: {backgroundColor: "white", boxShadow: "0"},
            formatter: (rowContent, row) => {
                return (
                    <div className="text">
                        <Link to={`/system/${params.systemId}/plant/view/${row._id}`}><Button color="primary">{t("main.view")}</Button></Link>
                        {(role === 'admin' || role.includes('supervisor')) && <Link to={`/system/${params.systemId}/plant/edit/${row._id}`}><Button color="warning" className="ms-2">{t("main.edit")}</Button></Link> }
                        {(role === 'admin' || role.includes('supervisor')) && <Button onClick={()=> setDeleteId(row._id)} color='danger' className="ms-2">{t("main.delete")}</Button> }
                    </div>
                );
            },
        },
    ];

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t('plants.title')}
                    systemData={{id: params.systemId, name: available.find((el) => el._id === params.systemId)?.name}}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/plants`, name: t('plants.breadcrumb1')}
                    ]}
                />
                <Card>
                    <CardBody>
                        <div>
                            <SearchBar
                                name="plant"
                                btnName={t("plants.add")}
                                link={`system/${params.systemId}/plant`}
                                searchValue={plantSearch}
                                setSearchValue={setPlantSearch}
                                addDisabled={role === 'customer' || role === 'guest'}
                                onSearch={onPlantSearch}
                            />
                        </div>
                        <TableFirst
                            dataList={systemPlantData.systemPlantList}
                            columns={tableColumns}
                            total={systemPlantData.totalSize}
                            getDataList={getDataList}
                            containerClass={'header-less-table-container'}
                            rowBorder={true}
                        />
                    </CardBody>
                </Card>
            </div>

            <DeleteModal
                onDelete={onDeletePlant}
                modalShow={!!deleteId}
                setModalShow={setDeleteId}
            />

        </Layout>
    );
}

export default PlantsList;
