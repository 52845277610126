import {systemActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getAvailableSystemsList = () => async dispatch => {
    dispatch({type: systemActionTypes.GET_AVAILABLE_SYSTEMS_REQUEST})

    try {
        const res = await request({
            url: `core/ui/systems/available`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: systemActionTypes.GET_AVAILABLE_SYSTEMS_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: systemActionTypes.GET_AVAILABLE_SYSTEMS_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getSystemList = (page, search) => async dispatch => {
    dispatch({type: systemActionTypes.GET_SYSTEM_LIST_REQUEST})

    try {
        const res = await request({
            url: `core/ui/systems?page=${page}&limit=5&${search ? 'searchText=' + search : ''}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: systemActionTypes.GET_SYSTEM_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: systemActionTypes.GET_SYSTEM_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getSystemData = (id) => async dispatch => {
    dispatch({type: systemActionTypes.GET_SYSTEM_DATA_REQUEST})

    try {
        const res = await request({
            url: `core/ui/systems/${id}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: systemActionTypes.GET_SYSTEM_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: systemActionTypes.GET_SYSTEM_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const addSystem = (Data) => async dispatch => {
    dispatch({type: systemActionTypes.CREATE_SYSTEM_REQUEST});

    let data = new FormData();

    data.append('name', Data.name);
    data.append('description', Data.description);
    data.append('customer', Data.customer);
    data.append('supervisor', Data.supervisor);
    data.append('metadata', JSON.stringify(Data.metadata));

    if(Data.topview)
        data.append('topview', Data.topview);
    if(Data.sideview)
        data.append('sideview', Data.sideview);

    try {
        const res = await request({
            url: `core/ui/systems`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: systemActionTypes.CREATE_SYSTEM_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: systemActionTypes.CREATE_SYSTEM_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const updateSystem = (Data) => async dispatch => {
    dispatch({type: systemActionTypes.UPDATE_SYSTEM_REQUEST});

    let data = new FormData();
    console.log(Data)

    data.append('name', Data.name);
    data.append('description', Data.description);
    data.append('customer', Data.customer);
    data.append('supervisor', Data.supervisor);
    data.append('metadata', JSON.stringify(Data.metadata));

    if(Data.topView)
        data.append('topview', Data.topView);
    if(Data.sideView)
        data.append('sideview', Data.sideView);

    try {
        const res = await request({
            url: `core/ui/systems/${Data._id}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: systemActionTypes.UPDATE_SYSTEM_SUCCEED,
            payload: res
        });
        return true;

    } catch (err) {
        dispatch({
            type: systemActionTypes.UPDATE_SYSTEM_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const deleteSystem = (id) => async dispatch => {
    dispatch({type: systemActionTypes.DELETE_SYSTEM_REQUEST});

    try {
        const res = await request({
            url: `core/ui/systems/${id}`,
            auth: true,
            method: 'DELETE',
        });

        dispatch({
            type: systemActionTypes.DELETE_SYSTEM_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: systemActionTypes.DELETE_SYSTEM_FAILED,
            payload: err ? err.data : null
        });
    }
}
