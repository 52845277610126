export const GET_DEVICE_LIST_REQUEST = 'GET_DEVICE_LIST_REQUEST';
export const GET_DEVICE_LIST_SUCCEED = 'GET_DEVICE_LIST_SUCCEED';
export const GET_DEVICE_LIST_FAILED = 'GET_DEVICE_LIST_FAILED';

export const GET_AVAILABLE_DEVICE_TYPES_REQUEST =
  'GET_AVAILABLE_DEVICE_TYPES_REQUEST';
export const GET_AVAILABLE_DEVICE_TYPES_SUCCEED =
  'GET_AVAILABLE_DEVICE_TYPES_SUCCEED';
export const GET_AVAILABLE_DEVICE_TYPES_FAILED =
  'GET_AVAILABLE_DEVICE_TYPES_FAILED';

export const GET_DEVICE_DATA_REQUEST = 'GET_DEVICE_DATA_REQUEST';
export const GET_DEVICE_DATA_SUCCEED = 'GET_DEVICE_DATA_SUCCEED';
export const GET_DEVICE_DATA_FAILED = 'GET_DEVICE_DATA_FAILED';

export const GET_DEVICE_HISTORY_REQUEST = 'GET_DEVICE_HISTORY_REQUEST';
export const GET_DEVICE_HISTORY_SUCCEED = 'GET_DEVICE_HISTORY_SUCCEED';
export const GET_DEVICE_HISTORY_FAILED = 'GET_DEVICE_HISTORY_FAILED';

export const GET_ALL_DEVICES_HISTORY_REQUEST =
  'GET_ALL_DEVICES_HISTORY_REQUEST';
export const GET_ALL_DEVICES_HISTORY_SUCCEED =
  'GET_ALL_DEVICES_HISTORY_SUCCEED';
export const GET_ALL_DEVICES_HISTORY_FAILED = 'GET_ALL_DEVICES_HISTORY_FAILED';

export const GET_DEVICE_HISTORY_EXPORT_REQUEST =
  'GET_DEVICE_HISTORY_EXPORT_REQUEST';
export const GET_DEVICE_HISTORY_EXPORT_SUCCEED =
  'GET_DEVICE_HISTORY_EXPORT_SUCCEED';
export const GET_DEVICE_HISTORY_EXPORT_FAILED =
  'GET_DEVICE_HISTORY_EXPORT_FAILED';

export const UPDATE_DEVICE_REQUEST = 'UPDATE_DEVICE_REQUEST';
export const UPDATE_DEVICE_SUCCEED = 'UPDATE_DEVICE_SUCCEED';
export const UPDATE_DEVICE_FAILED = 'UPDATE_DEVICE_FAILED';
