function calculateMapCenter(arr){
    let xSum = 0;
    let ySum = 0;
    for(let i=0; i<arr.length; i++){
        xSum += arr[i][0];
        ySum += arr[i][1];
    }

    let obj = {};
    obj.lat = xSum/arr.length;
    obj.lng = ySum/arr.length;

    return obj;
}

export default calculateMapCenter;