import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CardBody, Card} from "reactstrap";

import Layout from "../../layout/layout";
import AreaForm from "../../components/forms/AreaForm";
import Breadcrumb from "../../components/common/Breadcrumb";

import {useDispatch, useSelector} from "react-redux";
import {addSystemArea, getSystemAreaData, updateSystemArea} from "../../redux/actions/systemAreaActions";
import {getSystemData} from "../../redux/actions/systemActions";


const AreaAddEdit = ({isEdit}) => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [formState, setFormState] = useState({
        name: '',
        paths: [],
        mapCenter: null,
        geoLocation: '',
    })

    const available = useSelector((el) => el.systemData.availableList)
    const systemAreaData = useSelector((el)=> el.systemAreaData.systemAreaData)
    const errorData = useSelector((el) => el.loadingData.error)
    const systemData = useSelector((el) => el.systemData.systemData)


    useEffect(() => {
        if(isEdit){
            if(!available.some((el) => el._id === params.systemId)){
                history.push(`/systems`)
            } else {
                dispatch(getSystemAreaData(params.areaId, params.systemId))
            }
        }
        dispatch(getSystemData(params.systemId))
    }, [])

    useEffect(() => {
        if (systemAreaData && isEdit) {
            setFormState({
                ...formState,
                _id: systemAreaData._id,
                name: systemAreaData.name,
                description: systemAreaData.description,
                paths: systemAreaData.metadata?.geoposition?.coordinates[0].map((el)=>{
                    return{
                        lat: Number(el[0]),
                        lng: Number(el[1])
                    }
                })
            })
        }
    }, [systemAreaData])

    useEffect(() => {
        if (errorData && errorData.code === 404 && isEdit) {
            history.push(`/system/${params.systemId}/areas`)
        }
    }, [errorData])


    const onSaveClick = (e) => {
        e.preventDefault();

        let data = {};
        data.name = formState.name;
        data.description = formState.description;

        let mainCoordinates = [];
        let coordinates = [];
        for(let i=0; i<formState.paths.length; i++){
            let lat = formState.paths[i].lat;
            let lng = formState.paths[i].lng;
            coordinates.push([lat, lng])
        }
        mainCoordinates.push(coordinates)

        let metadata = {};
        metadata.geoposition = {
            type: 'Polygon',
            coordinates: mainCoordinates
        }

        data.metadata = metadata;

        if(formState._id){
            data._id = formState._id;
            dispatch(updateSystemArea(data, params.systemId)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/areas`)
                }
            })
        } else {
            dispatch(addSystemArea(data, params.systemId)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/areas`)
                }
            })
        }
    }

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={isEdit ? t('areas.editArea') : t('areas.addArea')}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/areas`, name: t('areas.breadcrumb1')},
                        {
                            path: isEdit ? `system/${params.systemId}/area/edit/${params.areaId}` : `system/${params.systemId}/areas/add`,
                            name: t(isEdit ? 'areas.breadcrumbEdit' : 'areas.breadcrumbAdd')
                        }
                    ]}
                />

                <Card>
                    <CardBody>
                        <AreaForm
                            systemPolygon={isEdit ? null : systemData?.metadata?.geoposition?.coordinates[0]}
                            onSaveClick={onSaveClick}
                            formState={formState}
                            setFormState={setFormState}
                        />
                    </CardBody>
                </Card>
            </div>
        </Layout>
    )
}

export default AreaAddEdit;