import TopView from '../assets/images/upper_view.png'
import SideView from '../assets/images/side_view.png'

export const userData = [
    {id: 1, firstName: "John", email: 'test1@test2.com', lastName: "Doe", role: "admin"},
    {id: 2, firstName: "Johny", email: 'test1test@test2.com', lastName: "Hans", role: "customer"},
    {id: 3, firstName: "Chris", email: 'test1user@test2.com', lastName: "Evans", role: "admin"},
]


export const systemData = [
    {
        id: 1,
        name: "System 01",
        customer: "user1",
        supervisor: "user2",
        systemToken: "AHU765RSVG0",
        paths: [
            {lat: 43.504308519844486, lng: 10.372860888671873},
            {lat: 43.5102846981171, lng: 10.935910205078123},
            {lat: 43.8499486244112, lng: 10.930417041015623},
            {lat: 43.99238964399791, lng: 10.592587451171873},
            {lat: 43.855890475403164, lng: 10.265744189453123},
            {lat: 43.518252015420195, lng: 10.364621142578123}
        ],
        manufacturer: "Manufacturer 1",
        code: "AA34",
        category: 'public',
        topView: TopView,
        sideView: SideView,
        areas: [
            {
                id: 1,
                name: "Area #1",
                paths: [{
                    lat: 43.75281459366609, lng: 10.452511767578123},
                    {lat: 43.621731412737915, lng: 10.452511767578123},
                    {lat: 43.665457634906154, lng: 10.565121630859373}
                ],
                date: "10/10/20 12:15"
            },
            {
                id: 2,
                name: "Area #2",
                paths: [
                    {lat: 43.68532266899139, lng: 10.581601123046873},
                    {lat: 43.56603382897268, lng: 10.576107958984373},
                    {lat: 43.62769595347051, lng: 10.773861865234373}
                ],
                date: "10/10/20 12:15"
            },
            {
                id: 3,
                name: "Area #3",
                paths: [
                    {lat: 43.84400618141477, lng: 10.482724169921873},
                    {lat: 43.76670055233994, lng: 10.548642138671873},
                    {lat: 43.8222121589143, lng: 10.688717822265623}
                ],
                date: "10/10/20 12:15"
            },
        ],
        devices: [
            {
                id: 1,
                name: "Station's Temparature",
                deviceType: "Air Temparature Sensor",
                lastUpdate: "10/10/2020 12:15",
                defaultState: "24.5 C",
                hasGraph: true,
                area: 'area1',
                geoLocation: {lat: 43.6228, lng: 10.3817}
            },
            {
                id: 2,
                name: "Station's Humidity",
                deviceType: "Air Humidity Sensor",
                lastUpdate: "10/10/2020 12:15",
                defaultState: "60%",
                hasGraph: true,
                area: 'area1',
                geoLocation: {lat: 43.7628, lng: 10.4717}
            },
            {
                id: 6,
                name: "Lights",
                deviceType: "Light",
                lastUpdate: "10/10/2020 12:15",
                defaultState: "ON",
                hasGraph: false,
                area: 'area1',
                geoLocation: {lat: 43.7028, lng: 10.4417}
            },
        ],
        ruleEntities: [
            {id: 1, name: "System Health", lastUpdate: "10/10/2020 12:15", state: "60%", graph: true},
            {id: 2, name: "Plant Diseases Prediction", lastUpdate: "10/10/2020 12:15", state: "2%", graph: true},
        ],
        plants: [
            {id: 1, name: "Turf #1", category: "turf", date: "10/10/20 12:15" , geoLocation: {lat: 43.6028, lng: 10.3517}},
            {id: 2, name: "Turf #2", category: "turf", date: "10/10/20 12:15", geoLocation: {lat: 43.5928, lng: 10.3817}},
            {id: 3, name: "Turf #3", category: "turf", date: "10/10/20 12:15", geoLocation: {lat: 43.6728, lng: 10.3017}},
        ],
        orchardRegisters: [
            {
                id: 1,
                title: "Prunning Dangerous Branches",
                date: "2022-03-20T12:15",
                areas: ["area1", "area2"],
                plants: ["turf1", "turf2"]
            },
            {
                id: 2,
                title: "Prunning Dangerous Branches",
                date: "2022-03-20T12:15",
                areas: ["area1", "area2"],
                plants: ["turf2"]
            },
            {id: 3, title: "Prunning Dangerous Branches", date: "2022-03-20T12:15", areas: [], plants: []},
        ],
        drivers: [
            {
                id: 1,
                configId: 1,
                name: "Weather's Station Conf.",
                date: "10/10/20 12:15",
                description: "LoRaWAN Weather Station (WS100LRW/SR) MQTT",
                status: "Pending",
                parameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            },
            {
                id: 2,
                configId: 2,
                name: "Main Light",
                date: "10/10/20 12:15",
                description: "Generic MQTT Light",
                status: "Active",
                parameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            },
            {
                id: 3,
                configId: 3,
                name: "Irrigation Tank 1 to 4",
                date: "10/10/20 12:15",
                description: "Generic MQTT Switch",
                status: "Error",
                messages: [
                    {id: 1, message: 'Sample Error #1'},
                    {id: 2, message: 'Sample Error #2'},
                    {id: 3, message: 'Sample Error #3'},
                ],
                parameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            }
        ],
        rules: [
            {
                id: 1,
                configId: 1,
                name: "Statation's Temparature Threshold",
                date: "10/10/20 12:15",
                description: "Threshold",
                status: "Pending",
                triggerParameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n',
                ruleParameters: '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            },
            {
                id: 2,
                configId: 2,
                name: "Torsion's Variation Check",
                date: "10/10/20 12:15",
                description: "Variation",
                status: "Error",
                messages: [
                    {id: 1, message: 'Sample Error #1'},
                    {id: 2, message: 'Sample Error #2'},
                    {id: 3, message: 'Sample Error #3'},
                ],
                triggerParameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n',
                ruleParameters: '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            },
            {
                id: 3,
                configId: 1,
                name: "Station's Temparature Threshold",
                date: "10/10/20 12:15",
                description: "Threshold",
                status: "Active",
                triggerParameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n',
                ruleParameters: '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            },
        ],
    },
    {
        id: 2,
        name: "System 02",
        customer: "user2",
        supervisor: "user1",
        paths: [
            {lat: 43.504308519844486, lng: 10.372860888671873},
            {lat: 43.5102846981171, lng: 10.935910205078123},
            {lat: 43.8499486244112, lng: 10.930417041015623},
            {lat: 43.99238964399791, lng: 10.592587451171873},
            {lat: 43.855890475403164, lng: 10.265744189453123},
            {lat: 43.518252015420195, lng: 10.364621142578123}
        ],
        areas: [
            {
                id: 1,
                name: "Area #1",
                paths: [{
                    lat: 43.75281459366609, lng: 10.452511767578123},
                    {lat: 43.621731412737915, lng: 10.452511767578123},
                    {lat: 43.665457634906154, lng: 10.565121630859373}
                ],
                date: "10/10/20 12:15"
            },
            {
                id: 2,
                name: "Area #2",
                paths: [
                    {lat: 43.68532266899139, lng: 10.581601123046873},
                    {lat: 43.56603382897268, lng: 10.576107958984373},
                    {lat: 43.62769595347051, lng: 10.773861865234373}
                ],
                date: "10/10/20 12:15"
            },
            {
                id: 3,
                name: "Area #3",
                paths: [
                    {lat: 43.84400618141477, lng: 10.482724169921873},
                    {lat: 43.76670055233994, lng: 10.548642138671873},
                    {lat: 43.8222121589143, lng: 10.688717822265623}
                ],
                date: "10/10/20 12:15"
            },
        ],
        devices: [
            {
                id: 4,
                name: "Irrigation Tank 1",
                deviceType: "Switch",
                lastUpdate: "10/10/2020 12:15",
                defaultState: "OFF",
                hasGraph: true,
                area: 'area1',
                geoLocation: {lat: 43.7228, lng: 10.3097}
            },
            {
                id: 7,
                name: "Dimmable Light",
                deviceType: "Light",
                lastUpdate: "10/10/2020 12:15",
                defaultState: "42%",
                hasGraph: false,
                area: 'area1',
                geoLocation: null
            },
        ],
        ruleEntities: [
            {id: 2, name: "Plant Diseases Prediction", lastUpdate: "10/10/2020 12:15", state: "2%", graph: true},
        ],
        plants: [
            {id: 3, name: "Turf #3", category: "turf", date: "10/10/20 12:15", geoLocation: {lat: 43.7228, lng: 10.4017}},
        ],
        orchardRegisters: [
            {
                id: 4,
                title: "Prunning Dangerous Branches",
                date: "2022-03-20T12:15",
                areas: ["area1"],
                plants: ["turf2"]
            },
            {
                id: 5,
                title: "Prunning Dangerous Branches",
                date: "2022-03-20T12:15",
                areas: ["area1", "area2"],
                plants: ["turf1"]
            },
        ],
        drivers: [
            {
                id: 4,
                configId: 4,
                name: "Atmoo Sensor (Temp & Hum)",
                date: "10/10/20 12:15",
                description: "Atomo MQTT LoraWAN",
                status: "Active",
            },
            {
                id: 5,
                configId: 3,
                name: "Torsion Sensor",
                date: "10/10/20 12:15",
                description: "Torsion MQTT LoraWAN",
                status: "Active",
            }
        ],
        rules: [
            {
                id: 4,
                configId: 1,
                name: "Station's Temparature Threshold",
                date: "10/10/20 12:15",
                description: "Threshold",
                status: "Active",
                triggerParameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n',
                ruleParameters: '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            },
            {
                id: 5,
                configId: 1,
                name: "Station's Temparature Threshold",
                date: "10/10/20 12:15",
                description: "Threshold",
                status: "Active",
                triggerParameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n',
                ruleParameters: '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            }
        ],
    },
    {
        id: 3,
        name: "System 03",
        customer: "user1",
        supervisor: "user2",
        paths: [
            {lat: 43.504308519844486, lng: 10.372860888671873},
            {lat: 43.5102846981171, lng: 10.935910205078123},
            {lat: 43.8499486244112, lng: 10.930417041015623},
            {lat: 43.99238964399791, lng: 10.592587451171873},
            {lat: 43.855890475403164, lng: 10.265744189453123},
            {lat: 43.518252015420195, lng: 10.364621142578123}
        ],
        areas: [
            {
                id: 1,
                name: "Area #1",
                paths: [{
                    lat: 43.75281459366609, lng: 10.452511767578123},
                    {lat: 43.621731412737915, lng: 10.452511767578123},
                    {lat: 43.665457634906154, lng: 10.565121630859373}
                ],
                date: "10/10/20 12:15"
            },
            {
                id: 2,
                name: "Area #2",
                paths: [
                    {lat: 43.68532266899139, lng: 10.581601123046873},
                    {lat: 43.56603382897268, lng: 10.576107958984373},
                    {lat: 43.62769595347051, lng: 10.773861865234373}
                ],
                date: "10/10/20 12:15"
            },
            {
                id: 3,
                name: "Area #3",
                paths: [
                    {lat: 43.84400618141477, lng: 10.482724169921873},
                    {lat: 43.76670055233994, lng: 10.548642138671873},
                    {lat: 43.8222121589143, lng: 10.688717822265623}
                ],
                date: "10/10/20 12:15"
            },
        ],
        devices: [
            {
                id: 6,
                name: "Lights",
                deviceType: "Light",
                lastUpdate: "10/10/2020 12:15",
                defaultState: "ON",
                hasGraph: false,
                area: 'area1',
                geoLocation: {lat: 43.7228, lng: 10.4317}
            },
            {
                id: 5,
                name: "Irrigation Tank 2",
                deviceType: "Switch",
                lastUpdate: "10/10/2020 12:15",
                defaultState: "OFF",
                hasGraph: true,
                area: 'area1',
                geoLocation: {lat: 43.7428, lng: 10.4617}
            }
        ],
        ruleEntities: [
            {id: 1, name: "System Health", lastUpdate: "10/10/2020 12:15", state: "60%", graph: true},
        ],
        plants: [
            {id: 1, name: "Turf #1", category: "turf", date: "10/10/20 12:15", geoLocation:null},
            {id: 4, name: "Turf #4", category: "turf", date: "10/10/20 12:15", geoLocation:null},
        ],
        orchardRegisters: [
            {
                id: 2,
                title: "Prunning Dangerous Branches",
                date: "2022-03-20T12:15",
                areas: ["area1", "area2"],
                plants: ["turf1", "turf2"]
            },
            {id: 3, title: "Prunning Dangerous Branches", date: "2022-03-20T12:15", areas: [], plants: []},
        ],
        drivers: [
            {
                id: 1,
                configId: 1,
                name: "Weather's Station Conf.",
                date: "10/10/20 12:15",
                description: "LoRaWAN Weather Station (WS100LRW/SR) MQTT",
                status: "Pending",
            },
            {
                id: 2,
                configId: 2,
                name: "Main Light",
                date: "10/10/20 12:15",
                description: "Generic MQTT Light",
                status: "Active",
            },
            {
                id: 5,
                configId: 3,
                name: "Torsion Sensor",
                date: "10/10/20 12:15",
                description: "Torsion MQTT LoraWAN",
                status: "Active",
            }
        ],
        rules: [
            {
                id: 1,
                configId: 1,
                name: "Statation's Temparature Threshold",
                date: "10/10/20 12:15",
                description: "Threshold",
                status: "Pending",
                triggerParameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n',
                ruleParameters: '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            },
            {
                id: 2,
                configId: 2,
                name: "Torsion's Variation Check",
                date: "10/10/20 12:15",
                description: "Variation",
                status: "Error",
                messages: [
                    {id: 1, message: 'Sample Error #1'},
                    {id: 2, message: 'Sample Error #2'},
                    {id: 3, message: 'Sample Error #3'},
                ],
                triggerParameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n',
                ruleParameters: '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            },
            {
                id: 4,
                configId: 1,
                name: "Station's Temparature Threshold",
                date: "10/10/20 12:15",
                description: "Threshold",
                status: "Active",
                triggerParameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n',
                ruleParameters: '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            },
            {
                id: 5,
                configId: 1,
                name: "Station's Temparature Threshold",
                date: "10/10/20 12:15",
                description: "Threshold",
                status: "Active",
                triggerParameters: '%TAG ! tag:clarkevans.com,2002:\n' +
                    '--- !shape\n' +
                    '  # Use the ! handle for presenting\n' +
                    '  # tag:clarkevans.com,2002:circle\n' +
                    '- !circle\n' +
                    '  center: &ORIGIN {x: 73, y: 129}\n' +
                    '  radius: 7\n' +
                    '- !line\n',
                ruleParameters: '  radius: 7\n' +
                    '- !line\n' +
                    '  start: *ORIGIN\n' +
                    '  finish: { x: 89, y: 102 }\n' +
                    '- !label\n' +
                    '  start: *ORIGIN\n' +
                    '  color: 0xFFEEBB\n' +
                    '  text: Pretty vector drawing.'
            }
        ],
    },
]


export const categoryData = [
    {name: "Category 1", value: "category1"},
    {name: "Category 2", value: "category2"},
]

export const graphData = [
    {
        name: "Current",
        data: [
            [1645660800000, 18],
            [1645920000000, 21],
            [1646006400000, 45],
            [1646179200000, 36],
            [1646352000000, 65],
            [1646770400000, 47],
            [1647043200000, 51],
            [1647216000000, 32],
            [1647302400000, 40],
            [1647561600000, 28],
            [1647648000000, 31],
            [1647820800000, 26]
        ],
    },
]

export const notificationData = [
    {
        id: 1,
        system: "System 1",
        messaage: "The device XXXXX exceed the value of 50",
        type: "Info",
        date: "22 Nov",
        time: "13:00",
        read: false
    },
    {
        id: 2,
        system: "System 1",
        messaage: "Sample of warning notification",
        type: "Warning",
        date: "22 Nov",
        time: "13:00",
        read: false
    },
    {
        id: 3,
        system: "System 1",
        messaage: "Sample of error notification",
        type: "Error",
        date: "22 Nov",
        time: "13:00",
        read: false
    },
    {id: 4, system: "System 1", messaage: "Lorem ipsum", type: "Info", date: "22 Nov", time: "13:00", read: true},
    {id: 5, system: "System 1", messaage: "Lorem ipsum", type: "Warning", date: "22 Nov", time: "13:00", read: true},
    {id: 6, system: "System 1", messaage: "Lorem ipsum", type: "Error", date: "22 Nov", time: "13:00", read: true},
    {id: 7, system: "System 1", messaage: "Lorem ipsum", type: "Info", date: "22 Nov", time: "13:00", read: true},
    {id: 8, system: "System 1", messaage: "Lorem ipsum", type: "Info", date: "22 Nov", time: "13:00", read: true},
    {id: 9, system: "System 1", messaage: "Lorem ipsum", type: "Info", date: "22 Nov", time: "13:00", read: true},
    {id: 10, system: "System 1", messaage: "Lorem ipsum", type: "Info", date: "22 Nov", time: "13:00", read: true},
    {id: 11, system: "System 1", messaage: "Lorem ipsum", type: "Info", date: "22 Nov", time: "13:00", read: true}
]

export const drivers = [
    {
        id: 1,
        name: "LaRaWAN Weather Station (WS100LRW/SR)",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    },
    {
        id: 2,
        name: "Generic MQTT Light",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    },
    {
        id: 3,
        name: "Generic MQTT Switch",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    },
    {
        id: 4,
        name: "Atomo MQTT LoraWAN",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    }
];

export const rules = [
    {
        id: 1,
        name: "Threshold",
        triggerType: "Type 1",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    },
    {
        id: 2,
        name: "Variation",
        triggerType: "Type 2",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    }
];

export const userDetails = {
    name: "John",
    surname: "Doe",
    role: "Administrator"
}

export const systemSensors = [
    {
        "_id": "6113e6f463da17189d41ae1c",
        "mapping": [
            "{}"
        ],
        "active": true,
        "name": "Temperature 01",
        "type": "6113dfc9f27f5b3083f28542",
        "device": [
            {
                "_id": "6113e5150adbba17eae4de79",
                "system": "6113de5d42c7fe14bc4a2a10",
                "active": true,
                "address": "0001",
                "name": "Sample Device",
                "type": "6113de5d42c7fe14bc4a2a10"
            }
        ],
        "description": "Aggregation Device",
        "address": "0001",
        "createdAt": "2021-08-11T15:04:20.792Z",
        "updatedAt": "2021-09-03T12:55:54.462Z",
        "__v": 0,
        "imageMapping": {
            "topView": [
                13.863822805578344,
                32.21052631578947
            ],
            "sideView": [
                20.26251025430681,
                65.05263157894737
            ]
        },
        "uom": "°C",
        "lastReading": [
            {
                "_id": "6140a083fb5667e29614a15b",
                "mapping": [],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6113e6f463da17189d41ae1c",
                "jsondataread": "{ value: 51}",
                "valueread": 51,
                "happened": "2021-09-14T13:15:47.317Z",
                "createdAt": "2021-09-14T13:15:47.318Z",
                "updatedAt": "2021-09-14T13:15:47.318Z",
                "__v": 0,
                "hasAlerts": 1
            },
            {
                "_id": "6140a080fb5667e29614a154",
                "mapping": [],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6113e6f463da17189d41ae1c",
                "jsondataread": "{ value: 51}",
                "valueread": 51,
                "happened": "2021-09-14T13:15:44.227Z",
                "createdAt": "2021-09-14T13:15:44.228Z",
                "updatedAt": "2021-09-14T13:15:44.228Z",
                "__v": 0,
                "hasAlerts": 1
            }
        ],
        "dangerLevel": 2
    },
    {
        "_id": "6113e70b63da17189d41ae20",
        "mapping": [
            "{}"
        ],
        "active": true,
        "name": "Temperature 02",
        "type": "6113dfc9f27f5b3083f28542",
        "device": [
            {
                "_id": "6113e5150adbba17eae4de79",
                "system": "6113de5d42c7fe14bc4a2a10",
                "active": true,
                "address": "0001",
                "name": "Sample Device",
                "type": "6113de5d42c7fe14bc4a2a10"
            }
        ],
        "description": "Temp 02",
        "address": "0002",
        "createdAt": "2021-08-11T15:04:43.332Z",
        "updatedAt": "2021-09-03T13:01:19.933Z",
        "__v": 0,
        "imageMapping": {
            "topView": [
                92.9450369155045,
                49.89473684210527
            ],
            "sideView": [
                19.9343724364233,
                86.10526315789474
            ]
        },
        "uom": "°C",
        "lastReading": [
            {
                "_id": "611637c4cf72f19c157acce2",
                "mapping": [
                    "{}"
                ],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6113e70b63da17189d41ae20",
                "jsondataread": "{\"value\":24}",
                "valueread": 24,
                "happened": "2021-08-13T09:13:40.336Z",
                "createdAt": "2021-08-13T09:13:40.336Z",
                "updatedAt": "2021-09-10T11:51:27.571Z",
                "__v": 0,
                "hasAlerts": 0
            }
        ],
        "dangerLevel": 0
    },
    {
        "_id": "6113e71763da17189d41ae24",
        "mapping": [
            "{}"
        ],
        "active": true,
        "name": "Temperature 03",
        "type": "6113dfc9f27f5b3083f28542",
        "device": [
            {
                "_id": "6113e5150adbba17eae4de79",
                "system": "6113de5d42c7fe14bc4a2a10",
                "active": true,
                "address": "0001",
                "name": "Sample Device",
                "type": "6113de5d42c7fe14bc4a2a10"
            }
        ],
        "description": "Temp 03",
        "address": "0003",
        "createdAt": "2021-08-11T15:04:55.248Z",
        "updatedAt": "2021-09-03T13:03:14.799Z",
        "__v": 0,
        "imageMapping": {
            "topView": [
                14.35602953240361,
                69.6842105263158
            ],
            "sideView": [
                83.7571780147662,
                68.84210526315789
            ]
        },
        "uom": "°C",
        "lastReading": [
            {
                "_id": "611637cccf72f19c157acce6",
                "mapping": [
                    "{}"
                ],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6113e71763da17189d41ae24",
                "jsondataread": "{\"value\":28}",
                "valueread": 28,
                "happened": "2021-08-13T09:13:48.503Z",
                "createdAt": "2021-08-13T09:13:48.503Z",
                "updatedAt": "2021-09-10T11:51:27.573Z",
                "__v": 0,
                "hasAlerts": 0
            }
        ],
        "dangerLevel": 0
    },
    {
        "_id": "6116378ecf72f19c157accde",
        "mapping": [
            "{}"
        ],
        "active": true,
        "name": "Twist 01",
        "type": "611637549d0533ac87fb3d2f",
        "device": [
            {
                "_id": "6113e5150adbba17eae4de79",
                "system": "6113de5d42c7fe14bc4a2a10",
                "active": true,
                "address": "0001",
                "name": "Sample Device",
                "type": "6113de5d42c7fe14bc4a2a10"
            }
        ],
        "description": "Twist 01",
        "address": "0004",
        "createdAt": "2021-08-13T09:12:46.553Z",
        "updatedAt": "2021-09-03T13:04:52.185Z",
        "__v": 0,
        "imageMapping": {
            "topView": [
                46.34946677604594,
                50.31578947368421
            ],
            "sideView": [
                44.87284659557014,
                49.89473684210527
            ]
        },
        "lastReading": [
            {
                "_id": "6140a07afb5667e29614a14d",
                "mapping": [],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6116378ecf72f19c157accde",
                "jsondataread": "{ value: 51}",
                "valueread": 51,
                "happened": "2021-09-14T13:15:38.519Z",
                "createdAt": "2021-09-14T13:15:38.521Z",
                "updatedAt": "2021-09-14T13:15:38.521Z",
                "__v": 0,
                "hasAlerts": 1
            },
            {
                "_id": "612e40c62289fceb992e3e8f",
                "mapping": [
                    "{}"
                ],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6116378ecf72f19c157accde",
                "jsondataread": "{\"value\": 594}",
                "valueread": 594,
                "happened": "2021-08-29T22:00:00.000Z",
                "createdAt": "2021-08-31T14:46:30.401Z",
                "updatedAt": "2021-09-10T11:51:27.591Z",
                "__v": 0,
                "hasAlerts": 0
            }
        ],
        "dangerLevel": 1
    },
    {
        "_id": "6113e6f463da17189d41ae1c",
        "mapping": [
            "{}"
        ],
        "active": true,
        "name": "Temperature 01",
        "type": "6113dfc9f27f5b3083f28542",
        "device": [
            {
                "_id": "6113e5150adbba17eae4de79",
                "system": "6113de5d42c7fe14bc4a2a10",
                "active": true,
                "address": "0001",
                "name": "Sample Device",
                "type": "6113de5d42c7fe14bc4a2a10"
            }
        ],
        "description": "Aggregation Device",
        "address": "0001",
        "createdAt": "2021-08-11T15:04:20.792Z",
        "updatedAt": "2021-09-03T12:55:54.462Z",
        "__v": 0,
        "imageMapping": {
            "topView": [
                13.863822805578344,
                32.21052631578947
            ],
            "sideView": [
                20.26251025430681,
                65.05263157894737
            ]
        },
        "uom": "°C",
        "lastReading": [
            {
                "_id": "6140a083fb5667e29614a15b",
                "mapping": [],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6113e6f463da17189d41ae1c",
                "jsondataread": "{ value: 51}",
                "valueread": 51,
                "happened": "2021-09-14T13:15:47.317Z",
                "createdAt": "2021-09-14T13:15:47.318Z",
                "updatedAt": "2021-09-14T13:15:47.318Z",
                "__v": 0,
                "hasAlerts": 1
            },
            {
                "_id": "6140a080fb5667e29614a154",
                "mapping": [],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6113e6f463da17189d41ae1c",
                "jsondataread": "{ value: 51}",
                "valueread": 51,
                "happened": "2021-09-14T13:15:44.227Z",
                "createdAt": "2021-09-14T13:15:44.228Z",
                "updatedAt": "2021-09-14T13:15:44.228Z",
                "__v": 0,
                "hasAlerts": 1
            }
        ],
        "dangerLevel": 2
    },
    {
        "_id": "6116378ecf72f19c157accde",
        "mapping": [
            "{}"
        ],
        "active": true,
        "name": "Twist 01",
        "type": "611637549d0533ac87fb3d2f",
        "device": [
            {
                "_id": "6113e5150adbba17eae4de79",
                "system": "6113de5d42c7fe14bc4a2a10",
                "active": true,
                "address": "0001",
                "name": "Sample Device",
                "type": "6113de5d42c7fe14bc4a2a10"
            }
        ],
        "description": "Twist 01",
        "address": "0004",
        "createdAt": "2021-08-13T09:12:46.553Z",
        "updatedAt": "2021-09-03T13:04:52.185Z",
        "__v": 0,
        "imageMapping": {
            "topView": [
                46.34946677604594,
                50.31578947368421
            ],
            "sideView": [
                44.87284659557014,
                49.89473684210527
            ]
        },
        "lastReading": [
            {
                "_id": "6140a07afb5667e29614a14d",
                "mapping": [],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6116378ecf72f19c157accde",
                "jsondataread": "{ value: 51}",
                "valueread": 51,
                "happened": "2021-09-14T13:15:38.519Z",
                "createdAt": "2021-09-14T13:15:38.521Z",
                "updatedAt": "2021-09-14T13:15:38.521Z",
                "__v": 0,
                "hasAlerts": 1
            },
            {
                "_id": "612e40c62289fceb992e3e8f",
                "mapping": [
                    "{}"
                ],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6116378ecf72f19c157accde",
                "jsondataread": "{\"value\": 594}",
                "valueread": 594,
                "happened": "2021-08-29T22:00:00.000Z",
                "createdAt": "2021-08-31T14:46:30.401Z",
                "updatedAt": "2021-09-10T11:51:27.591Z",
                "__v": 0,
                "hasAlerts": 0
            }
        ],
        "dangerLevel": 1
    },
]


export const temperatureData = [
    {
        "_id": "6113e6f463da17189d41ae1c",
        "mapping": [
            "{}"
        ],
        "active": true,
        "name": "Temperature 01",
        "type": "6113dfc9f27f5b3083f28542",
        "device": [
            {
                "_id": "6113e5150adbba17eae4de79",
                "system": "6113de5d42c7fe14bc4a2a10",
                "active": true,
                "address": "0001",
                "name": "Sample Device",
                "type": "6113de5d42c7fe14bc4a2a10"
            }
        ],
        "description": "Aggregation Device",
        "address": "0001",
        "createdAt": "2021-08-11T15:04:20.792Z",
        "updatedAt": "2021-09-03T12:55:54.462Z",
        "__v": 0,
        "imageMapping": {
            "topView": [
                13.863822805578344,
                32.21052631578947
            ],
            "sideView": [
                20.26251025430681,
                65.05263157894737
            ]
        },
        "uom": "°C",
        "lastReading": [
            {
                "_id": "6140a083fb5667e29614a15b",
                "mapping": [],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6113e6f463da17189d41ae1c",
                "jsondataread": "{ value: 51}",
                "valueread": 51,
                "happened": "2021-09-14T13:15:47.317Z",
                "createdAt": "2021-09-14T13:15:47.318Z",
                "updatedAt": "2021-09-14T13:15:47.318Z",
                "__v": 0,
                "hasAlerts": 1
            },
            {
                "_id": "6140a080fb5667e29614a154",
                "mapping": [],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6113e6f463da17189d41ae1c",
                "jsondataread": "{ value: 51}",
                "valueread": 51,
                "happened": "2021-09-14T13:15:44.227Z",
                "createdAt": "2021-09-14T13:15:44.228Z",
                "updatedAt": "2021-09-14T13:15:44.228Z",
                "__v": 0,
                "hasAlerts": 1
            }
        ],
        "dangerLevel": 2
    },
    {
        "_id": "6113e70b63da17189d41ae20",
        "mapping": [
            "{}"
        ],
        "active": true,
        "name": "Temperature 02",
        "type": "6113dfc9f27f5b3083f28542",
        "device": [
            {
                "_id": "6113e5150adbba17eae4de79",
                "system": "6113de5d42c7fe14bc4a2a10",
                "active": true,
                "address": "0001",
                "name": "Sample Device",
                "type": "6113de5d42c7fe14bc4a2a10"
            }
        ],
        "description": "Temp 02",
        "address": "0002",
        "createdAt": "2021-08-11T15:04:43.332Z",
        "updatedAt": "2021-09-03T13:01:19.933Z",
        "__v": 0,
        "imageMapping": {
            "topView": [
                92.9450369155045,
                49.89473684210527
            ],
            "sideView": [
                19.9343724364233,
                86.10526315789474
            ]
        },
        "uom": "°C",
        "lastReading": [
            {
                "_id": "611637c4cf72f19c157acce2",
                "mapping": [
                    "{}"
                ],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6113e70b63da17189d41ae20",
                "jsondataread": "{\"value\":24}",
                "valueread": 24,
                "happened": "2021-08-13T09:13:40.336Z",
                "createdAt": "2021-08-13T09:13:40.336Z",
                "updatedAt": "2021-09-10T11:51:27.571Z",
                "__v": 0,
                "hasAlerts": 0
            }
        ],
        "dangerLevel": 0
    },
    {
        "_id": "6113e71763da17189d41ae24",
        "mapping": [
            "{}"
        ],
        "active": true,
        "name": "Temperature 03",
        "type": "6113dfc9f27f5b3083f28542",
        "device": [
            {
                "_id": "6113e5150adbba17eae4de79",
                "system": "6113de5d42c7fe14bc4a2a10",
                "active": true,
                "address": "0001",
                "name": "Sample Device",
                "type": "6113de5d42c7fe14bc4a2a10"
            }
        ],
        "description": "Temp 03",
        "address": "0003",
        "createdAt": "2021-08-11T15:04:55.248Z",
        "updatedAt": "2021-09-03T13:03:14.799Z",
        "__v": 0,
        "imageMapping": {
            "topView": [
                14.35602953240361,
                69.6842105263158
            ],
            "sideView": [
                83.7571780147662,
                68.84210526315789
            ]
        },
        "uom": "°C",
        "lastReading": [
            {
                "_id": "611637cccf72f19c157acce6",
                "mapping": [
                    "{}"
                ],
                "system": "6113de5d42c7fe14bc4a2a10",
                "sensor": "6113e71763da17189d41ae24",
                "jsondataread": "{\"value\":28}",
                "valueread": 28,
                "happened": "2021-08-13T09:13:48.503Z",
                "createdAt": "2021-08-13T09:13:48.503Z",
                "updatedAt": "2021-09-10T11:51:27.573Z",
                "__v": 0,
                "hasAlerts": 0
            }
        ],
        "dangerLevel": 0
    }
]

export const overviewGraphData = [
    [
        1629158400000,
        2487
    ],
    [
        1629244800000,
        6877
    ],
    [
        1629331200000,
        7595
    ],
    [
        1629417600000,
        6490
    ],
    [
        1629504000000,
        3599
    ],
    [
        1629590400000,
        1829
    ],
    [
        1629676800000,
        4470
    ],
    [
        1629763200000,
        4208
    ],
    [
        1629849600000,
        7897
    ],
    [
        1629936000000,
        2759
    ],
    [
        1630022400000,
        6489
    ],
    [
        1630108800000,
        7889
    ],
    [
        1630195200000,
        594
    ],
    [
        1631577600519,
        51
    ]
]

export const alertsConfigurations = [
    {
        "_id": "6141b12d16cb8c0013e8f1fb",
        "type": "threshold",
        "value": 10,
        "condition": "lt",
        "variationData": null,
        "user": "6116311ff24ff89adba1b9d3",
        "sensor": "6113e70b63da17189d41ae20",
        "createdAt": "2021-09-15T08:39:09.224Z",
        "updatedAt": "2021-09-15T08:39:09.224Z",
        "__v": 0
    },
    {
        "_id": "6141b16916cb8c0013e8f201",
        "type": "variation",
        "value": 50,
        "condition": "gt",
        "variationData": {
            "hours": 2
        },
        "user": "6116311ff24ff89adba1b9d3",
        "sensor": "6113e70b63da17189d41ae20",
        "createdAt": "2021-09-15T08:40:10.006Z",
        "updatedAt": "2021-09-15T08:40:10.006Z",
        "__v": 0
    }
]