import React, {useEffect, useState} from "react";
import {Button, Card, CardBody} from "reactstrap";
import {Link, useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import Breadcrumb from "../../components/common/Breadcrumb";
import Layout from "../../layout/layout";
import Device from "../../components/formViews/Device";

import {getAvailableDeviceTypes, getDeviceData} from "../../redux/actions/deviceActions";
import {getAvailableSystemAreas} from "../../redux/actions/systemAreaActions";
import {config} from "../../config";
import {getSystemData} from "../../redux/actions/systemActions";


const DeviceView=()=>{
    const TARGET_CODE = config.TARGET_CODE;

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [formState, setFormState] = useState({
        driver: '',
        deviceType: '',
        name: '',
        slugId: '',
        // systemArea: '',
        areas: [],
        topViewTop: '',
        topViewLeft: '',
        sideViewTop: '',
        sideViewLeft: '',
        geoLocation: null
    })

    const deviceData = useSelector((el)=> el.deviceData.deviceData);
    const available = useSelector((el) => el.systemData.availableList)
    const errorData = useSelector((el) => el.loadingData.error);
    const role = useSelector((state) => state.authData?.userDetails?.role)
    const systemareas = useSelector((el)=> el.systemAreaData.availableSystemAreas);
    const deviceTypes = useSelector((el)=> el.deviceData.availableDeviceTypes);
    const systemData = useSelector((el) => el.systemData.systemData)

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            if(role === 'customer' || role === 'guest'){
                history.push(`/system/${available[0]._id}/dashboard`)
            } else {
                history.push(`/systems`)
            }
        } else {
            if(!systemareas.length)
                dispatch(getAvailableSystemAreas(params.systemId))
            if(!deviceTypes.length)
                dispatch(getAvailableDeviceTypes(params.systemId))

            dispatch(getDeviceData(params.deviceId,params.systemId));
            dispatch(getSystemData(params.systemId))
        }
    }, []);

    useEffect(() => {
        if (errorData && errorData.code === 404) {
            history.push(`/system/${params.systemId}/devices`)
        }
    }, [errorData])

    useEffect(() => {
        if (deviceData) {
            let commonData = {};

            commonData._id = deviceData._id;
            commonData.driver = deviceData.driverconfiguration?.driver?.name;
            commonData.deviceType = deviceTypes.find((el)=> el._id === deviceData.devicetype)?.name;
            commonData.name = deviceData.name;
            commonData.slugId = deviceData.slug_id;

            if (TARGET_CODE === 'PLT') {
                setFormState({
                    ...formState,
                    ...commonData,
                    // systemArea: deviceData?.metadata?.systemarea?.name,
                    areas: (deviceData.metadata?.systemareas) ? deviceData.metadata.systemareas : [],
                    geoLocation: deviceData?.metadata?.geoposition ? {
                        lat: deviceData?.metadata?.geoposition?.coordinates[0],
                        lng : deviceData?.metadata?.geoposition?.coordinates[1]
                    } : null
                })
            } else {
                setFormState({
                    ...formState,
                    ...commonData,
                    topViewTop: deviceData?.metadata?.topViewTop,
                    topViewLeft: deviceData?.metadata?.topViewLeft,
                    sideViewTop: deviceData?.metadata?.sideViewTop,
                    sideViewLeft: deviceData?.metadata?.sideViewLeft,
                })
            }
        }
    }, [deviceData])

    return(
        <Layout>
            <div>
                <Breadcrumb
                    title={t(formState.name)}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/devices`, name: t('devices.breadcrumb1')},
                        {path: `system/${params.systemId}/device/view/${params.deviceId}`, name: t(formState.name)},
                    ]}
                />

                <Card>
                    <CardBody>
                        <Device
                            systemPolygon={systemData?.metadata?.geoposition?.coordinates[0]}
                            formState={formState}
                            systemareas={systemareas}
                        />

                        <div className='col-12 pb-2 text-end mt-3 form-btn-grp'>
                            <Link to={`/system/${params.systemId}/devices`}><Button type='button' className='btn-secondary'>{t('main.back')}</Button></Link>
                        </div>
                    </CardBody>
                </Card>

            </div>
        </Layout>
    )
}

export default DeviceView;