import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CardBody, Card} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import jsYaml from "js-yaml";

import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";
import DriverForm from "../../components/forms/DriverForm";

import {addDriver, getDriverData, updateDriver} from "../../redux/actions/driverActions";


const DriverAddEdit = ({isEdit, isDisabled}) => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const available = useSelector((el) => el.systemData.availableList)
    const driverData = useSelector((el) => el.driverData.driverData)
    const drivers = useSelector((el) => el.driverData.availableDrivers)
    const errorData = useSelector((el) => el.loadingData.error)

    const [formState, setFormState] = useState({
        driver: null,
        configName: '',
        configDescription: '',
        parameters: null,
        status: null,
    })

    useEffect(() => {
        if (isEdit || isDisabled) {
            if (!available.some((el) => el._id === params.systemId)) {
                history.push(`/systems`)
            } else {
                dispatch(getDriverData(params.driverId, params.systemId))
            }
        } else {
            let driver = drivers.find((el) => el._id === params.driverConfigId)
            setFormState({
                ...formState,
                driver: driver,
                parameters: jsYaml.dump(driver?.driverparams_sample)
            })

        }
    }, [])

    useEffect(() => {
        if (driverData && (isEdit || isDisabled)) {
            setFormState({
                _id: driverData._id,
                configName: driverData.name,
                configDescription: driverData.description || '',
                driver: driverData.driver,
                status: driverData.status,
                parameters: jsYaml.dump(driverData.driverparams),
            })
        }
    }, [driverData])

    useEffect(() => {
        if (errorData && errorData.code === 404) {
            history.push(`/system/${params.systemId}/drivers`)
        }
    }, [errorData])
console.log(formState)
    const onSaveClick = (e) => {
        e.preventDefault();

        let data = {};
        data.name = formState.configName;
        data.description = formState.configDescription;
        data.driver = formState.driver?._id;
        data.driverparams = jsYaml.load(formState.parameters, null)

        if(formState._id){
            data._id = formState._id;
            dispatch(updateDriver(data, params.systemId)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/drivers`)
                }
            })
        } else {
            dispatch(addDriver(data, params.systemId)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/drivers`)
                }
            })
        }
    }

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={isEdit ? t('drivers.editDriver') : isDisabled ? formState.configName : t('drivers.addDriver')}
                    breadcrumbItems={[
                        {
                            path: `system/${params.systemId}/dashboard`,
                            name: t(available.find((el) => el._id === params.systemId)?.name)
                        },
                        {path: `system/${params.systemId}/drivers`, name: t('drivers.breadcrumb1')},
                        {
                            path: isEdit ? `system/${params.systemId}/driver/edit/${params.driverId}` : isDisabled ? `system/${params.systemId}/driver/view/${params.driverId}` : `system/${params.systemId}/driver/add/driver-config/${params.driverConfigId}`,
                            name: t(isEdit ? 'drivers.breadcrumbEdit' : isDisabled ? formState.configName : 'drivers.breadcrumbAdd')
                        }
                    ]}
                />

                <Card>
                    <CardBody>
                        <DriverForm
                            isDisabled={isDisabled}
                            onSaveClick={onSaveClick}
                            formState={formState}
                            setFormState={setFormState}
                        />
                    </CardBody>
                </Card>
            </div>
        </Layout>
    )
}

export default DriverAddEdit;