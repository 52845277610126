import React, {useEffect, useState} from "react";
import {Card, CardBody, Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import Layout from "../../layout/layout";
import TableFirst from "../../components/tables/TableFirst"
import SearchBar from "../../components/others/SearchBar";
import Breadcrumb from "../../components/common/Breadcrumb";

import DeleteModal from "../../components/modals/DeleteModal";
import {deleteSystemArea, getSystemAreaList} from "../../redux/actions/systemAreaActions";
import moment from "moment";

const AreasList = () => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const role = useSelector((state) => state.authData?.userDetails?.role)
    const available = useSelector((el) => el.systemData.availableList)
    const systemAreaData = useSelector((el)=> el.systemAreaData)

    const [deleteId, setDeleteId] = useState(null)
    const [areaSearch, setAreaSearch] = useState()

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            history.push(`/systems`)
        } else {
            dispatch(getSystemAreaList(params.systemId,1, null))
        }
    }, [location.pathname])

    const getDataList=(page)=>{
        dispatch(getSystemAreaList(params.systemId, page, areaSearch ? areaSearch : null))
    }

    const onDeleteArea=()=>{
        let id = deleteId;
        setDeleteId(null)
        dispatch(deleteSystemArea(id, params.systemId))
    }

    const onAreaSearch=()=>{
        dispatch(getSystemAreaList(params.systemId,1, areaSearch))
    }

    const tableColumns = [
        {
            key: "details",
            text: "",
            dataField: "name",
            headerStyle: {backgroundColor: 'white'},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex">
                        <div className="me-3 d-flex align-items-center">
                            <i className="bx bxs-layer bx-sm pe-4" style={{color:'#74788D'}}/>
                        </div>
                        <div>
                            <div className='text-new-dark fw-bold font-size-13'>{row.name}</div>
                            <div className='fw-light text-semi-dark font-size-12'>{t("main.lastUpdate")}: {moment(row.updatedAt).format('YY/MM/DD H:m')}</div>
                        </div>
                    </div>)
            }
        },
        {
            key: "actions",
            text: "",
            dataField: "",
            style: {textAlign: 'right'},
            headerStyle: {backgroundColor: 'white', boxShadow: '0'},
            formatter: (rowContent, row) => {
                return (
                    <div>
                        <Link to={`/system/${params.systemId}/area/view/${row._id}`}><Button color="primary">{t("View")}</Button></Link>
                        {(role === 'admin' || role.includes('supervisor')) && <Link to={`/system/${params.systemId}/area/edit/${row._id}`}><Button color="warning" className="ms-2">{t("Edit")}</Button></Link>}
                        {(role === 'admin' || role.includes('supervisor')) &&  <Button onClick={()=> setDeleteId(row._id)} color='danger' className="ms-2">{t("main.delete")}</Button> }
                    </div>);
            }
        }
    ];

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t('areas.title')}
                    systemData={{ id:params.systemId, name: available.find((el) => el._id === params.systemId)?.name }}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/areas`, name: t('areas.breadcrumb1')}
                    ]}
                />
                <Card>
                    <CardBody>
                        <div>
                            <SearchBar
                                name="area"
                                btnName={t("areas.add")}
                                link={`system/${params.systemId}/area`}
                                searchValue={areaSearch}
                                setSearchValue={setAreaSearch}
                                addDisabled={!(role === 'admin' || role.includes('supervisor'))}
                                onSearch={onAreaSearch}
                            />
                        </div>
                        <TableFirst
                            dataList={systemAreaData.systemAreaList}
                            columns={tableColumns}
                            total={systemAreaData.totalSize}
                            getDataList={getDataList}
                            containerClass={'header-less-table-container'}
                        />
                    </CardBody>
                </Card>
            </div>

            <DeleteModal
                onDelete={onDeleteArea}
                modalShow={!!deleteId}
                setModalShow={setDeleteId}
            />

        </Layout>
    );
}

export default AreasList;
