import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useParams} from "react-router-dom";
import {toast, ToastContainer} from 'react-toastify';
import useSound from 'use-sound';

import Header from '../components/common/Header'
import AboutModal from '../components/modals/AboutModal'
import Footer from "../components/common/Footer";
import NavigationBar from "../components/common/NavigationBar";

import {userLogout} from "../redux/actions/authActions";
import {useDispatch, useSelector} from "react-redux";
import {pageLoad} from "../redux/actions/loadingActions";
import {newNotificationsTimeOut, notificationCountTimeOut} from "../utils/timeoutData";
import {getNewNotificationList, getUnreadCount} from "../redux/actions/notificationActions";

import {config} from "../config";

import infoSound from '../assets/sounds/info.mp3'
import warningSound from '../assets/sounds/warning.mp3'
import dangerSound from '../assets/sounds/danger.mp3'

const Layout = ({children}) => {

    const TARGET_CODE = config.TARGET_CODE

    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();

    const [playInfo] = useSound(infoSound);
    const [playWarning] = useSound(warningSound);
    const [playDanger] = useSound(dangerSound);

    const infoButtonRef = useRef("");
    const dangerButtonRef = useRef("");
    const warningButtonRef = useRef("");

    const [aboutmodal, setAboutmodal] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    const role = useSelector((state) => state.authData?.userDetails?.role)
    const unreadCount = useSelector((el) => el.notificationData.unreadCount)
    const newNotifications = useSelector((el) => el.notificationData.newNotificationList)

    const logout = () => {
        dispatch(userLogout());
    }

    useEffect(() => {
        if (!location.pathname.includes('add')) {
            dispatch(pageLoad())
        }
    }, [location.pathname])

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getUnreadCount());
        }, notificationCountTimeOut);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(getNewNotificationList(TARGET_CODE === 'VLM_LOCAL' ? params.systemId : null));
        }, newNotificationsTimeOut);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        if (newNotifications && newNotifications.length) {
            for (let i = 0; i < newNotifications.length; i++) {
                let row = newNotifications[i];

                let msg = row.message?.config?.LANGUAGE ? row.message[row.message.config.LANGUAGE] : row.message[Object.keys(row.message)[0]];
                let system = row.system?.name

                if(isClicked) {
                    if (row.level === 'info') {
                        playInfo();
                    } else if (row.level === 'warning') {
                        playWarning();
                    } else if (row.level === 'danger') {
                        playDanger();
                    }
                }

                toast(
                    <div>
                        <div>{system}</div>
                        <div>{msg}</div>
                    </div>,
                    {
                        type: row.level === 'info' ? 'info' : row.level === 'warning' ? 'warning' : row.level === 'danger' ? 'error' : "",
                        className: "toast-" + row.level.toLowerCase()
                    }
                )
            }
        } else if(!isClicked){
            document.getElementById('root').addEventListener("click", function () {
                setIsClicked(true)
            }, false);
        }
    }, [JSON.stringify(newNotifications)])

    return (
        <div className='d-flex align-items-stretch flex-column' style={{minHeight: '100vh'}}>
            <ToastContainer position={"top-right"} theme={'colored'}/>
            <div className='sound-buttons'>
                <button ref={infoButtonRef} onClick={playInfo}>CLick</button>
                <button ref={dangerButtonRef} onClick={playDanger}>CLick</button>
                <button ref={warningButtonRef} onClick={playWarning}>CLick</button>
            </div>
            <div>
                <AboutModal
                    aboutmodal={aboutmodal}
                    setAboutmodal={setAboutmodal}
                />
                <Header
                    role={role}
                    setAboutmodal={setAboutmodal}
                    unreadCount={unreadCount}
                />
                <NavigationBar
                    role={role}
                    logout={logout}
                />
                {children}
            </div>
            <div className='mt-auto'>
                <Footer className='mt-auto'/>
            </div>
        </div>
    )
}

export default Layout;