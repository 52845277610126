import {ruleActionTypes} from "../actionTypes/index";
import produce from "immer";

const initialState = {
    availableRules:[],
    ruleList: [],
    ruleData: null,
    totalSize: 0
};

const ruleData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case ruleActionTypes.GET_AVAILABLE_RULES_SUCCEED: {
                state.availableRules = payload;
                return state;
            }
            case ruleActionTypes.GET_RULE_CONFIG_LIST_SUCCEED: {
                state.ruleList = payload.results;
                state.totalSize = payload.totalResults;
                return state;
            }
            case ruleActionTypes.GET_RULE_CONFIG_DATA_SUCCEED: {
                state.ruleData = payload;
                return state;
            }
            case ruleActionTypes.CREATE_RULE_CONFIG_SUCCEED: {
                state.ruleList.push(payload);
                state.totalSize++;
                return state;
            }
            case ruleActionTypes.UPDATE_RULE_CONFIG_SUCCEED: {
                const updatedId = state.ruleList.findIndex((item)=>item._id===payload._id);
                state.ruleList[updatedId]=payload;
                return state;
            }
            case ruleActionTypes.DELETE_RULE_CONFIG_SUCCEED: {
                const deletedId = state.ruleList.findIndex((item)=>item._id===payload._id);
                state.ruleList.splice(deletedId,1);
                state.totalSize--;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default ruleData;
