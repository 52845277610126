import React, {useState, useEffect} from "react";
import {Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useParams, Link} from "react-router-dom";
import moment from "moment";

import TableFirst from "../../components/tables/TableFirst";

import {ReactComponent as Tap} from '../../assets/icons/tap.svg'
import {getOrchardRegisterList, getPlantOrchardRegisterList} from "../../redux/actions/orchardRegisterActions";
import {useDispatch, useSelector} from "react-redux";

const OrchardRegisterListSub = () => {

    const {t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();

    const orchardRegisterData = useSelector((el)=> el.orchardRegisterData)

    useEffect(() => {
        dispatch(getPlantOrchardRegisterList(params.plantId, params.systemId, 1 ))
    }, [])

    const getDataList=(page)=>{
        dispatch(getOrchardRegisterList(params.plantId, params.systemId, page, ))
    }

    const tableColumns = [
        {
            key: "details",
            text: "",
            dataField: "name",
            headerStyle: {backgroundColor: "white"},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex">
                        <div className="me-3 d-flex align-items-center">
                            <Tap className='pe-4 tap-icon'/>
                        </div>
                        <div className='fw-light text-semi-dark font-size-12 d-flex align-items-center'>{t("main.date")}: {moment(row.date).format('YYYY-MM-DD, HH:mm')}</div>
                    </div>
                );
            },
        },
        {
            key: "title",
            text: "",
            dataField: "title",
            headerStyle: {backgroundColor: "white"},
            formatter: (rowContent, row) => {
                return <div className='text-new-dark fw-bold font-size-13'>{row.title}</div>
            },
        },
        {
            key: "edit-delete-save",
            text: "",
            dataField: "",
            style: {textAlign: "right"},
            headerStyle: {backgroundColor: "white", boxShadow: "0"},
            formatter: (rowContent, row) => {
                return (
                    <div className="text">
                        <Link to={`/system/${params.systemId}/orchard-register/view/${row._id}/?from-plants=${params.plantId}`}><Button color="primary">{t("main.view")}</Button></Link>
                    </div>
                );
            },
        },
    ];

    return (
        <div className='mt-2'>

            <div className='d-flex mb-4'>
                <div className='font-size-15 fw-medium mb-3'>{t("orchardRegister.subPageTopic")}</div>
                <div  style={{width:'max-content', marginLeft:'auto', marginTop:'-12px'}} className='searchbar-container'>
                    <Link to={`/system/${params.systemId}/orchard-register/add/?${params.plantId}`}>
                        <Button color='success' className='pe-4 ps-3'>
                            <i className='bx bx-plus bx-xs text-white me-2'/>
                            {t('Add')} {t("orchardRegister.add")}
                        </Button>
                    </Link>
                </div>
            </div>
            <TableFirst
                dataList={orchardRegisterData.plantOrchardRegisterList}
                columns={tableColumns}
                total={orchardRegisterData.plantTotalSize}
                getDataList={getDataList}
                containerClass={'header-less-table-container orchard-sub-table-container'}
                rowBorder={true}
            />
        </div>
    );
}

export default OrchardRegisterListSub;
