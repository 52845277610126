import {ruleEntityActionTypes} from "../actionTypes/index";
import produce from "immer";

const initialState = {
    ruleEntityList: [],
    ruleEntityData: null,
    ruleEntityChart: [],
    totalSize: 0
};

const ruleEntityData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {
            
            case ruleEntityActionTypes.GET_RULE_ENTITY_LIST_SUCCEED: {
                state.ruleEntityList = payload.results;
                state.totalSize = payload.totalResults;
                return state;
            }
            case ruleEntityActionTypes.GET_RULE_ENTITY_DATA_SUCCEED: {
                state.ruleEntityData = payload;
                return state;
            }
            case ruleEntityActionTypes.GET_RULE_ENTITY_CHART_SUCCEED: {
                state.ruleEntityChart = payload;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default ruleEntityData;
