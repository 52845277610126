import React, {useEffect, useState} from "react";
import {Button, Row} from "react-bootstrap";
import {Input} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";

import Map from "../common/Map";


const PlantForm = (props) => {

    const {t} = useTranslation();
    const params = useParams();

    const [systemMarker, setSystemMarker] = useState(null)

    useEffect(()=>{
        if(props.systemPolygon && props.systemPolygon.length){
            let markerVal = settingMapCenter();
            setSystemMarker(markerVal)
        }
    }, [props.systemPolygon])

    const settingMapCenter=()=>{
        let lng = 0;
        let lat = 0;
        for(let i=0; i<props.systemPolygon.length; i++){
            lat += Number(props.systemPolygon[i][0]);
            lng += Number(props.systemPolygon[i][1]);
        }

        return {
            lat: lat/props.systemPolygon.length,
            lng: lng/props.systemPolygon.length
        }
    }

    const onChangeHandler = (e) => {
        const {name, value} = e.target;

        if (value.length <= 50 || (name === 'notes' && value.length <= 500)) {
            props.setFormState({
                ...props.formState,
                [name]: value
            })
        }

    }

    const updateMarker = (e) => {
        props.setFormState({
            ...props.formState,
            'geoLocation': e
        })
    }

    const resetPosition=()=>{
        props.setFormState({
            ...props.formState,
            'geoLocation': settingMapCenter()
        })
    }

    return (

        <form onSubmit={props.onSaveClick}>
            <Row>
                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='name'>{t('plants.form.field1.name')} *</label></div>
                    <div className='form-limitation-container'>
                        <Input
                            type='text'
                            name='name'
                            id='name'
                            className='form-control'
                            value={props.formState.name}
                            onChange={onChangeHandler}
                            required
                            placeholder={t('plants.form.field1.placeholder')}
                        />
                        <div>{props.formState.name.length}/50</div>
                    </div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='category'>{t('plants.form.field2.name')} *</label></div>
                    <div>
                        <Input
                            type='select'
                            name='category'
                            id='category'
                            className='form-select'
                            value={props.formState.category}
                            onChange={onChangeHandler}
                            required
                        >
                            <option hidden value=''>{t('plants.form.field2.placeholder')}</option>
                            {props.categories.map((el, id)=>{
                                return(
                                    <option key={"category-" +id} value={el._id}>{t(el.name)}</option>
                                )
                            })}
                        </Input>
                    </div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='species'>{t('plants.form.field3.name')} *</label></div>
                    <div>
                        <Input
                            type='select'
                            name='species'
                            id='species'
                            className='form-select'
                            value={props.formState.species}
                            onChange={onChangeHandler}
                            required
                        >
                            <option hidden value=''>{t('plants.form.field3.placeholder')}</option>
                            {props.categories.find((el)=> el._id === props.formState.category)?.species.map((el, id)=>{
                                return(
                                    <option key={"species-" +id} value={el._id}>{t(el.name)}</option>
                                )
                            })}
                        </Input>
                    </div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='systemArea'>{t('plants.form.field4.name')} *</label></div>
                    <div>
                        <Input
                            type='select'
                            name='systemArea'
                            id='systemArea'
                            className='form-select'
                            value={props.formState.systemArea}
                            onChange={onChangeHandler}
                            required
                        >
                            <option hidden value=''>{t('plants.form.field4.placeholder')}</option>
                            {props.systemareas.map((el, id)=>{
                                return(
                                    <option key={"systemArea-" +id} value={el._id}>{t(el.name)}</option>
                                )
                            })}
                        </Input>
                    </div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='vegetationMinTemp'>{t('plants.form.vegetation_min_temp.name')}</label></div>
                    <div>
                        <Input
                            type='number'
                            // max={100}
                            // min={0}
                            name='vegetationMinTemp'
                            id='vegetationMinTemp'
                            className='form-control'
                            value={props.formState.vegetationMinTemp}
                            onChange={onChangeHandler}
                            placeholder={t('plants.form.vegetation_min_temp.placeholder')}
                            step={1}
                        />
                    </div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='vegetationMaxTemp'>{t('plants.form.vegetation_max_temp.name')}</label></div>
                    <div>
                        <Input
                            type='number'
                            // max={100}
                            // min={0}
                            name='vegetationMaxTemp'
                            id='vegetationMaxTemp'
                            className='form-control'
                            value={props.formState.vegetationMaxTemp}
                            onChange={onChangeHandler}
                            placeholder={t('plants.form.vegetation_max_temp.placeholder')}
                            step={1}
                        />
                    </div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='waterEcMax'>{t('plants.form.water_ec_max.name')}</label></div>
                    <div>
                        <Input
                            type='number'
                            // max={100}
                            // min={0}
                            name='waterEcMax'
                            id='waterEcMax'
                            className='form-control'
                            value={props.formState.waterEcMax}
                            onChange={onChangeHandler}
                            placeholder={t('plants.form.water_ec_max.placeholder')}
                            step={1}
                        />
                    </div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='notes'>{t('plants.form.notes.name')}</label></div>
                    <div className='form-limitation-container'>
                        <Input
                            type='textarea'
                            name='notes'
                            id='notes'
                            className='form-control'
                            value={props.formState.notes}
                            onChange={onChangeHandler}
                            rows={3}
                            placeholder={t('plants.form.notes.placeholder')}
                        />
                        <div>{props.formState.notes.length}/500</div>
                    </div>
                </div>

                <div className='mt-3 col-md-6'>
                    <div><label className='mb-2 p-0'>{t('plants.form.field6.name')} *</label></div>
                    <div className='text-end'><button type='button' onClick={resetPosition} className='reset-button ms-auto'>Reset Position</button></div>

                    <div>
                        <Map
                            systemPolygon={
                                (props.systemPolygon && props.systemPolygon.length) ?
                                    props.systemPolygon.map((item)=>{
                                        return{
                                            lat: item[0],
                                            lng: item[1]
                                        }
                                    })
                                : null
                            }
                            disablePolygon={true}
                            updateMarker={updateMarker}
                            marker={props.formState.geoLocation ? props.formState.geoLocation : systemMarker ? systemMarker : null}
                        />
                    </div>
                </div>
            </Row>

            <div className='col-12 text-end mt-3 form-btn-grp'>
                <Button color='primary' type='submit' className='me-2'>{t('main.save')}</Button>
                <Link to={`/system/${params.systemId}/plants`}><Button type='button' className='btn-secondary'>{t('main.cancel')}</Button></Link>
            </div>

        </form>
    )
}


export default PlantForm;