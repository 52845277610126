import React from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";

import Map from "../common/Map";
import { config } from '../../config';


const Device = (props) => {

    const TARGET_CODE = config.TARGET_CODE;
    const {t} = useTranslation();

    const customStyles = {

        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? '#eff2f7' : '',
            minHeight: '37px',
            maxHeight: '37px',
            boxShadow: 'none',
            border: '1px solid #ced4da',
            '&:active': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },
            '&:focus': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },
            '&:hover': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },

        }),

        multiValue: (provided) => ({
            ...provided,
            border: '#D5D5D5 solid 0.5px',
            borderRadius: '4px',
            backgroundColor: '#F8F9FA'
        }),

        multiValueLabel: (provided) => ({
            ...provided,
            color: '#74788D'
        }),

        multiValueRemove: (provided, state) => ({
            ...provided,
            color: '#74788D',
            width: state.isDisabled ? '0px' : '',
            '&:hover': {backgroundColor: '#F8F9FA', color: '#74788D'}

        }),


    }

    return (

        <div className='pb-3'>
            <div className='view-entity-container'>
                <div>
                    <div>{t("devices.form.field1.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.driver}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("devices.form.field2.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.deviceType}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("devices.form.field3.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.name}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("devices.form.field4.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.slugId}</div>
                    </div>
                </div>

                {(TARGET_CODE === 'PLT' && TARGET_CODE !== "VLM_LOCAL") &&
                    <div>
                        {/* <div className='mt-3'>
                            <div>{t("devices.form.field5.name")}</div>
                            <div>
                                <div className='form-control'>{props.formState.systemArea}</div>
                            </div>
                        </div> */}

                        <div className="mt-3 col-12">
                            <div><label className="mb-2 p-0" htmlFor="areas">{t("devices.form.field5.name")}</label></div>
                            <div>
                                <Select
                                    isMulti
                                    name="areas"
                                    options={props.systemareas.map((el)=> {
                                        return {
                                            label: el.name,
                                            value: el._id
                                        }
                                    })}
                                    className="basic-multi-select"
                                    placeholder={t("devices.form.field5.placeholder")}
                                    components={{IndicatorSeparator: () => null}}
                                    isClearable={false}
                                    // onChange={(e) => {
                                    //     let event = {
                                    //         name: "areas",
                                    //         values: e,
                                    //     };
                                    //     onChangeHandler(event);
                                    // }}
                                    value={props.formState.areas.map((item) => ({
                                        value: item._id,
                                        label: item.name,
                                    }))}
                                    isDisabled={true}
                                    styles={customStyles}
                                />
                            </div>
                        </div>

                        <div className='mt-3 col-md-6'>
                            <div>{t("devices.form.field6.name")}</div>
                            <div>
                                <Map
                                    systemPolygon={
                                        (props.systemPolygon && props.systemPolygon.length) ?
                                            props.systemPolygon.map((item)=>{
                                                return{
                                                    lat: item[0],
                                                    lng: item[1]
                                                }
                                            })
                                            : null
                                    }
                                    isView={true}
                                    marker={props.formState.geoLocation ? props.formState.geoLocation : null}
                                />
                            </div>
                        </div>
                    </div>
                }

                {(TARGET_CODE !== 'PLT') &&
                    <div>
                        <div className='mt-3'>
                            <div>{t("devices.form.field5.name")}</div>
                            <div>
                                <div className='form-control'>{props.formState.topViewTop}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div>{t("devices.form.field6.name")}</div>
                            <div>
                                <div className='form-control'>{props.formState.topViewLeft}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div>{t("devices.form.field7.name")}</div>
                            <div>
                                <div className='form-control'>{props.formState.sideViewTop}</div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div>{t("devices.form.field8.name")}</div>
                            <div>
                                <div className='form-control'>{props.formState.sideViewLeft}</div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}


export default Device;