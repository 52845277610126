import React, {useEffect} from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation} from 'react-router-dom'

import AuthenticateHelper from '../helpers/authenticateHelper'

import Login from '../views/Login';
import Notifications from "../views/Notifications";
import Settings from "../views/Settings";

import UsersList from "../views/Users/UsersList";
import UserAddEdit from "../views/Users/UserAddEdit";
import UserView from "../views/Users/UserView";

import SystemsList from "../views/Systems/SystemsList";
import SystemAddEdit from "../views/Systems/SystemAddEdit";
import SystemView from "../views/Systems/SystemView";

import AreasList from "../views/Areas/AreasList";
import AreaAddEdit from "../views/Areas/AreaAddEdit";
import AreaView from "../views/Areas/AreaView";

import DevicesList from "../views/Devices/DevicesList";
import DeviceEdit from "../views/Devices/DeviceEdit";
import DeviceView from "../views/Devices/DeviceView";

import PlantAddEdit from "../views/Plants/PlantAddEdit";
import PlantsList from "../views/Plants/PlantsList";
import PlantView from "../views/Plants/PlantView";

import RuleEntitiesList from "../views/RuleEntities/RuleEntitiesList";

import OrchardRegisterList from "../views/OrchardEntries/OrchardRegisterList";
import OrchardRegisterAddEdit from "../views/OrchardEntries/OrchardRegisterAddEdit";

import DriversList from "../views/Drivers/DriversList";
import DriverAddEdit from "../views/Drivers/DriverAddEdit";

import RulesList from "../views/Rules/RulesList";
import RuleAddEdit from "../views/Rules/RuleAddEdit";

import Dashboard from "../views/Dashboard/Dashboard";
import {config} from "../config";
import {useDispatch, useSelector} from "react-redux";
import {getMyDetails} from "../redux/actions/authActions";


const Routes = (props) => {

    const dispatch = useDispatch();
    const role = useSelector((state) => state.authData?.userDetails?.role)
    const available = useSelector((el) => el.systemData.availableList)

    const authorization = AuthenticateHelper();
    const TARGET_CODE = config.TARGET_CODE;

    useEffect(()=>{
        if(!role && authorization){
            dispatch(getMyDetails())
        }
    },[])

    const routes = [
        { route: '/users', roles: ['admin'], component: <UsersList /> },
        { route: '/user/view/:userId', roles: ['admin'], component: <UserView /> },
        { route: '/user/edit/:userId', roles: ['admin'], component: <UserAddEdit role={useSelector((el)=> el.authData)} isEdit={true} /> },
        { route: '/user/add', roles: ['admin'], component: <UserAddEdit isEdit={false} /> },
        { route: '/systems', roles: ['admin', 'supervisor'], component: <SystemsList /> },
        { route: '/system/view/:systemId', roles: ['admin', 'supervisor'], component: <SystemView /> },
        { route: '/system/edit/:systemId', roles: ['admin'], component: <SystemAddEdit isEdit={true} /> },
        { route: '/system/add', roles: ['admin'], component: <SystemAddEdit isEdit={false} /> },
        { route: '/system/:systemId/areas/', remove: 'VLM', roles: ['admin', 'supervisor'], component: <AreasList /> },
        { route: '/system/:systemId/area/view/:areaId', remove: 'VLM', roles: ['admin', 'supervisor'], component: <AreaView /> },
        { route: '/system/:systemId/area/edit/:areaId', remove: 'VLM', roles: ['admin', 'supervisor'], component: <AreaAddEdit isEdit={true} /> },
        { route: '/system/:systemId/area/add', remove: 'VLM', roles: ['admin', 'supervisor'], component: <AreaAddEdit isEdit={false} /> },
        { route: '/system/:systemId/devices/', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <DevicesList /> },
        { route: '/system/:systemId/device/view/:deviceId', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <DeviceView /> },
        { route: '/system/:systemId/device/edit/:deviceId', roles: ['admin', 'supervisor'], component: <DeviceEdit isEdit={true} /> },
        { route: '/system/:systemId/plants/', remove: 'VLM', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <PlantsList /> },
        { route: '/system/:systemId/plant/view/:plantId', remove: 'VLM', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <PlantView /> },
        { route: '/system/:systemId/plant/edit/:plantId', remove: 'VLM', roles: ['admin', 'supervisor'], component: <PlantAddEdit isEdit={true} /> },
        { route: '/system/:systemId/plant/add', remove: 'VLM', roles: ['admin', 'supervisor'], component: <PlantAddEdit isEdit={false} /> },
        { route: '/system/:systemId/orchard-registers/', remove: 'VLM', roles: ['admin', 'supervisor'], component: <OrchardRegisterList /> },
        { route: '/system/:systemId/orchard-register/view/:orchardId', remove: 'VLM', roles: ['admin', 'supervisor'], component: <OrchardRegisterAddEdit isDisabled={true}/> },
        { route: '/system/:systemId/orchard-register/edit/:orchardId', remove: 'VLM', roles: ['admin', 'supervisor'], component: <OrchardRegisterAddEdit isEdit={true} /> },
        { route: '/system/:systemId/orchard-register/add', remove: 'VLM', roles: ['admin', 'supervisor'], component: <OrchardRegisterAddEdit isEdit={false} /> },
        { route: '/system/:systemId/rule-entities/', remove: 'VLM', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <RuleEntitiesList /> },
        { route: '/system/:systemId/drivers/', roles: ['admin'], component: <DriversList /> },
        { route: '/system/:systemId/driver/view/:driverId', roles: ['admin'], component: <DriverAddEdit isDisabled={true} /> },
        { route: '/system/:systemId/driver/edit/:driverId', roles: ['admin'], component: <DriverAddEdit isEdit={true} /> },
        { route: '/system/:systemId/driver/add/driver-config/:driverConfigId', roles: ['admin'], component: <DriverAddEdit isEdit={false} /> },
        { route: '/system/:systemId/rules/', roles: ['admin', 'supervisor'], component: <RulesList /> },
        { route: '/system/:systemId/rule/view/:ruleId', roles: ['admin', 'supervisor'], component: <RuleAddEdit isDisabled={true} /> },
        { route: '/system/:systemId/rule/edit/:ruleId', roles: ['admin', 'supervisor'], component: <RuleAddEdit isEdit={true} /> },
        { route: '/system/:systemId/rule/add/rule-config/:ruleConfigId', roles: ['admin', 'supervisor'], component: <RuleAddEdit isEdit={false} /> },
        { route: '/settings', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <Settings /> },
        { route: '/notifications', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <Notifications /> },
        { route: '/system/:systemId/dashboard', roles: ['admin', 'supervisor', 'customer', 'guest'], component: <Dashboard /> },
    ]

    if (!authorization) {
        return (
            <Router>
                <Switch>
                    <Route path='/login'><Login/></Route>
                    <Redirect to="/login"/>
                </Switch>
            </Router>
        )
    } else {
        if (TARGET_CODE === 'VLM_LOCAL') {
            return (
                <Router>
                    <Switch>
                        <Route exact path='/system/:systemId/devices/'><DevicesList /></Route>
                        <Route exact path='/system/:systemId/device/view/:deviceId'><DeviceView /></Route>

                        <Route exact path='/notifications'><Notifications /></Route>
                        <Route exact path='/system/:systemId/dashboard'><Dashboard /></Route>

                        <Redirect to={`/system/${available[0]._id}/dashboard`}/>

                    </Switch>
                </Router>
            )
        } else {
            return (
                <Router>
                    <Switch>

                        {routes.filter((el)=> !el.remove || el.remove !== TARGET_CODE).filter((el)=> el.roles.some((el2)=> role.includes(el2))).map((item, id)=>{
                            return(
                                <Route exact key={id} path={item.route}>
                                    {item.component}
                                </Route>
                            )
                        })}

                        <Redirect to={(role === 'admin' || role.includes('supervisor')) ? "/systems" : `/system/${available[0]._id}/dashboard` }/>

                    </Switch>
                </Router>
            )
        }
    }
}

export default Routes;