import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Col} from "react-bootstrap";

import UserDetails from "../../components/dashboard/UserDetails";
import AlertComp from "../../components/dashboard/AlertComp";
import SystemHealth from "../../components/dashboard/SystemHealth";


import GardenView from "../../components/dashboard/plt/GardenView";
import AreaDataGraph from "../../components/dashboard/plt/AreaDataGraph";
import {Spinner} from "reactstrap";


const DashboardPLT = (props) => {

    const {t} = useTranslation();

    return (
        <div className='dashboard-container-plt'>
            <Col className='row mt-4 card-plt'>
                <Col md={12} xl={6} className='row ms-1 card-plt padding-left-none'>
                    <div className='col-md-6 card-plt card-user'>
                        <UserDetails
                            userData={props.userData}
                        />
                        <AlertComp
                            systemAlerts={props.notificationData}
                        />
                    </div>
                    <Col md={6} className='card-plt'>
                        <SystemHealth data={props.systemHealth}/>
                    </Col>
                </Col>
                <Col md={12} xl={6} className='mt-2 ms-2 row card-plt padding-right-none'>
                    <div className='col-12 p-0 pe-1 me-1'>
                        <GardenView
                            system={props.system}
                            areas={props.areas}
                            plants={props.plants}
                            devices={props.devices}
                        />
                    </div>
                </Col>
                {props.areasData.length ?
                    <>
                        {props.areasData.map((el) => {
                            return (
                                <Col className='col-12'>
                                    <AreaDataGraph
                                        getGraphData={(id, value) => props.getGraphData(id, value, el._id)}
                                        name={el.name}
                                        humChart={el.humChart}
                                        tempChart={el.tempChart}
                                    />
                                </Col>
                            )
                        })}
                    </>
                    :

                    props.isFetching ?
                        <div style={{height:120, textAlign:'center', paddingTop:40}}>
                        <Spinner style={{color: "#556ee6"}} animation='border'>
                            {' '}
                        </Spinner>
                        </div>

                    : ''
                }
            </Col>
        </div>
    )
}

export default DashboardPLT;