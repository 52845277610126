import { t } from "i18next";
import {Modal, ModalBody, Button, ModalHeader} from "reactstrap";
import {CloseButton} from "react-bootstrap";

function DeleteModal(props) {

    const toggleModal=()=>{
        props.setModalShow(!props.modalShow)
    }

    return (
        <div>
            <Modal isOpen={props.modalShow} centered className="delete-modal-container">
                <ModalHeader className='delete-modal ps-4'>
                    {t(props.deleteAll ? "main.deleteAll" : "main.delete")}
                    <CloseButton onClick={toggleModal} variant="white"/>
                </ModalHeader>
                <ModalBody>
                    <div className='font-size-13 text-new-dark mt-4 ms-4 mb-5'>
                        {t(props.deleteAll ? "notifications.deleteAllTopic" : "main.deleteTopic")} <br />{" "}
                        {t("notifications.deleteAllSubTopic")}{" "}
                    </div>
                    <div className="text-end form-btn-grp" style={{marginTop:'80px'}}>
                        <Button color="danger" onClick={props.onDelete} className="me-2">{t(props.deleteAll ? "main.deleteAll" : "main.delete")}</Button>
                        <Button color="secondary" onClick={toggleModal}>{t("main.cancel")}</Button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default DeleteModal;
