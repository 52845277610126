import { loadingActionTypes, notificationActionTypes, dashboardActionTypes } from "../actionTypes/index";
import produce from "immer";

const initialState = {
    loading: false,
    tableLoading: false,
    error: null
};

const loadingState = {
    loading: true,
    error: null,
}
const tableLoadingState = {
    loading: false,
    tableLoading: true,
    error: null
}

const successState = {
    loading: false,
    error: null
}
const tableSuccessState = {
    loading: false,
    tableLoading: false,
    error: null
}

const loadingData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        if(type === loadingActionTypes.PAGE_RELOAD_REQUEST){
            state = loadingState;
            return state;
        } else if(type === loadingActionTypes.PAGE_RELOAD_STOP){
            state = successState;
            return state;
        } else if(type === notificationActionTypes.GET_UNREAD_NOTIFICATION_COUNT_REQUEST || type === dashboardActionTypes.GET_TEMPERATURE_DETAILS_REQUEST || type === dashboardActionTypes.GET_ONBOARD_DEVICES_DETAILS_REQUEST || type === dashboardActionTypes.GET_SHIP_MAP_DETAILS_REQUEST || type === dashboardActionTypes.GET_SYSTEM_HEALTH_DETAILS_REQUEST || type === dashboardActionTypes.GET_LAST_NOTIFICATION_LIST_REQUEST){
            return state;
        } else if(type.includes('CHART_REQUEST') || type.includes('CHART_DATA_REQUEST')){
            return state;
        } else if(type.includes('DASHBOARD_ALERT') && !type.includes('SUCCEED') && !type.includes('FAILED')){
            if(state.loading){
                state.tableLoading = true;
                state.error = null
            } else {
                state = tableLoadingState;
            }
            return state;
        } else if(type.includes('LIST_REQUEST')){
            state.error = null
            return state;
        } else if(type.includes('REQUEST')){
            state = loadingState;
            return state;
        } else if (type.includes('GET_NEW_NOTIFICATION_LIST_SUCCEED') || type.includes('GET_UNREAD_NOTIFICATION_COUNT_SUCCEED') || type.includes('GET_AVAILABLE_DRIVER_CONFIG_SUCCEED') || type.includes('GET_AVAILABLE_SYSTEM_AREAS_SUCCEED') || type.includes('GET_AVAILABLE_DEVICE_TYPES_SUCCEED')){
            return state;
        } else if (type.includes('LIST_SUCCEED')){
            state = tableSuccessState;
            return state;
        } else if (type === 'USER_LOGIN_SUCCEED'){
            state.error = null;
            return state;
        } else if (type.includes('SUCCEED')){
            state = successState;
            return state;
        } else if (type === 'GET_DASHBOARD_SINGLE_DATA_FAILED'){
            let error = {};
            error = payload.error;
            error.isSingle = true;

            state.error = error;
            return state;
        } else if (type.includes('GET_DASHBOARD_DATA_FAILED')){
            state.error = (payload && payload.error) ? payload.error : payload;
            state.loading = false;
            return state;
        }else if (type.includes('DATA_FAILED')){
            state.error = (payload && payload.error) ? payload.error : payload;
            state.loading = true;
            return state;
        } else if (type.includes('FAILED')){
            state.loading = false;
            state.error = (payload && payload.error) ? payload.error : payload;
            return state;
        } else {
            return state;
        }
    }
);

export default loadingData;
