import React, {useEffect, useRef, useState} from 'react';
import AceEditor from "react-ace";
import jsYaml from "js-yaml";

import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-xcode";
import {useTranslation} from "react-i18next";


export const Editor = (props) => {

    const {t} = useTranslation();
    const editorRef = useRef();

    const [error, setError] = useState('');

    const onCheck = () => {
        try {
            const json = jsYaml.load(props.value, null)
            setError('')
        } catch (e) {
            setError(e.reason)
        }
    }

    return (
        <>
            <AceEditor
                style={{
                    height: props.height ? props.height : '250px',
                    width: '100%'
                }}
                mode="yaml"
                theme="xcode"
                readOnly={!!props.isDisabled}
                name={props.name}
                editorProps={{
                    $blockScrolling: true,
                }}
                value={props.value}
                onChange={(val) => {
                    let e = {};
                    e.target = {};
                    e.target.name = props.name
                    e.target.value = val

                    props.onChangeHandler(e)
                }}
                className='form-control'
                ref={editorRef}
            />
            {!props.isDisabled &&
                <div className='d-flex editor-btn-container mt-2 align-items-center'>
                    <div onClick={props.setSample} className='me-4'>Reset to sample</div>
                    <div onClick={onCheck} className='me-2'>Check YAML Syntax</div>
                    <i className='bx bx-check-double bx-xs text-success'/>
                </div>
            }

            {error &&
                <div className='mt-3'>
                    <div>{t("drivers.view.messages")}:</div>
                    <ul className='mt-1'>
                        <li className='fw-light'>{error}</li>
                    </ul>
                </div>
            }
        </>
    )
};