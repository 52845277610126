import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Container} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import Layout from '../../layout/layout'
import SearchBar from "../../components/others/SearchBar";
import TableFirst from "../../components/tables/TableFirst";
import Breadcrumb from "../../components/common/Breadcrumb";
import DeleteModal from "../../components/modals/DeleteModal";

import {deleteUser, getUserList} from "../../redux/actions/userActions";
import {roleData} from "../../utils/appData";

const UsersList = (props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userData = useSelector((el)=> el.userData)

    const [userSearch, setUserSearch] = useState()
    const [deleteId, setDeleteId] = useState(null)

    useEffect(()=>{
        dispatch(getUserList(1, null))
    },[])

    const getDataList=(page)=>{
        dispatch(getUserList(page, userSearch ? userSearch : null))
    }

    const onDeleteUser=()=>{
        let id = deleteId;
        setDeleteId(null)
        dispatch(deleteUser(id))
    }

    const onUserSearch=()=>{
        dispatch(getUserList(1, userSearch))
    }

    const tableColumns = [
        {
            key: "doc-row", text: t("users.table.header1"), dataField: 'fullname',
            headerStyle: {textAlign: 'left', width: '200px'},
        }, {
            key: 'email',
            dataField: 'email',
            text: t('users.table.header2'),
            headerStyle: {textAlign: 'left', width: '200px'},
        }, {
            key: 'role',
            dataField: 'role',
            text: t('users.table.header3'),
            headerStyle: {textAlign: 'left', minWidth: 'max-content',},
            style: {minWidth: 'max-content',},
            formatter: (rowContent, row) => { return t(roleData.find((el)=> el.value === row.role)?.label)}
        }, {
            key: 'actions',
            dataField: 'actions',
            text: t('main.actions'),
            headerStyle: {textAlign: 'left', width: '300px'},
            style: {textAlign: 'left'},
            formatter: (rowContent, row) => {
                return (
                    <div>
                        <Link to={`/user/view/${row._id}`}><Button color='primary' className='me-2'>{t("main.view")}</Button></Link>
                        <Link to={`/user/edit/${row._id}`}><Button color='warning' className='me-2'>{t("main.edit")}</Button></Link>
                        <Button onClick={()=> setDeleteId(row._id)} color='danger'>{t("main.delete")}</Button>
                    </div>
                )
            }
        }
    ]

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t('users.title')}
                    breadcrumbItems={[
                        {path: "users", name: t('users.breadcrumb1')}
                    ]}
                />
                <Card>
                    <CardBody>
                        <SearchBar
                            name="user"
                            btnName={t("users.add")}
                            link='user'
                            searchValue={userSearch}
                            setSearchValue={setUserSearch}
                            onSearch={onUserSearch}
                        />
                        <TableFirst
                            dataList={userData.userList}
                            columns={tableColumns}
                            total={userData.totalSize}
                            getDataList={getDataList}
                        />
                    </CardBody>
                </Card>
            </div>

            <DeleteModal
                onDelete={onDeleteUser}
                modalShow={!!deleteId}
                setModalShow={setDeleteId}
            />
        </Layout>
    )
}

export default UsersList;