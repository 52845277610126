import React from 'react'
import { Modal, ModalHeader } from 'reactstrap'
import {useTranslation} from "react-i18next";

import AboutImages from '../../assets/images/vlm/info_modal.png'
import Logo from '../../assets/images/company_logo.png'

const AboutModal=(props)=>{

    const {t} = useTranslation();

    return(
        <Modal
            isOpen={props.aboutmodal}
            role="dialog"
            autoFocus={true}
            centered={true}
            tabIndex="-1"
            data-toggle="modal"
            size='lg'
            toggle={() => {
                props.setAboutmodal(!props.aboutmodal)
            }}
        >
            <div className="modal-content">
                <div className="modal-body mt-3 mb-4">
                    <div className="text-center mb-0">

                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div style={{color:'#495057'}} className="font-size-24 fw-light mb-4">Velmare</div>
                                <p className="text-muted font-size-14 mb-4">{t("about.description")}</p>
                            </div>
                        </div>
                        <div className='col-8 mx-auto mt-3 mb-5'>
                            <img src={Logo} className='img-fluid' style={{maxHeight:'50px'}} alt='About'/>
                        </div>
                        <div className='col-8 mx-auto mt-3 mb-5'>
                            <img src={AboutImages} className='img-fluid' alt='About'/>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AboutModal;
