import React, {useState} from "react"
import PropTypes from 'prop-types'
import {Link, useParams} from "react-router-dom"
import {Row, Col, BreadcrumbItem, Input} from "reactstrap"
import {useTranslation} from "react-i18next";
import {Dropdown} from "react-bootstrap";
import {systemData} from "../../utils/userData";
import {useSelector} from "react-redux";

const Breadcrumb = props => {

    const {t} = useTranslation();
    const params = useParams();

    const [searchText, setSearchText] = useState('');

    const available = useSelector((el) => el.systemData.availableList)

    return (
        <Row className='pt-2 mb-3'>
            <Col xs="12">
                <div className="page-title-box d-flex flex-column flex-md-row flex-column-reverse align-items-center justify-content-md-between">

                    <div className='d-flex me-auto mt-2 mt-md-0 mb-2 mb-md-0'>
                        <h4 className="mb-0 font-size-18" style={{textTransform: "uppercase"}}>{props.title}</h4>
                        {(props.systemData && systemData.length > 1) ?
                            <div className='ms-4 me-2 ps-3' style={{borderLeft: '1px solid black'}}>
                                <Dropdown className='dropdown-system'>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        {props.systemData.name}
                                        { available?.length > 1 && <i style={{cursor: 'pointer'}} className='ms-2 bx-font-size bx bxs-down-arrow'/>}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Input
                                            type='text'
                                            name='search-system'
                                            id='search-system'
                                            className='form-control'
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                            placeholder={t('top.system.search.placeholder')}
                                        />
                                        {available.filter((el) => el.name.toLowerCase().includes(searchText.toLowerCase())).map((el) => {
                                                let oldPath = props.breadcrumbItems[props.breadcrumbItems.length - 1]?.path;
                                                let newPath = oldPath.replace(`system/${params.systemId}`, `system/${el._id}`)
                                                newPath = '/' + newPath
                                                return (
                                                    <Dropdown.Item as={Link} to={newPath} key={"system-dropdown-" + el._id} >{el.name}</Dropdown.Item>
                                                )
                                            }
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        :
                            props.systemData ?
                                <div className='ms-4 me-2 ps-3' style={{borderLeft: '1px solid black'}}>{props.systemData.name}</div>
                        : ''}
                    </div>

                    <div className="d-flex ms-auto mt-1 mt-md-0 mb-2 mb-md-0 page-title-right">
                        <ol className="breadcrumb m-0">
                            <BreadcrumbItem>
                                <Link to='/'>{t('main.project')}</Link>
                            </BreadcrumbItem>
                            {
                                props.breadcrumbItems.map((item, index) => {
                                    let path = '/' + item.path;
                                    return (
                                        <BreadcrumbItem
                                            active={index == (props.breadcrumbItems.length - 1) ? true : false}
                                            key={'users-bci-' + index}>
                                            <Link to={path}>{item.name}</Link>
                                        </BreadcrumbItem>
                                    )
                                })
                            }
                        </ol>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

Breadcrumb.propTypes = {
    breadcrumbItems: PropTypes.array,
    title: PropTypes.string
}

export default Breadcrumb
