import {systemAreaActionTypes, systemPlantActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getAvailablePlantCategories = () => async dispatch => {
    dispatch({type: systemPlantActionTypes.GET_AVAILABLE_CATEGORIES_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/plants/availablecategories`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: systemPlantActionTypes.GET_AVAILABLE_CATEGORIES_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: systemPlantActionTypes.GET_AVAILABLE_CATEGORIES_FAILED,
            payload: err ? err.data : null
        });
    }
}
export const getAvailablePlants = (id) => async dispatch => {
    dispatch({type: systemPlantActionTypes.GET_AVAILABLE_PLANTS_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/plants/availableplants?system=${id}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: systemPlantActionTypes.GET_AVAILABLE_PLANTS_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: systemPlantActionTypes.GET_AVAILABLE_PLANTS_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getSystemPlantList = (id, page, search) => async dispatch => {
    dispatch({type: systemPlantActionTypes.GET_SYSTEM_PLANT_LIST_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/plants?system=${id}&page=${page}&limit=5&${search ? 'searchText=' + search : ''}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: systemPlantActionTypes.GET_SYSTEM_PLANT_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: systemPlantActionTypes.GET_SYSTEM_PLANT_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getSystemPlantData = (id, systemId) => async dispatch => {
    dispatch({type: systemPlantActionTypes.GET_SYSTEM_PLANT_DATA_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/plants/${id}?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: systemPlantActionTypes.GET_SYSTEM_PLANT_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: systemPlantActionTypes.GET_SYSTEM_PLANT_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const addSystemPlant = (Data, id) => async dispatch => {
    dispatch({type: systemPlantActionTypes.CREATE_SYSTEM_PLANT_REQUEST});

    let data = new FormData();

    data.append('name', Data.name);
    data.append('species_id', Data.species);
    data.append('systemarea', Data.systemarea);
    data.append('vegetation_min_temp', Data.vegetation_min_temp);
    data.append('vegetation_max_temp', Data.vegetation_max_temp);
    data.append('water_ec_max', Data.water_ec_max);
    data.append('notes', Data.notes);
    data.append('geoposition', Data.geoposition ? JSON.stringify(Data.geoposition) : null);

    try {
        const res = await request({
            url: `plantsystem/ui/plants?system=${id}`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: systemPlantActionTypes.CREATE_SYSTEM_PLANT_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: systemPlantActionTypes.CREATE_SYSTEM_PLANT_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const updateSystemPlant = (Data, systemId) => async dispatch => {
    dispatch({type: systemPlantActionTypes.UPDATE_SYSTEM_PLANT_REQUEST});

    let data = new FormData();

    data.append('name', Data.name);
    data.append('species_id', Data.species);
    data.append('systemarea', Data.systemarea);
    data.append('vegetation_min_temp', Data.vegetation_min_temp);
    data.append('vegetation_max_temp', Data.vegetation_max_temp);
    data.append('water_ec_max', Data.water_ec_max);
    data.append('notes', Data.notes);
    data.append('geoposition', Data.geoposition ? JSON.stringify(Data.geoposition) : null);

    try {
        const res = await request({
            url: `plantsystem/ui/plants/${Data._id}?system=${systemId}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: systemPlantActionTypes.UPDATE_SYSTEM_PLANT_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: systemPlantActionTypes.UPDATE_SYSTEM_PLANT_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const deleteSystemPlant = (id, systemId) => async dispatch => {
    dispatch({type: systemPlantActionTypes.DELETE_SYSTEM_PLANT_REQUEST});

    try {
        const res = await request({
            url: `plantsystem/ui/plants/${id}?system=${systemId}`,
            auth: true,
            method: 'DELETE',
        });

        dispatch({
            type: systemPlantActionTypes.DELETE_SYSTEM_PLANT_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: systemPlantActionTypes.DELETE_SYSTEM_PLANT_FAILED,
            payload: err ? err.data : null
        });
    }
}
