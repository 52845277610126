import {CardBody, Card} from "reactstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams } from "react-router-dom";
import moment from "moment";

import Breadcrumb from "../../components/common/Breadcrumb";
import OrchardRegisterForm from "../../components/forms/OrchardRegisterForm";
import Layout from "../../layout/layout";

import {useDispatch, useSelector} from "react-redux";
import {getAvailablePlants,} from "../../redux/actions/systemPlantActions";
import {
    addOrchardRegister,
    getOrchardRegisterData,
    updateOrchardRegister
} from "../../redux/actions/orchardRegisterActions";
import {getAvailableSystemAreas} from "../../redux/actions/systemAreaActions";

function AddEditOrchard({isEdit, isDisabled}) {

    const {t} = useTranslation()
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const available = useSelector((el) => el.systemData.availableList)
    const orchardRegisterData = useSelector((el)=> el.orchardRegisterData.orchardRegisterData)
    const errorData = useSelector((el) => el.loadingData.error)
    const systemareas = useSelector((el) => el.systemAreaData.availableSystemAreas)
    const plants = useSelector((el) => el.systemPlantData.availablePlants)

    const [formState, setFormState] = useState({
        title: "",
        areas: [],
        plants: [],
        date: moment().format('YYYY-MM-DDTHH:mm'),
        notes: "",
    })

    useEffect(()=>{
        if(!history.location.search){
            setFormState({
                title: "",
                areas: [],
                plants: [],
                date: moment().format('YYYY-MM-DDTHH:mm'),
                notes: "",
            })
        }
    },[history.location.search])

    useEffect(() => {
        if(isEdit || isDisabled){
            if(!available.some((el) => el._id === params.systemId)){
                history.push(`/systems`)
            } else {
                dispatch(getOrchardRegisterData(params.orchardId, params.systemId))
            }
        }
        if (!isDisabled){
            dispatch(getAvailableSystemAreas(params.systemId))
            dispatch(getAvailablePlants(params.systemId))
        }
    }, [])

    useEffect(() => {
        if (orchardRegisterData && (isEdit || isDisabled)) {
            setFormState({
                _id: orchardRegisterData._id,
                title: orchardRegisterData.title,
                plants: orchardRegisterData.plants,
                areas: orchardRegisterData.systemareas,
                date: moment(orchardRegisterData.date).format('YYYY-MM-DDTHH:mm'),
                notes: orchardRegisterData.notes,
            })
        }
    }, [orchardRegisterData])

    useEffect(() => {
        if (errorData && errorData.code === 404 ) {
            history.push(`/system/${params.systemId}/orchard-registers`)
        }
    }, [errorData])

    useEffect(() => {
        if (plants.length && !isDisabled && !isEdit) {
            if(history.location.search && !history.location.search.includes('from-plants')) {
                let searchString = history.location.search.substr(1)
                console.log(history.location, plants.some((el) => el._id === searchString), searchString)
                if (plants.some((el) => el._id === searchString)) {
                    let state = JSON.parse(JSON.stringify(formState))
                    let newArray = [];
                    newArray.push({
                        _id: searchString,
                        name: plants.find((el) => el._id === searchString)?.name
                    })
                    state.plants = newArray;
                    setFormState(state)
                } else if(!history.location.search.includes('from-plants')){
                    history.push(`/system/${params.systemId}/orchard-register/add`)
                }
            }
        }

    }, [plants])

    const onSaveClick = (e) => {
        e.preventDefault();

        let data = {};
        data.title = formState.title;
        data.plants = formState.plants.map((el)=> {return el._id});
        data.systemareas = formState.areas.map((el)=> {return el._id});;
        data.date = formState.date;
        data.notes = formState.notes;

        if(formState._id){
            data._id = formState._id;
            dispatch(updateOrchardRegister(data, params.systemId)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/orchard-registers`)
                }
            })
        } else {
            dispatch(addOrchardRegister(data, params.systemId)).then((res)=>{
                if(res){
                    history.push(`/system/${params.systemId}/orchard-registers`)
                }
            })
        }
    }

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={isEdit ? t('orchardRegister.editOrchardRegister') : isDisabled ? formState.title : t('orchardRegister.addOrchardRegister')}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/orchard-registers`, name: t('orchardRegister.breadcrumb1')},
                        {
                            path:
                                isEdit ? `system/${params.systemId}/orchard-register/edit/${params.orchardId}`
                                    :
                                isDisabled ?  `system/${params.systemId}/orchard-register/view/${params.orchardId}`
                                    :
                                `system/${params.systemId}/orchard-register/add`,

                            name: t(isEdit ? 'orchardRegister.breadcrumbEdit' : isDisabled ? formState.title : 'orchardRegister.breadcrumbAdd')
                        }
                    ]}
                />
                <Card>
                    <CardBody>
                        <OrchardRegisterForm
                            systemareas={systemareas}
                            plants={plants}
                            onSaveClick={onSaveClick}
                            formState={formState}
                            setFormState={setFormState}
                            isDisabled={isDisabled}
                            plantNo={history.location.search.includes('from-plants') ? history.location.search.substr(13) : null}
                            goBack={plants.some((el) => el.value === history.location.search.substr(1))}
                        />
                    </CardBody>
                </Card>
            </div>
        </Layout>
    );
}

export default AddEditOrchard;
