import React, {useEffect, useRef, useState} from "react";
import {compose, withProps} from "recompose"
import {withScriptjs, withGoogleMap, GoogleMap, Marker, Polygon} from "react-google-maps"

import Device from '../../assets/icons/device.svg'
import Plant from '../../assets/icons/plant.svg'
import { config } from "../../config";

const key = config.GOOGLE_API_KEY;
const colors = ['brown', 'orange', 'green', 'purple', 'blue']

const MapView = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `370px`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withScriptjs,
    withGoogleMap
)((props) => {

    const polygonRef = useRef(null)
    const mapRef = useRef(null)

    useEffect(()=>{
        setTimeout(()=>{
            if(mapRef.current && polygonRef.current){
                let bounds = new window.google.maps.LatLngBounds();

                let polPaths = polygonRef.current.getPath().getArray()
                for (let i=0; i<polPaths.length; i++) {
                    let point = new window.google.maps.LatLng(polPaths[i].lat(), polPaths[i].lng());
                    bounds.extend(point);
                }
                mapRef.current.fitBounds(bounds)
            }
        },0)
    },[JSON.stringify(props.system)])

    return (
        <GoogleMap
            ref={mapRef}
            zoom={9}
            center={{lat: 43.7228, lng: 10.4017}}
        >

            {props.devices.filter((el) => el.geoLocation?.lat && el.geoLocation?.lng).map((el, id)=> {
                return(
                    <Marker
                        label={{
                            text:el.name,
                            className:"map-marker-label-device"
                        }}
                        icon={{
                            url: Device
                        }}
                        position={el.geoLocation}
                    />
                )
            })}

            {props.plants.filter((el) => el.geoLocation?.lat && el.geoLocation?.lng).map((el, id)=> {
                return(
                    <Marker
                        label={{
                            text:el.name,
                            className:"map-marker-label-plant"
                        }}
                        icon={{
                            url: Plant
                        }}
                        position={el.geoLocation}
                    />
                )
            })}

            {props.system &&
                <Polygon
                    ref={polygonRef}
                    path={props.system}
                    options={{
                        strokeColor: 'red',
                        fillColor: 'red',
                        strokeWeight: '1',
                        fillOpacity:0.12
                    }}
                    editable={false}
                />
            }

            {props.areas.map((el, id)=> {
                return(
                    <Polygon
                        path={el.paths}
                        options={{
                            strokeColor: colors[id % 5],
                            fillColor: colors[id % 5],
                            strokeWeight: '1',
                            fillOpacity:0.4
                        }}
                        editable={false}
                    />
                )
            })}

        </GoogleMap>
    )
})
export default MapView;