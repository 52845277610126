import { loadingActionTypes } from '../actionTypes/index';



export const pageLoad=()=> async dispatch=> {
    dispatch({
        type: loadingActionTypes.PAGE_RELOAD_REQUEST
    })
}

export const pageLoadStop=()=> async dispatch=> {
    dispatch({
        type: loadingActionTypes.PAGE_RELOAD_STOP
    })
}