import {ruleEntityActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getRuleEntityList = (id, search) => async dispatch => {
    dispatch({type: ruleEntityActionTypes.GET_RULE_ENTITY_LIST_REQUEST})

    try {
        const res = await request({
            url: `rulesystem/ui/ruleentities?system=${id}&page=1&limit=200&${search ? 'searchText=' + search : ''}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: ruleEntityActionTypes.GET_RULE_ENTITY_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: ruleEntityActionTypes.GET_RULE_ENTITY_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getRuleEntityData = (id, systemId) => async dispatch => {
    dispatch({type: ruleEntityActionTypes.GET_RULE_ENTITY_DATA_REQUEST})

    try {
        const res = await request({
            url: `rulesystem/ui/ruleentities/${id}?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: ruleEntityActionTypes.GET_RULE_ENTITY_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: ruleEntityActionTypes.GET_RULE_ENTITY_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getRuleEntityChartData = (id, systemId, dateFilter, startDate, endDate) => async dispatch => {
    dispatch({type: ruleEntityActionTypes.GET_RULE_ENTITY_CHART_REQUEST})

    try {
        const res = await request({
            url: `rulesystem/ui/ruleentities/${id}/history?system=${systemId}${dateFilter ? '&dateFilter=' +dateFilter : ''}${!dateFilter ? '&startDate='+startDate : ''}${!dateFilter ? '&endDate='+endDate : ''}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: ruleEntityActionTypes.GET_RULE_ENTITY_CHART_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: ruleEntityActionTypes.GET_RULE_ENTITY_CHART_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getRuleEntityExportData = (id, systemId, dateFilter, startDate, endDate) => async dispatch => {
    try {
        await request({
            url: `rulesystem/ui/ruleentities/${id}/export?system=${systemId}${dateFilter ? '&dateFilter=' +dateFilter : ''}${!dateFilter ? '&startDate='+startDate : ''}${!dateFilter ? '&endDate='+endDate : ''}`,
            auth: true,
            method: 'GET',
        });
    } catch (err) {
        console.log(err.data)
    }
}