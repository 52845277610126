import {dashboardActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';
import {config} from "../../config";


export const getDashboardDataPLT = (systemId) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_DASHBOARD_DATA_REQUEST})

    const data = {
        areasData: [],
        devicesMap: [],
        gardenMap: {
            areas: [],
            garden: null
        },
        lastNotification: [],
        plantsMap: [],
        systemHealth: null,
        userDetails: null
    };

    let areaData = [];

    try {
        const res = await request({
            url: `core/ui/dashboard?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        if(res["last-notifications"]){
            try {
                data.lastNotification = await request({
                    url: res?.["last-notifications"]?.endpoint.substr(1),
                    auth: true,
                    method: 'GET',
                });
            } catch (err) {
                dispatch({
                    type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                    payload: err ? err.data : null
                });
            }
        }

        if(res["system-health"]){
            try {
                data.systemHealth = await request({
                    url: res?.["system-health"]?.endpoint.substr(1),
                    auth: true,
                    method: 'GET',
                });
            } catch (err) {
                dispatch({
                    type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                    payload: err ? err.data : null
                });
            }
        }

        if(res["garden-map"]){
            if(res["garden-map"].gardenMapEndpoint){
                try {
                    data.gardenMap = await request({
                        url: res?.["garden-map"]?.gardenMapEndpoint.substr(1),
                        auth: true,
                        method: 'GET',
                    });
                } catch (err) {
                    dispatch({
                        type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                        payload: err ? err.data : null
                    });
                }
            }

            if(res["garden-map"].devicesMapEndpoint){
                try {
                    data.devicesMap = await request({
                        url: res?.["garden-map"]?.devicesMapEndpoint.substr(1),
                        auth: true,
                        method: 'GET',
                    });
                } catch (err) {
                    dispatch({
                        type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                        payload: err ? err.data : null
                    });
                }
            }

            if(res["garden-map"].plantsMapEndpoint){
                try {
                    data.plantsMap = await request({
                        url: res?.["garden-map"]?.plantsMapEndpoint.substr(1),
                        auth: true,
                        method: 'GET',
                    });
                } catch (err) {
                    dispatch({
                        type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                        payload: err ? err.data : null
                    });
                }
            }
        }

        data.userDetails = res["welcome-user"];
        data.systemId = systemId;
        dispatch({
            type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_SUCCEED,
            payload: data
        });

        if(res["areas-charts"] && res["areas-charts"].length){
            let allAreas = res["areas-charts"];
            for(let i=0; i<allAreas.length; i++){
                let area = {};
                area.systemAreaName = allAreas[i].systemAreaName;
                area._id = allAreas[i].tempChartEndpoint.split('/').pop().split('?')[0]

                if(allAreas[i].tempChartEndpoint){
                    try {
                        area.tempChart = await request({
                            url: allAreas[i].tempChartEndpoint.substr(1)+ '&dateFilter=today',
                            auth: true,
                            method: 'GET',
                        });
                    } catch (err) {
                        dispatch({
                            type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                            payload: err ? err.data : null
                        });
                    }
                }

                if(allAreas[i].humChartEndpoint){
                    try {
                        area.humChart = await request({
                            url: allAreas[i].humChartEndpoint.substr(1)+ '&dateFilter=today',
                            auth: true,
                            method: 'GET',
                        });
                    } catch (err) {
                        dispatch({
                            type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                            payload: err ? err.data : null
                        });
                    }
                }

                areaData.push(area)
            }
        }

        let dataObj = {
            areaData: areaData,
            systemId: systemId
        }
        dispatch({
            type: dashboardActionTypes.GET_DASHBOARD_DATA_SUCCEED,
            payload: dataObj
        });

    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_DASHBOARD_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getDashboardDataVLM = (systemId) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_DASHBOARD_DATA_REQUEST})

    const data = {
        shipMap: {
            devicesMap: [],
            topView: null,
            sideView: null,
        },
        lastNotification: [],
        systemHealth: null,
        userDetails: null,
        tempData: null,
        torsionData: [],
        mainDeviceData: null
    };

    let onboardData = [];

    try {
        const res = await request({
            url: `core/ui/dashboard?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        if(res["last-notifications"]){
            try {
                data.lastNotification = await request({
                    url: res?.["last-notifications"]?.endpoint.substr(1),
                    auth: true,
                    method: 'GET',
                });
            } catch (err) {
                dispatch({
                    type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                    payload: err ? err.data : null
                });
            }
        }

        if(res["system-health"]){
            try {
                data.systemHealth = await request({
                    url: res?.["system-health"]?.endpoint.substr(1),
                    auth: true,
                    method: 'GET',
                });
            } catch (err) {
                dispatch({
                    type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                    payload: err ? err.data : null
                });
            }
        }

        if(res["ship-map"]){
            if(res["ship-map"].topViewEndpoint){
                try {
                    data.shipMap.topView = await request({
                        url: res?.["ship-map"]?.topViewEndpoint.substr(1),
                        auth: true,
                        method: 'GET',
                        responseType: 'blob'
                    });
                } catch (err) {
                    dispatch({
                        type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                        payload: err ? err.data : null
                    });
                }
            }

            if(res["ship-map"].sideViewEndpoint){
                try {
                    data.shipMap.sideView = await request({
                        url: res?.["ship-map"]?.sideViewEndpoint.substr(1),
                        auth: true,
                        method: 'GET',
                        responseType: 'blob'
                    });
                } catch (err) {
                    dispatch({
                        type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                        payload: err ? err.data : null
                    });
                }
            }

            if(res["ship-map"].devicesMapEndpoint){
                try {
                    data.shipMap.devicesMap = await request({
                        url: res?.["ship-map"]?.devicesMapEndpoint,
                        auth: true,
                        method: 'GET',
                    });
                } catch (err) {
                    dispatch({
                        type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                        payload: err ? err.data : null
                    });
                }
            }
        }

        if(res["main-device-chart"]){
            try {
                data.mainDeviceData = await request({
                    url: res?.["main-device-chart"]?.endpoint.substr(1)+ '&dateFilter=lastmonth',
                    auth: true,
                    method: 'GET',
                });
            } catch (err) {
                dispatch({
                    type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                    payload: err ? err.data : null
                });
            }
        }

        if(res["torsion-chart"]){
            try {
                data.torsionData = await request({
                    url: res?.["torsion-chart"]?.endpoint.substr(1)+ '&dateFilter=lastmonth',
                    auth: true,
                    method: 'GET',
                });
            } catch (err) {
                dispatch({
                    type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                    payload: err ? err.data : null
                });
            }
        }

        if(res["temperature-widget"]){
            try {
                data.tempData = await request({
                    url: res?.["temperature-widget"]?.endpoint.substr(1),
                    auth: true,
                    method: 'GET',
                });
            } catch (err) {
                dispatch({
                    type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                    payload: err ? err.data : null
                });
            }
        }

        if(res["onboard-devices"]){
            try {
                onboardData = await request({
                    url: res?.["onboard-devices"]?.endpoint.substr(1),
                    auth: true,
                    method: 'GET',
                });
            } catch (err) {
                dispatch({
                    type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_FAILED,
                    payload: err ? err.data : null
                });
            }
        }

        data.userDetails = res["welcome-user"];

        dispatch({
            type: dashboardActionTypes.GET_DASHBOARD_SINGLE_DATA_SUCCEED,
            payload: data
        });
        dispatch({
            type: dashboardActionTypes.GET_DASHBOARD_DATA_SUCCEED,
            payload: onboardData
        });

    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_DASHBOARD_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getLastNotificationData = (systemId) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_LAST_NOTIFICATION_LIST_REQUEST})

    try {
        const res = await request({
            url: `core/ui/dashboard/lastnotifications?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: dashboardActionTypes.GET_LAST_NOTIFICATION_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_LAST_NOTIFICATION_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getSystemHealthData = (systemId) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_SYSTEM_HEALTH_DETAILS_REQUEST})

    const TARGET_CODE = config.TARGET_CODE
    let url;

    if(TARGET_CODE === 'PLT')
        url = `rulesystem/ui/dashboard/systemhealth?system=${systemId}`
    else
        url = `driversystem/ui/dashboard/systemhealth?system=${systemId}`

    try {
        const res = await request({
            url: url,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: dashboardActionTypes.GET_SYSTEM_HEALTH_DETAILS_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_SYSTEM_HEALTH_DETAILS_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getShipMapData = (systemId) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_SHIP_MAP_DETAILS_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/dashboard/devicesmap?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: dashboardActionTypes.GET_SHIP_MAP_DETAILS_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_SHIP_MAP_DETAILS_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getOnBoardDevicesData = (systemId) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_ONBOARD_DEVICES_DETAILS_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/dashboard/onboarddevices?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: dashboardActionTypes.GET_ONBOARD_DEVICES_DETAILS_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_ONBOARD_DEVICES_DETAILS_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getTemperatureData = (systemId) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_TEMPERATURE_DETAILS_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/dashboard/temperaturewidget?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: dashboardActionTypes.GET_TEMPERATURE_DETAILS_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_TEMPERATURE_DETAILS_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getTorsionChartData = (systemId, dateFilter) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_TORSION_CHART_DATA_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/dashboard/torsionchart?system=${systemId}&dateFilter=${dateFilter}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: dashboardActionTypes.GET_TORSION_CHART_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_TORSION_CHART_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getMainDeviceChartData = (systemId, dateFilter) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_MAIN_DEVICE_CHART_DATA_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/dashboard/maindevicechart?system=${systemId}&dateFilter=${dateFilter}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: dashboardActionTypes.GET_MAIN_DEVICE_CHART_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_MAIN_DEVICE_CHART_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getTempChartData = (id, systemId, dateFilter) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_TEMP_CHART_DATA_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/dashboard/systemareatempchart/${id}?system=${systemId}&dateFilter=${dateFilter}`,
            auth: true,
            method: 'GET',
        });

        let data = {}

        data._id = id;
        data.data = res;

        dispatch({
            type: dashboardActionTypes.GET_TEMP_CHART_DATA_SUCCEED,
            payload: data
        });
    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_TEMP_CHART_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getHumChartData = (id, systemId, dateFilter) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_HUM_CHART_DATA_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/dashboard/systemareahumchart/${id}?system=${systemId}&dateFilter=${dateFilter}`,
            auth: true,
            method: 'GET',
        });

        let data = {}

        data._id = id;
        data.data = res;

        dispatch({
            type: dashboardActionTypes.GET_HUM_CHART_DATA_SUCCEED,
            payload: data
        });
    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_HUM_CHART_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getDashboardAlertData = (id, systemId) => async dispatch => {
    dispatch({type: dashboardActionTypes.GET_DASHBOARD_ALERT_DATA_REQUEST})

    try {
        const res = await request({
            url: `rulesystem/ui/dashboard/alertconfigurations/${id}?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: dashboardActionTypes.GET_DASHBOARD_ALERT_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: dashboardActionTypes.GET_DASHBOARD_ALERT_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const addDashboardAlert = (Data, id) => async dispatch => {
    dispatch({type: dashboardActionTypes.CREATE_DASHBOARD_ALERT_REQUEST});

    let data = new FormData();

    data.append('type', Data.type);
    data.append('value', Data.value);
    data.append('condition', Data.condition);
    data.append('level', Data.level);

    if(Data.type === 'variation')
        data.append('hours', Data.hours);

    try {
        const res = await request({
            url: `rulesystem/ui/dashboard/alertconfigurations/${Data.sensor}?system=${id}`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: dashboardActionTypes.CREATE_DASHBOARD_ALERT_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: dashboardActionTypes.CREATE_DASHBOARD_ALERT_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const updateDashboardAlert = (Data, device, systemId) => async dispatch => {
    dispatch({type: dashboardActionTypes.UPDATE_DASHBOARD_ALERT_REQUEST});

    let data = new FormData();

    data.append('type', Data.type);
    data.append('value', Data.value);
    data.append('condition', Data.condition);
    data.append('level', Data.level);

    if(Data.type === 'variation')
        data.append('hours', Data.hours);

    try {
        const res = await request({
            url: `rulesystem/ui/dashboard/alertconfigurations/${device}/${Data._id}?system=${systemId}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: dashboardActionTypes.UPDATE_DASHBOARD_ALERT_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: dashboardActionTypes.UPDATE_DASHBOARD_ALERT_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const deleteDashboardAlert = (id, device, systemId) => async dispatch => {
    dispatch({type: dashboardActionTypes.DELETE_DASHBOARD_ALERT_REQUEST});

    try {
        const res = await request({
            url: `rulesystem/ui/dashboard/alertconfigurations/${device}/${id}?system=${systemId}`,
            auth: true,
            method: 'DELETE',
        });

        dispatch({
            type: dashboardActionTypes.DELETE_DASHBOARD_ALERT_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: dashboardActionTypes.DELETE_DASHBOARD_ALERT_FAILED,
            payload: err ? err.data : null
        });
    }
}
