export const categoryData = [
    { label: 'Deck', value: 'Deck' },
    { label: 'Bowrider', value: 'Bowrider' },
    { label: 'Catamaran', value: 'Catamaran' },
    { label: 'Motor Yacht', value: 'Motor Yacht' }
]

export const roleData = [
    { label: 'Administrator', value: 'admin' },
    { label: 'Global Supervisor', value: 'globalsupervisor' },
    { label: 'Supervisor', value: 'supervisor' },
    { label: 'Customer', value: 'customer' },
    { label: 'Guest', value: 'guest' },
]

export const dataConsumptionData = [
    {value:1, name: "dashboard.dataConsumption.form.value1"},
    {value:2, name: "dashboard.dataConsumption.form.value2"},
    {value:3, name: "dashboard.dataConsumption.form.value3"},
]