const valueAndUom = (value, uom) => `${value}${uom?.length ? ` ${uom}` : ""}`;

// Green usability
function greenUsabilityLabel(type, value, uom, tFn) {
    const translatedValue = tFn(`${type}.${value}`);
    return valueAndUom(translatedValue, uom);
}

// Wellbeing index for users
function wellbeingIndexUsersLabel(type, value, attributes, uom, tFn) {
    const valueWithUom = valueAndUom(value, uom);
    const labelId = attributes?.labelId;
    if(labelId) {
        const label = tFn(`${type}.${labelId}`);
        return `${label} (${valueWithUom})`;
    }
    return valueWithUom;
}

// Wellbeing index for operators
function wellbeingIndexOperatorsLabel(type, value, attributes, uom, tFn) {
    const valueWithUom = valueAndUom(value, uom);
    const labelId = attributes?.labelId;
    if(labelId) {
        const label = tFn(`${type}.${labelId}`);
        return label;
    }
    return valueWithUom;
}

// Degree days Summation
function degreeDaysSumLabel(type, value, attributes, tFn) {
    const genLabel = tFn(`${type}.generation`);
    const currentGen = parseInt(attributes?.currentGen || "0");
    if(currentGen <= 0) {
        return `${tFn(`${type}.no-generation`)} (${value})`;
    }
    return `${genLabel} ${currentGen} (${value})`;
}

// Generic Infection
function genericInfectionLabel(type, value, attributes, tFn) {
    const riskLevel = attributes?.riskLevel;
    return `${tFn(`${type}.${riskLevel}`)} (${value})`;
}


// Generic
function getRuleEntityStateLabel(type, value, attributes, uom, tFn) {
    if (value?.length) {
        const typeStr = type?.length ? type.trim() : "";
        switch (typeStr) {
            case "green-usability-sm":
            case "green-usability-rain":
            case "green-usability-ws":
                return greenUsabilityLabel(type, value, uom, tFn);
            case "wellbeing-index-users":
                return wellbeingIndexUsersLabel(type, value, attributes, uom, tFn);
            case "wellbeing-index-operators":
                return wellbeingIndexOperatorsLabel(type, value, attributes, uom, tFn);
            case "degreedays-sum":
                return degreeDaysSumLabel(type, value, attributes, tFn);
            case "generic-infection":
                return genericInfectionLabel(type, value, attributes, tFn);
            default:
                return valueAndUom(value, uom);
        }
    }

    return "-";
}

export default getRuleEntityStateLabel;
