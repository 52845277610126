import React, { useState } from "react"
import { Col, Card, CardBody, Button } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import moment from 'moment'
import {useTranslation} from "react-i18next";

// const en = require("apexcharts/dist/locales/en.json")

const OverviewGraph=(props)=>{

  const {t} = useTranslation();

  const data = [{
    name: 'Sensor Data',
    data: props.overviewGraphData ? props.overviewGraphData : []
  }]

  const [options, setOptions] = useState({
    chart: { 
      toolbar: "false", 
      // locales: [en],
      defaultLocale: 'en'
    },
    dataLabels: { enabled: !1 },
    stroke: { curve: "smooth", width: 2 },
    markers: { size: 0, style: "hollow" },
    xaxis: {
      type: "datetime",
      min:  moment().subtract(1, 'months').valueOf(),
      max: moment().valueOf(),      
      tickAmount: 6,
      labels:{
          format:'dd MMM',
          datetimeUTC: false
      }
    },
    colors: ["#f1b44c"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.6,
        opacityTo: 0.05,
        stops: [42, 100, 100, 100],
      },
    },
    tooltip:{
        x: { format:'dd MMM' }
      }
  })

  const [activeState, setActiveState] = useState({
    activeM: true,
    active6M: false,
    activeY: false,
    activeA: false,
  });  

  const updateChartMonthly=()=>{    
    props.getData('lastmonth');

    setOptions({
      ...options,
      xaxis:{
        ...options.xaxis,
        min: moment().subtract(1, 'months').valueOf(),
        labels:{
          format:'dd MMM',
          datetimeUTC: false
        }
      },
      tooltip:{
        x: { format:'dd MMM' }
      }
    })
    setActiveState({
      ...activeState,
        activeM: true,
    })
    props.setGraphState('1M');    
  }

  const updateChartSixMonth=()=>{    
    props.getData('lastsixmonth');

    setOptions({
      ...options,
      xaxis:{
        ...options.xaxis,
        min: moment().subtract(6, 'months').valueOf(),
        labels:{
          format:'MMM',
          datetimeUTC: false
        }
      },
      tooltip:{
        x: { format:'MMM' }
      }
    })
    setActiveState({
      ...activeState,
        active6M: true,
    })
    props.setGraphState('6M');
  }

  const updateChartYearly=()=>{   
    props.getData('lastyear')

    setOptions({
      ...options,
      xaxis:{
        ...options.xaxis,
        min: moment().subtract(1, 'years').valueOf(),
        labels:{
          format:'MMM',
          datetimeUTC: false
        }
      },
      tooltip:{
        x: { format:'MMM' }
      }
    })
    setActiveState({
      ...activeState,
        activeY: true,
    })
    props.setGraphState('1Y');
  }
    return (
      <React.Fragment>
        <Col>
          <Card className='overview-card'>
            <CardBody>
              <h4 className="card-title mb-3 text-start">{t("dashboard.overviewGraph.topic")}</h4>
              <div className='pt-2 mt-4 pb-2'>
                <div id="overview-chart" className="apex-charts" dir="ltr">
                  <div className="toolbar button-items text-end">
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={props.graphState === '1M' ? 'btn-selected' : ''}
                      onClick={updateChartMonthly}
                      id="one_month"
                      style={{marginLeft:5}}
                    >
                      1M
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={props.graphState === '6M' ? 'btn-selected' : ''}
                      onClick={updateChartSixMonth}
                      id="six_months"
                      style={{marginLeft:5}}
                    >
                      6M
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      type="button"
                      className={props.graphState === '1Y' ? 'btn-selected' : ''}
                      onClick={updateChartYearly}
                      id="one_year"
                      style={{marginLeft:5}}
                    >
                      1Y
                    </Button>
                  </div>
                  <div id="overview-chart-timeline">
                    <ReactApexChart
                      options={options}
                      series={data}
                      type="area"
                      height={240}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
}

export default OverviewGraph
