import {systemAreaActionTypes} from "../actionTypes/index";
import produce from "immer";

const initialState = {
    availableSystemAreas:[],
    systemAreaList: [],
    systemAreaData: null,
    totalSize: 0
};

const systemAreaData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case systemAreaActionTypes.GET_AVAILABLE_SYSTEM_AREAS_SUCCEED: {
                state.availableSystemAreas = payload;
                return state;
            }
            case systemAreaActionTypes.GET_SYSTEM_AREA_LIST_SUCCEED: {
                state.systemAreaList = payload.results;
                state.totalSize = payload.totalResults;
                return state;
            }
            case systemAreaActionTypes.GET_SYSTEM_AREA_DATA_SUCCEED: {
                state.systemAreaData = payload;
                return state;
            }
            case systemAreaActionTypes.CREATE_SYSTEM_AREA_SUCCEED: {
                state.systemAreaList.push(payload);
                state.totalSize++;
                return state;
            }
            case systemAreaActionTypes.UPDATE_SYSTEM_AREA_SUCCEED: {
                const updatedId = state.systemAreaList.findIndex((item)=>item._id===payload._id);
                state.systemAreaList[updatedId]=payload;
                return state;
            }
            case systemAreaActionTypes.DELETE_SYSTEM_AREA_SUCCEED: {
                const deletedId = state.systemAreaList.findIndex((item)=>item._id===payload._id);
                state.systemAreaList.splice(deletedId,1);
                state.totalSize--;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default systemAreaData;
