import React, {useEffect, useState} from "react";
import {Button, Row} from "react-bootstrap";
import Select from "react-select";
import {Input} from "reactstrap";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Map from "../common/Map";
import { config } from '../../config';

const DeviceForm = (props) => {

    const TARGET_CODE = config.TARGET_CODE;

    const {t} = useTranslation();
    const params = useParams();

    const [systemMarker, setSystemMarker] = useState(null)

    useEffect(()=>{
        if(props.systemPolygon && props.systemPolygon.length){
            let markerVal = settingMapCenter();
            setSystemMarker(markerVal)
        }
    }, [props.systemPolygon])

    const settingMapCenter=()=>{
        let lng = 0;
        let lat = 0;
        for(let i=0; i<props.systemPolygon.length; i++){
            lat += Number(props.systemPolygon[i][0]);
            lng += Number(props.systemPolygon[i][1]);
        }

        return {
            lat: lat/props.systemPolygon.length,
            lng: lng/props.systemPolygon.length
        }
    }

    const onChangeHandler = (e) => {
        if (e.target) {
            const {name, value} = e.target;
            props.setFormState({
                ...props.formState,
                [name]: value
            })
        } else {
            let arr = [];
            e.values.forEach((element) => {
                arr.push({
                    _id: element.value,
                    name: element.label,
                });
            });
            props.setFormState({...props.formState, [e.name]: arr});
        }
    }

    const updateMarker = (e) => {
        props.setFormState({
            ...props.formState,
            'geoLocation': e
        })
    }

    const resetPosition=()=>{
        props.setFormState({
            ...props.formState,
            'geoLocation': settingMapCenter()
        })
    }

    const customStyles = {

        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? '#eff2f7' : '',
            minHeight: '37px',
            maxHeight: '37px',
            boxShadow: 'none',
            border: '1px solid #ced4da',
            '&:active': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },
            '&:focus': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },
            '&:hover': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },

        }),

        multiValue: (provided) => ({
            ...provided,
            border: '#D5D5D5 solid 0.5px',
            borderRadius: '4px',
            backgroundColor: '#F8F9FA'
        }),

        multiValueLabel: (provided) => ({
            ...provided,
            color: '#74788D'
        }),

        multiValueRemove: (provided, state) => ({
            ...provided,
            color: '#74788D',
            width: state.isDisabled ? '0px' : '',
            '&:hover': {backgroundColor: '#F8F9FA', color: '#74788D'}

        }),


    }

    return (
        <Row>
            <div className='view-entity-container'>
            <div className='mt-3 col-12'>
                <div><label className='mb-2 p-0' htmlFor='driver'>{t('devices.form.field1.name')}</label></div>
                <div className='form-control disabled' style={{minHeight:'37px'}}>{props.formState.driver}</div>
            </div>

            <div className='mt-3 col-12'>
                <div><label className='mb-2 p-0' htmlFor='deviceType'>{t('devices.form.field2.name')}</label></div>
                <div className='form-control' style={{minHeight:'37px'}}>{props.formState.deviceType}</div>
            </div>

            <div className='mt-3 col-12'>
                <div><label className='mb-2 p-0' htmlFor='name'>{t('devices.form.field3.name')}</label></div>
                <div className='form-control' style={{minHeight:'37px'}}>{props.formState.name}</div>
            </div>

            <div className='mt-3 col-12'>
                <div><label className='mb-2 p-0' htmlFor='slugId'>{t('devices.form.field4.name')}</label></div>
                <div className='form-control disabled' style={{minHeight:'37px'}}>{props.formState.slugId}</div>
            </div>
            </div>

        <form onSubmit={props.onSaveClick}>
            <Row>
                {TARGET_CODE === 'PLT' &&
                    <div>
                        {/* <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='systemArea'>{t('devices.form.field5.name')}</label>
                            </div>
                            <div>
                                <Input
                                    type='select'
                                    name='systemArea'
                                    id='systemArea'
                                    className='form-control form-select'
                                    value={props.formState.systemArea}
                                    onChange={onChangeHandler}
                                >
                                    <option value=''>{t('devices.form.field5.placeholder')}</option>
                                    {props.areas.map((el)=>{
                                        return(
                                            <option key={el._id} value={el._id}>{el.name}</option>
                                        )}
                                    )}
                                </Input>
                            </div>
                        </div> */}

                        <div className="mt-3 col-12">
                            <div><label className="mb-2 p-0" htmlFor="areas">{t("devices.form.field5.name")}</label></div>
                            <div>
                                <Select
                                    isMulti
                                    name="areas"
                                    options={props.systemareas.map((el)=> {
                                        return {
                                            label: el.name,
                                            value: el._id
                                        }
                                    })}
                                    className="basic-multi-select"
                                    placeholder={t("devices.form.field5.placeholder")}
                                    components={{IndicatorSeparator: () => null}}
                                    isClearable={false}
                                    onChange={(e) => {
                                        let event = {
                                            name: "areas",
                                            values: e,
                                        };
                                        onChangeHandler(event);
                                    }}
                                    value={props.formState.areas.map((item) => ({
                                        value: item._id,
                                        label: item.name,
                                    }))}
                                    isDisabled={props.isDisabled}
                                    styles={customStyles}
                                />
                            </div>
                        </div>

                        <div className='mt-3 col-md-6'>
                            <div><label className='p-0'>{t('devices.form.field6.name')} {t('devices.form.field6.placeholder')}</label></div>
                            <div className='text-end mt-3'><button type='button' onClick={resetPosition} className='reset-button ms-auto'>Reset Position</button></div>

                            <div>
                                <Map
                                    systemPolygon={
                                        (props.systemPolygon && props.systemPolygon.length) ?
                                            props.systemPolygon.map((item)=>{
                                                return{
                                                    lat: item[0],
                                                    lng: item[1]
                                                }
                                            })
                                            : null
                                    }
                                    disablePolygon={true}
                                    updateMarker={updateMarker}
                                    marker={props.formState.geoLocation ? props.formState.geoLocation : systemMarker ? systemMarker : null}
                                />
                            </div>
                        </div>
                    </div>
                }

                {TARGET_CODE !== 'PLT' &&
                    <div>
                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='topViewTop'>{t('devices.form.field5.name')}</label></div>
                            <div>
                                <Input
                                    type='number'
                                    max={100}
                                    min={0}
                                    name='topViewTop'
                                    id='topViewTop'
                                    className='form-control'
                                    value={props.formState.topViewTop}
                                    onChange={onChangeHandler}
                                    placeholder={t('devices.form.field5.placeholder')}
                                    step={0.0000000000001}
                                />
                            </div>
                        </div>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='topViewLeft'>{t('devices.form.field6.name')}</label></div>
                            <div>
                                <Input
                                    type='number'
                                    max={100}
                                    min={0}
                                    name='topViewLeft'
                                    id='topViewLeft'
                                    className='form-control'
                                    value={props.formState.topViewLeft}
                                    onChange={onChangeHandler}
                                    placeholder={t('devices.form.field6.placeholder')}
                                    step={0.0000000000001}
                                />
                            </div>
                        </div>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='sideViewTop'>{t('devices.form.field7.name')}</label></div>
                            <div>
                                <Input
                                    type='number'
                                    max={100}
                                    min={0}
                                    name='sideViewTop'
                                    id='sideViewTop'
                                    className='form-control'
                                    value={props.formState.sideViewTop}
                                    onChange={onChangeHandler}
                                    placeholder={t('devices.form.field7.placeholder')}
                                    step={0.0000000000001}
                                />
                            </div>
                        </div>

                        <div className='mt-3 col-12'>
                            <div><label className='mb-2 p-0'
                                        htmlFor='sideViewLeft'>{t('devices.form.field8.name')}</label></div>
                            <div>
                                <Input
                                    type='number'
                                    max={100}
                                    min={0}
                                    name='sideViewLeft'
                                    id='sideViewLeft'
                                    className='form-control'
                                    value={props.formState.sideViewLeft}
                                    onChange={onChangeHandler}
                                    placeholder={t('devices.form.field8.placeholder')}
                                    step={0.0000000000001}
                                />
                            </div>
                        </div>
                    </div>
                }

            </Row>

            <div className='col-12 text-end mt-3 form-btn-grp'>
                <Button color='primary' type='submit' className='me-2'>{t('main.save')}</Button>
                <Link to={`/system/${params.systemId}/devices`}><Button type='button'
                                                                        className='btn-secondary'>{t('main.cancel')}</Button></Link>
            </div>

        </form>
        </Row>
    )
}

export default DeviceForm;