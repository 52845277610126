import {orchardRegisterActionTypes} from "../actionTypes/index";
import produce from "immer";

const initialState = {
    orchardRegisterList: [],
    plantOrchardRegisterList:[],
    orchardRegisterData: null,
    plantTotalSize:0,
    totalSize: 0
};

const orchardRegisterData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case orchardRegisterActionTypes.GET_ORCHARD_REGISTER_LIST_SUCCEED: {
                state.orchardRegisterList = payload.results;
                state.totalSize = payload.totalResults;
                return state;
            }
            case orchardRegisterActionTypes.GET_PLANT_ORCHARD_REGISTER_LIST_SUCCEED: {
                state.plantOrchardRegisterList = payload.results;
                state.plantTotalSize = payload.totalResults;
                return state;
            }
            case orchardRegisterActionTypes.GET_ORCHARD_REGISTER_DATA_SUCCEED: {
                state.orchardRegisterData = payload;
                return state;
            }
            case orchardRegisterActionTypes.CREATE_ORCHARD_REGISTER_SUCCEED: {
                state.orchardRegisterList.push(payload);
                state.totalSize++;
                return state;
            }
            case orchardRegisterActionTypes.UPDATE_ORCHARD_REGISTER_SUCCEED: {
                const updatedId = state.orchardRegisterList.findIndex((item)=>item._id===payload._id);
                state.orchardRegisterList[updatedId]=payload;
                return state;
            }
            case orchardRegisterActionTypes.DELETE_ORCHARD_REGISTER_SUCCEED: {
                const deletedId = state.orchardRegisterList.findIndex((item)=>item._id===payload._id);
                state.orchardRegisterList.splice(deletedId,1);
                state.totalSize--;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default orchardRegisterData;
