import React from "react";
import {Row} from "react-bootstrap";
import {Input} from "reactstrap";
import {useTranslation} from "react-i18next";

import SearchSelect from '../common/SearchSelect'

const SystemBasic=(props)=>{

    const { t } = useTranslation();

    const onChangeHandler=(e, Name)=>{
        let name;
        let value;

        if(Name){
            name = Name;
            value = e?.value
        } else {
            name = e.target.name;
            value = e.target.value;
        }
        if(!value || value.length <= 50) {
            props.setFormState({
                ...props.formState,
                [name]: value ? value : ''
            })
        }
    }

    const copyContents=()=>{
        navigator.clipboard.writeText(props.formState.systemToken);
    }

    return(

        <div className='pb-3'>
            <Row><div className='fw-bold'>{t('systems.form1.card.topic')} *</div></Row>
            <Row><div className='fw-light text-muted mt-1 mb-3'>{t('systems.form1.card.subTopic')}.</div></Row>
            <Row>
                <div className='mt-3 col-md-6'>
                    <div><label className='mb-2 p-0' htmlFor='name'>{t('systems.form1.field1.name')} *</label></div>
                    <div className='form-limitation-container'>
                        <Input
                            type='text'
                            name='name'
                            id='name'
                            className='form-control'
                            value={props.formState.name}
                            onChange={onChangeHandler}
                            required
                        />
                        <div>{props.formState.name.length}/50</div>
                    </div>
                </div>

                <div className='mt-3 col-md-6'>
                    <div><label className='mb-2 p-0' htmlFor='customer'>{t('systems.form1.field2.name')} *</label></div>
                    <div>
                        <SearchSelect
                            name="customer"
                            placeholder={t('systems.form1.field2.placeholder')}
                            data={props.customers.map((item)=>{
                                return{
                                    value: item._id,
                                    label: item.fullname
                                }
                            })}
                            value={{
                                label: props.customers.find((el)=> el._id === props.formState.customer)?.fullname,
                                value: props.formState.customer
                            }}
                            onChangeHandler={(e)=> onChangeHandler(e, 'customer')}
                        />
                    </div>
                </div>
            </Row>

            <Row>
                <div className='mt-3 col-md-6'>
                    <div><label className='mb-2 p-0' htmlFor='description'>{t('systems.form1.field3.name')}</label></div>
                    <div className='form-limitation-container'>
                        <Input
                            type='text'
                            name='description'
                            id='description'
                            className='form-control'
                            value={props.formState.description}
                            onChange={onChangeHandler}
                        />
                        <div>{props.formState.description.length}/50</div>
                    </div>
                </div>

                <div className='mt-3 col-md-6'>
                    <div><label className='mb-2 p-0' htmlFor='supervisor'>{t('systems.form1.field4.name')}</label></div>
                    <div>
                        <SearchSelect
                            name="supervisor"
                            placeholder={t('systems.form1.field4.placeholder')}
                            data={props.supervisors.map((item)=>{
                                return{
                                    value: item._id,
                                    label: item.fullname
                                }
                            })}
                            value={props.formState.supervisor
                                ?
                                    {
                                        label: props.supervisors.find((el)=> el._id === props.formState.supervisor)?.fullname,
                                        value: props.formState.supervisor
                                    }
                                : null
                            }
                            onChangeHandler={(e)=> onChangeHandler(e, 'supervisor')}
                            isClearable={true}
                        />
                    </div>
                </div>
            </Row>

            {props.isEdit &&
                <Row>
                    <div className='mt-3 col-md-6'>
                        <div><label className='mb-2 p-0' htmlFor='systemToken'>{t('System Token')} *</label></div>
                        <div style={{position: 'relative'}}>
                            <Input
                                type='text'
                                name='systemToken'
                                id='systemToken'
                                className='form-control'
                                value={props.formState.systemToken}
                                disabled={true}
                            />
                            <i style={{position: 'absolute', right: '8px', top: '11px', cursor: 'pointer'}} onClick={copyContents} className='bx bxs-copy bx-xs'/>
                        </div>
                    </div>
                </Row>
            }

        </div>
    )
}


export default SystemBasic;