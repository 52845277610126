import {notificationActionTypes} from "../actionTypes/index";
import produce from "immer";
import moment from "moment";

const initialState = {
    notificationList: [],
    unreadCount: 0,
    totalSize: 0,
    newNotificationList: [],
    oldNotificationList: [],
};

const notificationData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case notificationActionTypes.GET_NOTIFICATION_LIST_SUCCEED: {
                state.notificationList = payload.results;
                state.totalSize = payload.totalResults;
                return state;
            }
            case notificationActionTypes.GET_NEW_NOTIFICATION_LIST_SUCCEED: {
                let data = payload.filter((el)=> !state.oldNotificationList.some((el2)=> el._id === el2._id));
                state.newNotificationList = data;

                for(let i=0; i < state.oldNotificationList.length; i++){
                    const today = moment();
                    const diff = today.diff(state.oldNotificationList[i].time, 'seconds');

                    if(diff > 60){
                        state.oldNotificationList.splice(i, 1)
                    }
                }

                if(data.length) {
                    for(let i=0; i<data.length; i++) {
                        state.oldNotificationList.push(data[i]);
                    }
                }
                return state;
            }
            case notificationActionTypes.GET_UNREAD_NOTIFICATION_COUNT_SUCCEED: {
                state.unreadCount = payload.unreadCount;
                return state;
            }
            case notificationActionTypes.NOTIFICATION_MARK_AS_READ_UNREAD_SUCCEED: {
                const updatedId = state.notificationList.findIndex((item)=>item._id===payload._id);
                state.notificationList[updatedId]=payload;
                return state;
            }
            case notificationActionTypes.NOTIFICATION_MARK_ALL_AS_READ_SUCCEED: {
                for(let i=0; i<state.notificationList.length; i++){
                    state.notificationList[i].read = true;
                }
                state.unreadCount = 0;
                return state;
            }
            case notificationActionTypes.DELETE_NOTIFICATION_SUCCEED: {
                const deletedId = state.notificationList.findIndex((item)=>item._id===payload._id);
                state.notificationList.splice(deletedId,1);
                state.totalSize--;
                return state;
            }
            case notificationActionTypes.DELETE_ALL_NOTIFICATION_SUCCEED: {
                state.notificationList = [];
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default notificationData;
