import React, {useEffect, useState} from "react";
import moment from "moment";
import {Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import AlertComp from "../../components/dashboard/AlertComp";
import SystemHealth from "../../components/dashboard/SystemHealth";
import BoatViews from "../../components/dashboard/vlm/BoatViews";
import CompareGraph from "../../components/dashboard/vlm/CompareGraph";
import TemparatureDetails from "../../components/dashboard/vlm/TemparatureDetails";
import SensorInformation from "../../components/dashboard/vlm/SensorInformation";
import OverviewGraph from "../../components/dashboard/vlm/OverviewGraph";
import AlarmSensorModal from "../../components/dashboard/vlm/AlarmSensorModal";
import UserDetails from "../../components/dashboard/UserDetails";
import {
    addDashboardAlert,
    deleteDashboardAlert,
    getDashboardAlertData,
    getMainDeviceChartData,
    getOnBoardDevicesData, getShipMapData, getSystemHealthData,
    getTemperatureData,
    getTorsionChartData,
    updateDashboardAlert
} from "../../redux/actions/dashboardActions";
import {
    onBoardDevicesTimeOut,
    shipMapTimeOut, systemHealthTimeOut,
    temperatureTimeOut
} from "../../utils/timeoutData";
import {config} from "../../config";
import DataConsumption from "../../components/dashboard/vlm/DataConsumption";

import useInterval from "../../helpers/timeoutHelper"

const DashboardVLM = (props) => {

    const TARGET_CODE = config.TARGET_CODE;

    const {t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();

    const available = useSelector((el) => el.systemData.availableList)
    const loading = useSelector((state) => state.loadingData.loading)

    const [compareGraphState, setCompareGraphState] = useState('1M');
    const [overviewGraphState, setOverviewGraphState] = useState('1M');

    const [editingConfigurations, setEditingConfigurations] = useState([])
    const [alarmModal, setAlarmModal] = useState({
        name:'',
        sensor:null,
        isOpen:false
    });

    useInterval(() => {
        if(available.some((el) => el._id === params.systemId)) {
            if(!loading) dispatch(getOnBoardDevicesData(params.systemId));
        }
    }, onBoardDevicesTimeOut);

    useInterval(() => {
        if(available.some((el) => el._id === params.systemId)) {
            if(!loading) dispatch(getTemperatureData(params.systemId));
        }
    }, temperatureTimeOut);

    useInterval(() => {
        if(available.some((el) => el._id === params.systemId)) {
            if(!loading) dispatch(getShipMapData(params.systemId));
        }
    }, shipMapTimeOut);

    const handleConfigurationSave=(data)=>{
        if(!data._id){
            data.sensor = alarmModal.sensor;

            console.log(data)

            dispatch(addDashboardAlert(data, params.systemId));

            let DataArray = JSON.parse(JSON.stringify(editingConfigurations))
            DataArray.splice(DataArray.findIndex(item => item.notSavedId === data.notSavedId))
            setEditingConfigurations(DataArray)
        }
        else{
            dispatch(updateDashboardAlert(data, alarmModal.sensor , params.systemId));

            if(editingConfigurations.findIndex(item => item._id === data._id)){
                let DataArray = JSON.parse(JSON.stringify(editingConfigurations))

                DataArray.splice(DataArray.findIndex(item => item._id === data._id))
                setEditingConfigurations(DataArray)
            }
        }
    }

    const handleConfigurationDelete=(data)=>{
        if(data.notSavedId){
            let DataArray = JSON.parse(JSON.stringify(editingConfigurations))
            for(var i= 0; i < DataArray.length; i++){
                if(DataArray[i].notSavedId === data.notSavedId){
                    DataArray.splice(i,1);
                    break;
                }
            }
            setEditingConfigurations(DataArray)
        }
        else{
            dispatch(deleteDashboardAlert(data._id, alarmModal.sensor, params.systemId))

            if(editingConfigurations.findIndex(item => item._id === data._id)){
                let DataArray = JSON.parse(JSON.stringify(editingConfigurations))

                DataArray.splice(DataArray.findIndex(item => item._id === data._id))
                setEditingConfigurations(DataArray)
            }
        }
    }

    const handleAlarmModal=(item)=>{

        dispatch(getDashboardAlertData(item.slug_id, params.systemId));
        setAlarmModal({
            name:item.name,
            sensor: item.slug_id,
            isOpen:true
        })
    }

    const getTorsionData=(value)=>{
        dispatch(getTorsionChartData(params.systemId, value))
    }

    const getMainDeviceData=(value)=>{
        dispatch(getMainDeviceChartData(params.systemId, value))
    }

    return (
        <div>
            <div className='row mt-4'>
                <Col xl={4} lg={5} md={6}>
                    <UserDetails
                        userData={props.userData}
                        project={TARGET_CODE}
                    />
                    <AlertComp
                        systemAlerts={props.notificationData}
                    />
                    <SystemHealth data={props.systemHealth}/>
                </Col>
                <Col xl={8} lg={7} md={6}>
                    <BoatViews shipData={props.shipData} />
                    <CompareGraph
                        getData={getMainDeviceData}
                        compareGraphData={{
                            current: (props.mainDeviceData && props.mainDeviceData.current) ?
                                props.mainDeviceData.current.map((el) => {
                                    return [
                                        (moment(el.time).valueOf()), el.value
                                    ]
                                }) : [],
                            previous: (props.mainDeviceData && props.mainDeviceData.prevYear) ?
                                props.mainDeviceData.prevYear.map((el) => {
                                    return [
                                        (moment(el.time).valueOf()), el.value
                                    ]
                                }) : []
                        }}
                        graphInfo={{
                            avg: props.mainDeviceData?.avg,
                            min: props.mainDeviceData?.min,
                            max: props.mainDeviceData?.max,
                        }}
                        setGraphState={setCompareGraphState}
                        graphState={compareGraphState}
                    />
                </Col>
                <Col md={4}>
                    <SensorInformation project={TARGET_CODE} handleAlarmModal={handleAlarmModal} systemSensors={props.onboardDevicesData}/>
                </Col>
                <Col md={4}>
                    <OverviewGraph
                        getData={getTorsionData}
                        overviewGraphData={(props.torsionData && props.torsionData.data) ?
                            props.torsionData.data.map((el) => {
                            return [
                                (moment(el.time).valueOf()), el.value
                            ]
                        }) : []}
                        setGraphState={setOverviewGraphState}
                        graphState={overviewGraphState}
                    />
                </Col>
                <Col md={4}>
                    <TemparatureDetails  temparatureData={props.tempData}/>
                </Col>

                <Col>
                    {TARGET_CODE === "VLM_LOCAL" && <DataConsumption/> }
                </Col>
            </div>

            <AlarmSensorModal
                alertsConfigurations={props.dashboardAlertData}
                isLoading = {props.isLoading}
                editingConfigurations={editingConfigurations}
                setEditingConfigurations={setEditingConfigurations}
                alarmModal={alarmModal}
                setAlarmModal={setAlarmModal}
                handleConfigurationSave={handleConfigurationSave}
                handleConfigurationDelete={handleConfigurationDelete}
            />
        </div>
    )
}

export default DashboardVLM;