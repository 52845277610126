import {authActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const userLogin=(Data, isRemember)=> async dispatch=>{
    dispatch({type: authActionTypes.USER_LOGIN_REQUEST});

    let data = new URLSearchParams();

    data.append('email', Data.username);
    data.append('password', Data.password);
    data.append('rememberme', isRemember ? 1 : 0);

    try{
        const res = await request({
            url:`core/auth/login`,
            auth: false,
            method:'POST',
            data: data
        });

        if(res?.access?.token){
            await localStorage.setItem('token', res.access.token)
            dispatch({
                type:authActionTypes.USER_LOGIN_SUCCEED,
                payload: res.user
            });
        }else{
            localStorage.removeItem('token')
            dispatch({
                type:authActionTypes.USER_LOGIN_FAILED,
                payload: res ? res : null
            });
        }

        return res;

    } catch (err){
        dispatch({
            type:authActionTypes.USER_LOGIN_FAILED,
            payload: err ? err.data : null
        });

        return false
    }
}


export const userLogout=()=>async dispatch=>{
    dispatch({type:authActionTypes.USER_LOGOUT_REQUEST});

    await localStorage.removeItem('token')
    // await dispatch({type:authActionTypes.USER_LOGOUT_SUCCEED});

    setTimeout(()=>{
        dispatch({type:authActionTypes.USER_LOGOUT_SUCCEED});
        return true;
    },2000)
}

export const getMyDetails=()=> async dispatch=>{
    dispatch({type:authActionTypes.GET_USER_DETAILS_REQUEST})

    try {
        const res = await request({
            url: `core/ui/users/whoami`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: authActionTypes.GET_USER_DETAILS_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: authActionTypes.GET_USER_DETAILS_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const updateMyDetails = (Data) => async dispatch => {
    dispatch({type: authActionTypes.UPDATE_USER_DETAILS_REQUEST});

    let data = new FormData();

    if(Data.firstname)
        data.append('firstname', Data.firstname);
    if(Data.lastname)
        data.append('lastname', Data.lastname);
    if(Data.email)
        data.append('email', Data.email);
    if(Data.password)
        data.append('password', Data.password);
    if(Data.confirmpassword)
        data.append('confirmpassword', Data.confirmpassword);


    try {
        const res = await request({
            url: `core/ui/users/${Data._id}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: authActionTypes.UPDATE_USER_DETAILS_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: authActionTypes.UPDATE_USER_DETAILS_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}
