import React from "react";
import {Button, Row} from "react-bootstrap";
import {Input} from "reactstrap";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Editor} from "../common/Editor";
import capitalizeFirstLetter from "../../helpers/capitalizeHelper";
import jsYaml from "js-yaml";

const RuleForm = (props) => {

    const {t} = useTranslation();
    const params = useParams();

    const onChangeHandler = (e) => {
        const {name, value} = e.target;

        if (name === "triggerParameters" ||  name === "ruleParameters" || value.length <= 50 || (name === 'configDescription' && value.length <= 500)) {
            props.setFormState({
                ...props.formState,
                [name]: value
            })
        }
    }

    const setSample=(id)=>{
        if(id === 0){
            props.setFormState({
                ...props.formState,
                triggerParameters: jsYaml.dump(props.formState?.rule?.trigger?.triggerparams_sample)
            })
        } else {
            props.setFormState({
                ...props.formState,
                ruleParameters: jsYaml.dump(props.formState?.rule?.ruleparams_sample)
            })
        }
    }

    return (
        <Row>
            <div className='view-entity-container'>
                <Row><div className='fw-bold'>{t('rules.form.card.topic')}</div></Row>
                <Row><div className='fw-light text-muted mt-1 mb-2'>{t('rules.form.card.subTopic')}.</div></Row>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='ruleName'>{t('rules.form.field1.name')}</label></div>
                    <div className='form-control disabled'
                         style={{minHeight: '37px'}}>{props.formState.rule?.name}</div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='ruleDescription'>{t('rules.form.field2.name')}</label>
                    </div>
                    <div className='form-control' style={{minHeight: '64px'}}>{props.formState.rule?.description}</div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='ruleTriggerType'>{t('rules.form.field3.name')}</label>
                    </div>
                    <div className='form-control' style={{minHeight: '37px'}}>{props.formState.rule?.trigger?.type}</div>
                </div>

            </div>

            <form onSubmit={props.onSaveClick}>
                <Row>

                    <div className="mt-4 col-12">
                        <div className='fw-bold'>{t("rules.form.card.config.topic")}</div>
                        <div className='fw-light text-muted mt-1 mb-2'>{t("rules.form.card.config.subTopic")}</div>
                    </div>

                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="configName">{t('rules.form.field4.name')} {!props.isDisabled && '*'}</label></div>
                        <div className='form-limitation-container'>
                            <Input
                                type="text"
                                name="configName"
                                id="configName"
                                className="form-control"
                                maxLength='500'
                                value={props.formState.configName}
                                onChange={onChangeHandler}
                                placeholder={t('rules.form.field4.placeholder')}
                                disabled={props.isDisabled}
                                required
                            />
                            <div>{props.formState.configName.length}/50</div>
                        </div>
                    </div>
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0" htmlFor="configDescription">{t('rules.form.field5.name')}</label></div>
                        <div className='form-limitation-container'>
                            <Input
                                type="textarea"
                                name="configDescription"
                                id="configDescription"
                                className="form-control"
                                rows='3'
                                value={props.formState.configDescription}
                                onChange={onChangeHandler}
                                placeholder={props.isDisabled ? "" : t('rules.form.field5.placeholder')}
                                disabled={props.isDisabled}
                            />
                            <div>{props.formState.configDescription.length}/500</div>
                        </div>
                    </div>

                    <div className="mt-4 col-12">
                        <div><label className="mb-2 p-0" htmlFor="triggerParameters">{t('rules.form.field6.name')} {!props.isDisabled && '*'}</label></div>
                        <div>
                            <Editor
                                height='150px'
                                isDisabled={props.isDisabled}
                                name='triggerParameters'
                                value={props.formState.triggerParameters}
                                onChangeHandler={onChangeHandler}
                                setSample={()=> setSample(0)}
                            />
                        </div>
                    </div>

                    <div className="mt-4 col-12">
                        <div><label className="mb-2 p-0" htmlFor="ruleParameters">{t('rules.form.field7.name')} {!props.isDisabled && '*'}</label></div>
                        <div>
                            <Editor
                                height='150px'
                                isDisabled={props.isDisabled}
                                name='ruleParameters'
                                value={props.formState.ruleParameters}
                                onChangeHandler={onChangeHandler}
                                setSample={()=> setSample(1)}
                            />
                        </div>
                    </div>

                    {props.isDisabled &&
                        <div className="mt-3 col-12">
                            <div className='d-flex'>
                                <div className='me-1'>{t("drivers.view.status")}:</div>
                                <div className='fw-medium'>{props.formState.status && capitalizeFirstLetter(props.formState.status?.state)}</div>
                            </div>
                            {(props.formState.status && props.formState.status.errormessages && props.formState.status.errormessages.length) &&
                                <div>
                                    <div>{t("drivers.view.messages")}:</div>
                                    <ul className='mt-1'>
                                        {props.formState.status.errormessages.map((el)=>{
                                            return(
                                                <li className='fw-light'>{el}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            }
                        </div>
                    }

                </Row>

                <div className='col-12 text-end mt-3 form-btn-grp'>
                    {!props.isDisabled && <Button disabled={!props.formState.triggerParameters || !props.formState.ruleParameters || !props.formState.configName} color="primary" type="submit" className="me-2">{t("main.save")}</Button> }
                    <Link to={`/system/${params.systemId}/rules`}>
                        <Button type="button" className="btn-secondary">{props.isDisabled ? t("main.back") : t("main.cancel")}</Button>
                    </Link>
                </div>

            </form>
        </Row>
    )
}

export default RuleForm;