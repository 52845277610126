import React, {useEffect, useRef, useState} from "react"
import { Card, CardBody, CardTitle, Badge } from "reactstrap"
import moment from 'moment'
import {useTranslation} from "react-i18next";

const SensorInformation = (props) => {

  const {t} = useTranslation();
  const tableRef = useRef(null);

  const [width, setWidth] = useState('')

  useEffect(()=>{
    if(tableRef.current)
      setWidth(tableRef.current.clientWidth)
  }, [tableRef.current])

  return (
    <React.Fragment>
      <Card className='sensor-card'>
        <CardBody>
          <CardTitle className="mb-2 text-start">{t("dashboard.sensors.topic")}</CardTitle>
          <div className="table-responsive table-overflow-custom">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-hover">
                <tr>                  
                  <th className="align-middle first-header" style={{minWidth: width/3.2}}>{t("dashboard.sensors.header1")}</th>
                  <th className="align-middle" >{t("dashboard.sensors.header2")}</th>
                  <th className="align-middle third-header" >{t("dashboard.sensors.header3")}</th>
                </tr>
              </thead>
              <tbody>
                {props.systemSensors ? props.systemSensors.map((item, key) => (
                  <tr key={"_tr_" + key}>                    
                    <td ref={tableRef}>{item.name}</td>
                    { item.state ? (
                      <td>{moment.utc(item.state.time, 'YYYY-MM-DDTHH:mm:ss').local().format('YYYY-MM-DD, HH:mm')}</td>
                    ) : (
                      <td>-</td>
                    )  }
                    <td style={{whiteSpace:'nowrap'}}>
                      <Badge
                        className={
                          "font-size-12 badge-soft-" + (item.alertStatus === 'warning' ? 'warning' : item.alertStatus === 'danger' ? 'danger' : 'success')
                        }
                        color={item.alertStatus === 'warning' ? 'warning' : item.alertStatus === 'danger' ? 'danger' : 'success'}
                        pill
                      >
                        {item.alertStatus === 'warning' ? 'Warning' : item.alertStatus === 'danger' ? 'Danger' : 'Ok'}
                      </Badge>
                      {props.project !== 'VLM_LOCAL' && <i style={{cursor: 'pointer'}} onClick={() => {props.handleAlarmModal(item)}} className='bx bx-edit bx-fw ms-1'/> }
                    </td>                    
                  </tr>
                )): <tr></tr>}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SensorInformation
