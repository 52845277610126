import React, {useEffect} from 'react';



import {config} from "../../config";
import platform from "platform";

const OverlaySpinner=(props)=>{

	let platform = require('platform');

	const TARGET_CODE = config.TARGET_CODE;

	useEffect(()=>{
		if(TARGET_CODE === 'VLM_LOCAL' && platform.name.includes('Chrome') && platform.os.toString().includes('Android')) {
			window.onLoadFullScreenButton();
		}
	},[])

	const styles = {
		full:{
			backgroundColor:'rgba(255,255,255,0.8)',
			width:'100%', 
			height:'100%', 
			position:'fixed', 
			zIndex:'1000',
			marginLeft: '-35px'
		},
		fullSpinner:{
			position:'fixed', 
			top:'45%', 
			left:'calc(50% - 40px)',
			marginLeft:'-17px',
			zIndex:'1000'
		},
	}
	if(TARGET_CODE !== 'VLM_LOCAL' || !platform.name.includes('Chrome') || !platform.os.toString().includes('Android')){
		return null
	}
	else {
		return (
			<div style={styles.full} id='fs-overlay-btn'>
				<div style={styles.fullSpinner}>
					<button className="btn btn-primary btn-block waves-effect waves-light" onClick={props.handle}>
						Enter fullscreen
					</button>
				</div>
			</div>
		)
	}

}

export default OverlaySpinner;