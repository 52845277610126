import React from 'react'
import Select, { components } from 'react-select'


const SearchSelect=(props)=>{

    const customStyles = {
        container: (provided, state) =>({
            ...provided,
            width: '100%',
            padding:0,
            height:'100%',
            border:'none',
            boxShadow:'none',
            minHeight:'37px',
            maxHeight:'37px',
        }),
        control: (provided, state) =>({
            ...provided,
            minHeight:'37px',
            maxHeight:'37px',
            boxShadow:'none',
            border: '1px solid #ced4da',
            '&:active' : {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },
            '&:focus': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },
            '&:hover': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },
        }),
        clearIndicator: (provided)=>({
            ...provided,
            paddingRight:'0'
        }),
        dropdownIndicator: (provided)=>({
            ...provided,
            maxWidth:'35px',
            paddingLeft:'0',
            div:{
                paddingLeft:'0',
            }
        })
    }

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <div className='search-select-dropdown'/>
            </components.DropdownIndicator>
        );
    };

    return(
        <Select
            className="form-control form-select"
            classNamePrefix="select"
            isSearchable={true}
            name={props.name}
            placeholder={props.placeholder}
            options={props.data}
            value={props.value ? props.value : null}
            onChange={props.onChangeHandler}
            styles={customStyles}
            isClearable={props.isClearable}
            components={{
                DropdownIndicator,
                IndicatorSeparator: () => null,
            }}
        />
    )
}

export default SearchSelect;