import {notificationActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getNotificationList = (page) => async dispatch => {
    dispatch({type: notificationActionTypes.GET_NOTIFICATION_LIST_REQUEST})

    try {
        const res = await request({
            url: `core/ui/notifications?page=${page}&limit=5`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: notificationActionTypes.GET_NOTIFICATION_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: notificationActionTypes.GET_NOTIFICATION_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getNewNotificationList = (system) => async dispatch => {
    dispatch({type: notificationActionTypes.GET_NEW_NOTIFICATION_LIST_REQUEST})

    try {
        const res = await request({
            url: `core/ui/notifications/new${system ? "?system="+system : ""}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: notificationActionTypes.GET_NEW_NOTIFICATION_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: notificationActionTypes.GET_NEW_NOTIFICATION_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getUnreadCount = () => async dispatch => {
    dispatch({type: notificationActionTypes.GET_UNREAD_NOTIFICATION_COUNT_REQUEST})

    try {
        const res = await request({
            url: `core/ui/notifications/unreadcount`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: notificationActionTypes.GET_UNREAD_NOTIFICATION_COUNT_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: notificationActionTypes.GET_UNREAD_NOTIFICATION_COUNT_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const markAsReadNotification = (read, Id) => async dispatch => {
    dispatch({type: notificationActionTypes.NOTIFICATION_MARK_AS_READ_UNREAD_REQUEST});

    let data = new FormData();

    data.append('read', read);


    try {
        const res = await request({
            url: `core/ui/notifications/${Id}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: notificationActionTypes.NOTIFICATION_MARK_AS_READ_UNREAD_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: notificationActionTypes.NOTIFICATION_MARK_AS_READ_UNREAD_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const markAllAsRead = () => async dispatch => {
    dispatch({type: notificationActionTypes.NOTIFICATION_MARK_ALL_AS_READ_REQUEST});

    try {
        const res = await request({
            url: `core/ui/notifications/markallasread`,
            auth: true,
            method: 'PATCH',
        });

        dispatch({type: notificationActionTypes.NOTIFICATION_MARK_ALL_AS_READ_SUCCEED});

        return true;

    } catch (err) {
        dispatch({
            type: notificationActionTypes.NOTIFICATION_MARK_ALL_AS_READ_FAILEDAS_READ_UNREAD_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const deleteNotification = (id) => async dispatch => {
    dispatch({type: notificationActionTypes.DELETE_NOTIFICATION_REQUEST});

    try {
        const res = await request({
            url: `core/ui/notifications/${id}`,
            auth: true,
            method: 'DELETE',
        });

        dispatch({
            type: notificationActionTypes.DELETE_NOTIFICATION_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: notificationActionTypes.DELETE_NOTIFICATION_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const deleteAllNotification = () => async dispatch => {
    dispatch({type: notificationActionTypes.DELETE_ALL_NOTIFICATION_REQUEST});

    try {
        const res = await request({
            url: `core/ui/notifications/`,
            auth: true,
            method: 'DELETE',
        });

        dispatch({
            type: notificationActionTypes.DELETE_ALL_NOTIFICATION_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: notificationActionTypes.DELETE_ALL_NOTIFICATION_FAILED,
            payload: err ? err.data : null
        });
    }
}
