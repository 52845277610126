import React, {useEffect, useState} from "react";
import {CardBody, CardTitle} from "reactstrap";
import {Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import MapView from "../../common/MapView";


const GardenView=(props)=>{

    const {t} = useTranslation();

    const [system, setSystem] = useState([])
    const [areas, setAreas] = useState([])
    const [plants, setPlants] = useState([])
    const [devices, setDevices] = useState([])

    useEffect(()=>{
        if(JSON.stringify(system) !== JSON.stringify(props.system)){
            setSystem(props.system)
        }
        if(JSON.stringify(areas) !== JSON.stringify(props.areas)){
            setAreas(props.areas)
        }
        if(JSON.stringify(plants) !== JSON.stringify(props.plants)){
            setPlants(props.plants)
        }
        if(JSON.stringify(devices) !== JSON.stringify(props.devices)){
            setDevices(props.devices)
        }
    }, [props.system, props.areas, props.plants, props.devices ])

    return(
        <Card className='pb-1 garden-card'>
            <CardBody>
                <CardTitle className='mb-3'>{t("dashboard.gardenMap.topic")}</CardTitle>
                <MapView
                    system={system}
                    areas={areas}
                    plants={plants}
                    devices={devices}
                />
            </CardBody>
        </Card>
    )
}

export default GardenView;