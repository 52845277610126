import React, {useState, useEffect} from "react";
import {Card, CardBody, Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useParams, Link, useLocation} from "react-router-dom";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";

import SearchBar from "../../components/others/SearchBar";
import TableFirst from "../../components/tables/TableFirst";
import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";
import DeleteModal from "../../components/modals/DeleteModal";

import { ReactComponent as Tap } from '../../assets/icons/tap.svg'

import {deleteOrchardRegister, getOrchardRegisterList} from "../../redux/actions/orchardRegisterActions";

const OrchardRegisterList = () => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    const [deleteId, setDeleteId] = useState(null)
    const [orchardSearch, setOrchardSearch] = useState('')

    const dispatch = useDispatch();

    const available = useSelector((el) => el.systemData.availableList)
    const orchardRegisterData = useSelector((el)=> el.orchardRegisterData)

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            history.push(`/systems`)
        } else {
            dispatch(getOrchardRegisterList(params.systemId,1, null))
        }
    }, [location.pathname])

    const getDataList=(page)=>{
        dispatch(getOrchardRegisterList(params.systemId, page, orchardSearch ? orchardSearch : null))
    }

    const onDeleteRegister=()=>{
        let id = deleteId;
        setDeleteId(null)
        dispatch(deleteOrchardRegister(id, params.systemId))
    }

    const onRegisterSearch=()=>{
        dispatch(getOrchardRegisterList(params.systemId,1, orchardSearch))
    }



    const tableColumns = [
        {
            key: "details",
            text: "",
            dataField: "name",
            headerStyle: {backgroundColor: "white"},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex">
                        <div className="me-3 d-flex align-items-center">
                            <Tap className='pe-4 tap-icon'/>
                        </div>
                        <div className="row-details">
                            <div className='text-new-dark fw-bold font-size-13'>{row.title}</div>
                            <div>
                                <div className='fw-light text-semi-dark font-size-12'>{t("main.date")}: {moment(row.date).format('YYYY-MM-DD, HH:mm')}</div>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            key: "edit-delete-save",
            text: "",
            dataField: "",
            style: {textAlign: "right"},
            headerStyle: {backgroundColor: "white", boxShadow: "0"},
            formatter: (rowContent, row) => {
                return (
                    <div className="text">
                        <Link to={`/system/${params.systemId}/orchard-register/view/${row._id}`}><Button color="primary">{t("main.view")}</Button></Link>
                        <Link to={`/system/${params.systemId}/orchard-register/edit/${row._id}`}><Button color="warning" className="ms-2">{t("main.edit")}</Button></Link>
                        <Button onClick={()=> setDeleteId(row._id)} color='danger' className='ms-2'>{t("main.delete")}</Button>
                    </div>
                );
            },
        },
    ];

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t('orchardRegister.title')}
                    systemData={{id: params.systemId, name: available.find((el) => el._id === params.systemId)?.name}}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/orchard-registers`, name: t('orchardRegister.breadcrumb1')}
                    ]}
                />
                <Card>
                    <CardBody>
                        <div>
                            <div className='font-size-15 fw-medium'>{t("orchardRegister.pageTopic")}</div>
                            <div className='text-semi-dark mt-2 mb-4'>{t("orchardRegister.pageSubTopic")}</div>
                        </div>
                        <div>
                            <SearchBar
                                name="orchard-register"
                                btnName={t("orchardRegister.add")}
                                link={`system/${params.systemId}/orchard-register`}
                                searchValue={orchardSearch}
                                setSearchValue={setOrchardSearch}
                                onSearch={onRegisterSearch}
                            />
                        </div>
                        <TableFirst
                            dataList={orchardRegisterData.orchardRegisterList}
                            columns={tableColumns}
                            total={orchardRegisterData.totalSize}
                            getDataList={getDataList}
                            containerClass={'header-less-table-container'}
                            rowBorder={true}
                        />
                    </CardBody>
                </Card>
            </div>

            <DeleteModal
                onDelete={onDeleteRegister}
                modalShow={!!deleteId}
                setModalShow={setDeleteId}
            />
        </Layout>
    );
}

export default OrchardRegisterList;
