import {Row, Input, Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {useHistory, useParams} from "react-router-dom";
import React from "react";


function OrchardRegisterForm(props) {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();

    const onChangeHandler = (e) => {
        if (e.target) {
            const {name, value} = e.target;
            if(value.length <= 50 || (name === 'notes' && value.length <= 500)){
                props.setFormState({...props.formState, [name]: value});
            }
        } else {
            let arr = [];
            e.values.forEach((element) => {
                arr.push({
                    _id: element.value,
                    name: element.label,
                });
            });
            props.setFormState({...props.formState, [e.name]: arr});
        }
    };

    const onBackClick=()=>{
        if(props.goBack){
            history.goBack()
        } else {
            if(props.plantNo){
                history.push(`/system/${params.systemId}/plant/view/${props.plantNo}`)
            } else {
                history.push(`/system/${params.systemId}/orchard-registers`)
            }
        }
    }

    const customStyles = {

        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? '#eff2f7' : '',
            minHeight: '37px',
            maxHeight: '37px',
            boxShadow: 'none',
            border: '1px solid #ced4da',
            '&:active': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },
            '&:focus': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },
            '&:hover': {
                borderColor: "#b9bfc4 !important",
                outline: 0
            },

        }),

        multiValue: (provided) => ({
            ...provided,
            border: '#D5D5D5 solid 0.5px',
            borderRadius: '4px',
            backgroundColor: '#F8F9FA'
        }),

        multiValueLabel: (provided) => ({
            ...provided,
            color: '#74788D'
        }),

        multiValueRemove: (provided, state) => ({
            ...provided,
            color: '#74788D',
            width: state.isDisabled ? '0px' : '',
            '&:hover': {backgroundColor: '#F8F9FA', color: '#74788D'}

        }),


    }
    return (
        <form onSubmit={props.onSaveClick}>
            <Row>
                <div className="mt-3 col-12">
                    <div><label className="mb-2 p-0" htmlFor="title">{t("orchardRegister.form.field1.name")} {!props.isDisabled && '*'}</label></div>
                    <div className='form-limitation-container'>
                        <Input
                            type="text"
                            name="title"
                            id="title"
                            className="form-control input-disabled"
                            value={props.formState.title}
                            onChange={onChangeHandler}
                            required
                            placeholder={t("orchardRegister.form.field1.placeholder")}
                            disabled={props.isDisabled}
                        />
                        { !props.isDisabled && <div>{props.formState.title.length}/50</div> }
                    </div>
                </div>
                <div className="mt-3 col-12">
                    <div><label className="mb-2 p-0" htmlFor="areas">{t("orchardRegister.form.field2.name")}</label></div>
                    <div>
                        <Select
                            isMulti
                            name="areas"
                            options={props.systemareas.map((el)=> {
                                return {
                                    label: el.name,
                                    value: el._id
                                }
                            })}
                            className="basic-multi-select"
                            placeholder={t("orchardRegister.form.field2.placeholder")}
                            components={{IndicatorSeparator: () => null}}
                            isClearable={false}
                            onChange={(e) => {
                                let event = {
                                    name: "areas",
                                    values: e,
                                };
                                onChangeHandler(event);
                            }}
                            value={props.formState.areas.map((item) => ({
                                value: item._id,
                                label: item.name,
                            }))}
                            isDisabled={props.isDisabled}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className="mt-3 col-12">
                    <div><label className="mb-2 p-0" htmlFor="areas">{t("orchardRegister.form.field3.name")}</label></div>
                    <div>
                        <Select
                            isMulti
                            name="plants"
                            options={props.plants.map((el)=>{
                                return{
                                    label: el.name,
                                    value: el._id
                                }
                            })}
                            className="basic-multi-select"
                            placeholder={t("orchardRegister.form.field3.placeholder")}
                            components={{IndicatorSeparator: () => null}}
                            isClearable={false}
                            onChange={(e) => {
                                let event = {
                                    name: "plants",
                                    values: e,
                                };
                                onChangeHandler(event);
                            }}
                            value={props.formState.plants.map((item) => ({
                                value: item._id,
                                label: item.name,
                            }))}
                            isDisabled={props.isDisabled}
                            styles={customStyles}
                        />
                    </div>
                </div>
                <div className="mt-3 col-12">
                    <div><label className="mb-2 p-0" htmlFor="date">{t("orchardRegister.form.field4.name")} {!props.isDisabled && '*'}</label></div>
                    <div>
                        <Input
                            type="datetime-local"
                            name="date"
                            id="date"
                            className="form-control"
                            value={props.formState.date}
                            onChange={onChangeHandler}
                            required
                            disabled={props.isDisabled}
                            style={{maxHeight: '37px', lineHeight: 1.2}}
                        />
                    </div>
                </div>
                <div className="mt-3 col-12">
                    <div><label className="mb-2 p-0" htmlFor="title">{t("orchardRegister.form.field5.name")}</label></div>
                    <div className='form-limitation-container'>
                        <Input
                            type="textarea"
                            name="notes"
                            id="notes"
                            className="form-control"
                            value={props.formState.notes}
                            onChange={onChangeHandler}
                            placeholder={props.isDisabled ? "" : t("orchardRegister.form.field5.placeholder")}
                            disabled={props.isDisabled}
                            rows={3}
                        />
                        { !props.isDisabled && <div>{props.formState.notes.length}/500</div> }
                    </div>
                </div>
            </Row>
            <div className="col-12 text-end mt-3 form-btn-grp">
                {!props.isDisabled && <Button color="primary" type="submit" className="me-2">{t("main.save")}</Button>}
                <Button onClick={onBackClick} type="button" className="btn-secondary">{props.isDisabled ? t("main.back") : t("main.cancel")}</Button>
            </div>
        </form>
    );
}

export default OrchardRegisterForm;
