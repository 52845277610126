import React, {useEffect, useState} from "react";
import {Card, CardBody} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

import Layout from '../../layout/layout'
import Breadcrumb from "../../components/common/Breadcrumb";
import UserForm from "../../components/forms/UserForm";

import {addUser, getUserData, updateUser} from "../../redux/actions/userActions";
import {useDispatch, useSelector} from "react-redux";

const UserAddEdit = ({isEdit}) => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = useSelector((el) => el.userData.userData)
    const errorData = useSelector((el) => el.loadingData.error)

    const [formState, setFormState] = useState({
        email: '',
        firstname: '',
        lastname: '',
        password: '',
        confirmpassword: '',
        role: ''
    })

    useEffect(() => {
        if (isEdit) {
            dispatch(getUserData(params.userId))
        }
    }, [])

    useEffect(() => {
        if (userData && isEdit) {
            setFormState({
                ...formState,
                ...userData
            })
        }
    }, [userData])

    useEffect(() => {
        if (errorData && errorData.code === 404 && isEdit) {
            history.push('/users')
        }
    }, [errorData])

    const onSaveClick = (e) => {
        e.preventDefault();

        if(formState._id){
            dispatch(updateUser(formState)).then((res)=> {
                if (res) {
                    history.push('/users')
                }
            })
        } else {
            dispatch(addUser(formState)).then((res)=> {
                if (res) {
                    history.push('/users')
                }
            })
        }
    }

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={isEdit ? t('users.editUser') : t('users.addUser')}
                    breadcrumbItems={[
                        {path: "users", name: t('users.breadcrumb1')},
                        {
                            path: isEdit ? `user/edit/${params.userId}` : "users/add",
                            name: t(isEdit ? 'users.breadcrumbEdit' : 'users.breadcrumbAdd')
                        },
                    ]}
                />
                <Card>
                    <CardBody>
                        <UserForm
                            onSaveClick={onSaveClick}
                            formState={formState}
                            setFormState={setFormState}
                            isEdit={isEdit}
                        />
                    </CardBody>
                </Card>
            </div>
        </Layout>
    )
}

export default UserAddEdit;