import React from 'react';
import ReactApexChart from "react-apexcharts"
import {Row, CardBody, Card, CardTitle} from 'reactstrap';
import moment from 'moment/min/moment-with-locales'
import {useTranslation} from "react-i18next";
import { config } from '../../config';

const SystemHealth = (props) => {

    const {t} = useTranslation();

    const options = {
        options: {
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    dataLabels: {
                        name: {
                            fontSize: "13px",
                            color: void 0,
                            offsetY: 60,
                        },
                        value: {
                            offsetY: 22,
                            fontSize: "16px",
                            color: void 0,
                            formatter: function (e) {
                                return e + "%"
                            },
                        },
                    },
                },
            },
            colors: [props.data && props.data.color ? props.data.color : "#556ee6"],
            fill: {
                type: "gradient",
                gradient: {
                    shade: "dark",
                    shadeIntensity: 0.15,
                    inverseColors: !1,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 65, 91],
                },
            },
            stroke: {
                dashArray: 4,
            },
            labels: ["Health"],
        },
        series: [props.data ? props.data.health : 0],
    };

    return (
        <>
            <Card className='health-container health-card'>
                <CardBody>
                    <CardTitle style={{textAlign: 'left'}}>{t("dashboard.health.topic")}</CardTitle>
                    <ReactApexChart
                        options={options.options}
                        series={options.series}
                        type="radialBar"
                        height="200"
                        className="apex-charts"
                    />
                    <Row className='text-center mt-3'>
                        <div
                            className='font-size-18 fw-light'>{t("dashboard.health.lastUpdate")}: <br/> {props.data ? moment(props.data.updatedAt).locale(config.LANGUAGE).fromNow() : ''}</div>
                    </Row>

                    <div className='mt-5 mb-3 health-details-container'>
                        <Row className='ps-4 pe-4'>
                            <div className='col-6'>
                                <div className="me-4 rounded-pill pill-danger">{props.data?.criticalRange[0]}-{props.data?.criticalRange[1]}%</div>
                            </div>
                            <div className='col-6 text-start ps-4'>
                                {t("dashboard.health.condition1")}
                            </div>
                        </Row>

                        <Row className='ps-4 pe-4 mt-2'>
                            <div className='col-6'>
                                <div className="me-4 rounded-pill pill-info">{props.data?.averageRange[0]}-{props.data?.averageRange[1]}%</div>
                            </div>
                            <div className='col-6 text-start ps-4'>
                                {t("dashboard.health.condition2")}
                            </div>
                        </Row>

                        <Row className='ps-4 pe-4 mt-2'>
                            <div className='col-6'>
                                <div className="me-4 rounded-pill pill-success">{props.data?.optimalRange[0]}-{props.data?.optimalRange[1]}%</div>
                            </div>
                            <div className='col-6 text-start ps-4'>
                                {t("dashboard.health.condition3")}
                            </div>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default SystemHealth;