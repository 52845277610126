import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useParams} from "react-router-dom";

import Layout from '../../layout/layout'
import Breadcrumb from "../../components/common/Breadcrumb";

import {userData} from "../../utils/userData";
import {useDispatch, useSelector} from "react-redux";
import {getUserData} from "../../redux/actions/userActions";

import {roleData} from "../../utils/appData";

const UserView = (props) => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const userData = useSelector((el) => el.userData.userData)
    const errorData = useSelector((el) => el.loadingData.error)

    const [formState, setFormState] = useState({
        email: '',
        firstname: '',
        lastname: '',
        role: ''
    })

    useEffect(() => {
        dispatch(getUserData(params.userId))
    }, [])

    useEffect(() => {
        if (userData) {
            setFormState(userData)
        }
    }, [userData])

    useEffect(() => {
        if (errorData && errorData.code === 404 ) {
            history.push('/users')
        }
    }, [errorData])

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t(formState.firstname + ' ' + formState.lastname)}
                    breadcrumbItems={[
                        {path: "users", name: t('systems.breadcrumb1')},
                        {path: `user/view/${params.userId}`, name: t(formState.firstName + ' ' + formState.lastName)},
                    ]}
                />
                <Card>
                    <CardBody>
                        <div className='view-entity-container'>
                            <div>{t("users.form.field1.name")}</div>
                            <div>
                                <div className='form-control'>{formState.email}</div>
                            </div>

                            <div>{t("users.form.field2.name")}</div>
                            <div>
                                <div className='form-control'>{formState.firstname}</div>
                            </div>

                            <div>{t("users.form.field3.name")}</div>
                            <div>
                                <div className='form-control'>{formState.lastname}</div>
                            </div>

                            <div>{t("users.form.field6.name")}</div>
                            <div>
                                <div className='form-control'>{t(roleData.find((el)=> el.value === formState.role)?.label)}</div>
                            </div>
                        </div>

                        <div className='text-end mt-3'>
                            <Link to={`/users`}><Button type='button' className='btn-secondary pe-4 ps-4'>{t('main.back')}</Button></Link>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Layout>
    )
}

export default UserView;