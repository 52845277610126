import {systemAreaActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getAvailableSystemAreas = (id) => async dispatch => {
    dispatch({type: systemAreaActionTypes.GET_AVAILABLE_SYSTEM_AREAS_REQUEST})

    try {
        const res = await request({
            url: `core/ui/systemareas/available?system=${id}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: systemAreaActionTypes.GET_AVAILABLE_SYSTEM_AREAS_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: systemAreaActionTypes.GET_AVAILABLE_SYSTEM_AREAS_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getSystemAreaList = (id, page, search) => async dispatch => {
    dispatch({type: systemAreaActionTypes.GET_SYSTEM_AREA_LIST_REQUEST})

    try {
        const res = await request({
            url: `core/ui/systemareas?system=${id}&page=${page}&limit=5&${search ? 'searchText=' + search : ''}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: systemAreaActionTypes.GET_SYSTEM_AREA_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: systemAreaActionTypes.GET_SYSTEM_AREA_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getSystemAreaData = (id, systemId) => async dispatch => {
    dispatch({type: systemAreaActionTypes.GET_SYSTEM_AREA_DATA_REQUEST})

    try {
        const res = await request({
            url: `core/ui/systemareas/${id}?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: systemAreaActionTypes.GET_SYSTEM_AREA_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: systemAreaActionTypes.GET_SYSTEM_AREA_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const addSystemArea = (Data, id) => async dispatch => {
    dispatch({type: systemAreaActionTypes.CREATE_SYSTEM_AREA_REQUEST});

    let data = new FormData();

    data.append('name', Data.name);
    data.append('description', Data.description);
    data.append('metadata', JSON.stringify(Data.metadata));

    try {
        const res = await request({
            url: `core/ui/systemareas?system=${id}`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: systemAreaActionTypes.CREATE_SYSTEM_AREA_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: systemAreaActionTypes.CREATE_SYSTEM_AREA_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const updateSystemArea = (Data, systemId) => async dispatch => {
    dispatch({type: systemAreaActionTypes.UPDATE_SYSTEM_AREA_REQUEST});

    let data = new FormData();

    data.append('name', Data.name);
    data.append('description', Data.description);
    data.append('metadata', JSON.stringify(Data.metadata));

    try {
        const res = await request({
            url: `core/ui/systemareas/${Data._id}?system=${systemId}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: systemAreaActionTypes.UPDATE_SYSTEM_AREA_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: systemAreaActionTypes.UPDATE_SYSTEM_AREA_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const deleteSystemArea = (id, systemId) => async dispatch => {
    dispatch({type: systemAreaActionTypes.DELETE_SYSTEM_AREA_REQUEST});

    try {
        const res = await request({
            url: `core/ui/systemareas/${id}?system=${systemId}`,
            auth: true,
            method: 'DELETE',
        });

        dispatch({
            type: systemAreaActionTypes.DELETE_SYSTEM_AREA_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: systemAreaActionTypes.DELETE_SYSTEM_AREA_FAILED,
            payload: err ? err.data : null
        });
    }
}
