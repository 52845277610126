import React from 'react'
import { Container, Row, Col, Button, Input} from 'reactstrap'

import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";

const SearchBar=(props)=>{

    const { t } = useTranslation();

    const onSearch=(e)=>{
        e.preventDefault();

        if(props.onSearch){
            props.onSearch();
        }
    }

    return(
        <Container fluid={true}>
            <Row className='mt-4 mb-4 searchbar-container'>
                <Col className='d-flex searchbar-input'>
                    <form onSubmit={onSearch}>
                    <div><i className='bx bx-search-alt bx-sm text-semi-dark'></i></div>
                    <Input
                        type='text'
                        name={props.name.toLowerCase()}
                        id={props.name.toLowerCase()}
                        className='form-control'
                        placeholder={t('search') + '...'}
                        value={props.searchValue}
                        onChange={(e)=> props.setSearchValue(e.target.value)}
                    />
                    </form>
                </Col>
                <Col className='ms-auto text-end'>
                    {!props.addDisabled &&
                        <div style={{width: 'max-content', marginLeft: 'auto'}}>
                            {props.isAddModal ?
                                <Button onClick={() => props.setModal(true)} color='success' className='pe-4 ps-3'>
                                    <i className='bx bx-plus bx-xs text-white me-2'/>
                                    {t('main.add')} {t(props.btnName)}
                                </Button>
                                :
                                <Link to={`/${props.link}/add`}>
                                    <Button color='success' className='pe-4 ps-3'>
                                        <i className='bx bx-plus bx-xs text-white me-2'/>
                                        {t('main.add')} {t(props.btnName)}
                                    </Button>
                                </Link>
                            }
                        </div>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default SearchBar;