import React from 'react';
import {Row, Container} from 'reactstrap';
import {useTranslation} from "react-i18next";

const Footer = () => {

    const {t} = useTranslation();

    return (
        <Container fluid={true}>
            <Row className='footer-container'>
                <Row className='font-size-12 justify-content-between pt-3'>
                    <div className="text-start ml-auto ps-4"
                         style={{whiteSpace: 'nowrap', width: 'auto'}}>{t("footer.left")}
                    </div>
                    <div className="text-end" style={{whiteSpace: 'nowrap', width: 'auto'}}>
                        {t("footer.right")}
                    </div>
                </Row>
            </Row>
        </Container>
    )
}

export default Footer;