import {driverActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getAvailableDrivers = () => async dispatch => {
    dispatch({type: driverActionTypes.GET_AVAILABLE_DRIVERS_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/drivers/available`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: driverActionTypes.GET_AVAILABLE_DRIVERS_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: driverActionTypes.GET_AVAILABLE_DRIVERS_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getAvailableDriverConfigurations = (id) => async dispatch => {
    dispatch({type: driverActionTypes.GET_AVAILABLE_DRIVER_CONFIG_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/driverconfigurations/available?system=${id}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: driverActionTypes.GET_AVAILABLE_DRIVER_CONFIG_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: driverActionTypes.GET_AVAILABLE_DRIVER_CONFIG_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getDriverList = (id, page, search) => async dispatch => {
    dispatch({type: driverActionTypes.GET_DRIVER_CONFIG_LIST_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/driverconfigurations?system=${id}&page=${page}&limit=5&${search ? 'searchText=' + search : ''}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: driverActionTypes.GET_DRIVER_CONFIG_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: driverActionTypes.GET_DRIVER_CONFIG_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getDriverData = (id, systemId) => async dispatch => {
    dispatch({type: driverActionTypes.GET_DRIVER_CONFIG_DATA_REQUEST})

    try {
        const res = await request({
            url: `driversystem/ui/driverconfigurations/${id}?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: driverActionTypes.GET_DRIVER_CONFIG_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: driverActionTypes.GET_DRIVER_CONFIG_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const addDriver = (Data, id) => async dispatch => {
    dispatch({type: driverActionTypes.CREATE_DRIVER_CONFIG_REQUEST});

    let data = new FormData();

    data.append('name', Data.name);
    data.append('description', Data.description);
    data.append('driver', Data.driver);
    data.append('driverparams', JSON.stringify(Data.driverparams));

    try {
        const res = await request({
            url: `driversystem/ui/driverconfigurations?system=${id}`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: driverActionTypes.CREATE_DRIVER_CONFIG_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: driverActionTypes.CREATE_DRIVER_CONFIG_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const updateDriver = (Data, systemId) => async dispatch => {
    dispatch({type: driverActionTypes.UPDATE_DRIVER_CONFIG_REQUEST});

    let data = new FormData();

    data.append('name', Data.name);
    data.append('description', Data.description);
    data.append('driverparams', JSON.stringify(Data.driverparams));

    try {
        const res = await request({
            url: `driversystem/ui/driverconfigurations/${Data._id}?system=${systemId}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: driverActionTypes.UPDATE_DRIVER_CONFIG_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: driverActionTypes.UPDATE_DRIVER_CONFIG_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const deleteDriver = (id, systemId) => async dispatch => {
    dispatch({type: driverActionTypes.DELETE_DRIVER_CONFIG_REQUEST});

    try {
        const res = await request({
            url: `driversystem/ui/driverconfigurations/${id}?system=${systemId}`,
            auth: true,
            method: 'DELETE',
        });

        dispatch({
            type: driverActionTypes.DELETE_DRIVER_CONFIG_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: driverActionTypes.DELETE_DRIVER_CONFIG_FAILED,
            payload: err ? err.data : null
        });
    }
}
