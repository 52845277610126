import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useParams} from "react-router-dom";
import {Button, Card, CardBody, Row} from "reactstrap";

import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";
import Area from "../../components/formViews/Area";

import {systemData} from "../../utils/userData";
import {getSystemAreaData, getSystemAreaList} from "../../redux/actions/systemAreaActions";
import {useDispatch, useSelector} from "react-redux";


const AreaView = () => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const available = useSelector((el) => el.systemData.availableList)
    const systemAreaData = useSelector((el)=> el.systemAreaData.systemAreaData)
    const errorData = useSelector((el) => el.loadingData.error)

    const [formState, setFormState] = useState({
        name: '',
        paths: []
    })

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            history.push(`/systems`)
        } else {
            dispatch(getSystemAreaData(params.areaId, params.systemId))
        }
    }, [])

    useEffect(() => {
        if (systemAreaData) {
            setFormState({
                name: systemAreaData.name,
                description: systemAreaData.description,
                paths: systemAreaData.metadata?.geoposition?.coordinates[0].map((el)=>{
                    return{
                        lat: Number(el[0]),
                        lng: Number(el[1])
                    }
                })
            })
        }
    }, [systemAreaData])

    useEffect(() => {
        if (errorData && errorData.code === 404 ) {
            history.push(`/system/${params.systemId}/areas`)
        }
    }, [errorData])

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t(formState.name)}
                    breadcrumbItems={[
                        {path: `system/view/${params.systemId}`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/areas`, name: t('areas.breadcrumb1')},
                        {path: `system/${params.systemId}/area/view/${params.areaId}`, name: t(formState.name)},
                    ]}
                />

                <Card>
                    <CardBody>
                        <Area formState={formState} />
                    </CardBody>
                </Card>

                <div className='col-12 pb-5 text-end mt-3 form-btn-grp'>
                    <Link to={`/system/${params.systemId}/areas`}><Button type='button' className='btn-secondary'>{t('main.back')}</Button></Link>
                </div>

            </div>
        </Layout>
    )
}

export default AreaView;