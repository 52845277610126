import React from "react";
import {useTranslation} from "react-i18next";
import {Row} from "reactstrap";
import Map from "../common/Map";


const Area = (props) => {

    const {t} = useTranslation();

    return (

        <div className='pb-3'>
            <div className='view-entity-container'>
                <div>
                    <div>{t("areas.form.field1.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.name}</div>
                    </div>
                </div>

                <div className='row mt-0 mb-0'>
                    <div className='col-md-6'>
                        <div>{t("areas.form.field2.name")}</div>
                        <div className='mt-2'>
                            <Map
                                strokeColor={'#ECB753'}
                                isView={true}
                                polygonPaths={props.formState.paths}
                                center={props.formState.mapCenter}
                            />
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div>{t("areas.form.field3.name")}</div>
                        <div className='mt-2'>
                            {props.formState.paths.map((el, index)=>{
                                return(
                                    <div className='row m-0' key={"area-form-view-" +index}>
                                        <div className='col-4 m-0 ps-0'><div className='form-control'>{el.lat}</div></div>
                                        <div className='col-4 m-0 pe-0'><div className='form-control'>{el.lng}</div></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default Area;