import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

import enCommon from "./locales/en/translation.json"
import enVLM from "./locales/en/vlm.json"
import enPLT from "./locales/en/plt.json"

import itCommon from "./locales/it/translation.json"
import itVLM from "./locales/it/vlm.json"
import itPLT from "./locales/it/plt.json"
import {config} from "./config";

const ns = config.TEXT_NAMESPACE;
const lng = config.LANGUAGE;

i18n
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        resources:{
          en:{
              translation: enCommon,
              plt: enPLT,
              vlm: enVLM,
          },
          it:{
              translation: itCommon,
              plt: itPLT,
              vlm: itVLM,
          }
        },
        fallbackNS: 'translation',
        defaultNS: ns,
        ns: [
            "translation", ns
        ],
        lng: lng,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        },
        debug: false,
        react: {
            useSuspense: true,
            // wait: true,

        },
    });

export default i18n;
