import React from "react";
import {useTranslation} from "react-i18next";
import {Row} from "reactstrap";
import Map from "../common/Map";


const SystemVLMView = (props) => {

    const {t} = useTranslation();

    return (

        <div className='pb-3'>
            <div className='view-entity-container'>
                <Row>
                    <div className='fw-bold mb-3'>{t('systems.form2.card.topic')} *</div>
                </Row>

                <div className='row mt-0 mb-0'>
                    <div className='col-md-6'>
                        <div>{t("systems.form2.field1.name")}</div>
                        <div>
                            <div className='form-control'>{props.formState.manufacturer}</div>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div>{t("systems.form2.field2.name")}</div>
                        <div>
                            <div className='form-control'>{props.formState.code}</div>
                        </div>
                    </div>
                </div>

                <div className='row mt-0 mb-0'>
                    <div className='col-md-6'>
                        <div>{t("systems.form2.field3.name")}</div>
                        <div>
                            <div className='form-control'>{props.formState.category}</div>
                        </div>
                    </div>
                </div>

                <div className='row mt-0 mb-0'>
                    <div className='col-md-6'>
                        <div>{t("systems.form2.field4.name")}</div>
                        <div className='file-drop d-flex justify-content-center align-items-center' style={{height:'165px'}}>
                            <img src={props.formState.topView} style={{maxWidth:'100%', maxHeight:'100%'}} />
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div>{t("systems.form2.field5.name")}</div>
                        <div className='file-drop d-flex justify-content-center align-items-center' style={{height:'165px'}}>
                            <img src={props.formState.sideView} style={{maxWidth:'100%', maxHeight:'100%'}} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default SystemVLMView;