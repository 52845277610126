import { deviceActionTypes } from "../actionTypes/index";
import produce from "immer";

const initialState = {
    deviceList: [],
    availableDeviceTypes: [],
    deviceData: null,
    deviceHistory: []
};

const deviceData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {
            case deviceActionTypes.GET_DEVICE_LIST_SUCCEED: {
                state.deviceList = payload.results;
                return state;
            }
            case deviceActionTypes.GET_AVAILABLE_DEVICE_TYPES_SUCCEED: {
                state.availableDeviceTypes = payload;
                return state;
            }
            case deviceActionTypes.GET_DEVICE_DATA_SUCCEED: {
                state.deviceData = payload;
                return state;
            }
            case deviceActionTypes.GET_DEVICE_HISTORY_SUCCEED: {
                state.deviceHistory = payload;
                return state;
            }
            case deviceActionTypes.UPDATE_DEVICE_SUCCEED: {
                const updatedId = state.deviceList.findIndex((device) => device.id === payload.id);
                state.deviceList[updatedId].metadata = payload;
                return state;
            }
            default: {
                return state;
            }
        }
    }
);

export default deviceData;