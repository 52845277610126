import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, Container} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import Layout from '../../layout/layout'
import SearchBar from "../../components/others/SearchBar";
import TableFirst from "../../components/tables/TableFirst";
import Breadcrumb from "../../components/common/Breadcrumb";
import DeleteModal from "../../components/modals/DeleteModal";

import { config } from '../../config';
import {useDispatch, useSelector} from "react-redux";
import {getSystemList, deleteSystem} from "../../redux/actions/systemActions";


const SystemsList = () => {

    const TARGET_CODE = config.TARGET_CODE;
    const dispatch = useDispatch();
    const systemData = useSelector((el)=> el.systemData)
    const role = useSelector((state) => state.authData?.userDetails?.role)

    const {t} = useTranslation();

    const [deleteId, setDeleteId] = useState(null)
    const [systemSearch, setSystemSearch] = useState()

    useEffect(()=>{
        dispatch(getSystemList(1, null))
    },[])

    const getDataList=(page)=>{
        dispatch(getSystemList(page, systemSearch ? systemSearch : null))
    }

    const onDeleteSystem=()=>{
        let id = deleteId;
        setDeleteId(null)
        dispatch(deleteSystem(id))
    }

    const onSystemSearch=()=>{
        dispatch(getSystemList(1, systemSearch))
    }

    const tableColumns = [
        {
            key: "doc-row",
            text: t("systems.table.header1"),
            dataField: 'name',
            headerStyle: {textAlign: 'left', minWidth:'110px'},
        }, {
            key: 'customer',
            dataField: 'customer',
            text: t('systems.table.header2'),
            headerStyle: {textAlign: 'left', minWidth:'110px'},
            formatter: (rowContent, row) => {return row.customer?.fullname}
        }, {
            key: 'supervisor',
            dataField: 'supervisor',
            text: t('systems.table.header3'),
            headerStyle: {textAlign: 'left', minWidth:'110px'},
            formatter: (rowContent, row) => {return row.supervisor?.fullname}
        }, {
            key: 'sections',
            text: t('systems.table.header4'),
            headerStyle: {textAlign: 'left', minWidth: 'max-content', paddingLeft: TARGET_CODE === 'PLT' ? '40px' : '150px'},
            style: {display: 'flex', minWidth: 'max-content', justifyContent: 'start', paddingLeft: TARGET_CODE === 'PLT' ? '40px' : '150px'},
            formatter: (rowContent, row) => {
                return (
                    <div>
                        <Link to={`/system/${row._id}/dashboard`}><Button color='secondary' className='me-2'>{t('systems.table.button1')}</Button></Link>
                        {TARGET_CODE === 'PLT' && <Link to={`/system/${row._id}/areas`}><Button color='secondary' className='me-2'>{t("systems.table.button2")}</Button></Link>}
                        <Link to={`/system/${row._id}/devices`}><Button color='secondary' className='me-2'>{t("systems.table.button3")}</Button></Link>
                        {TARGET_CODE === 'PLT' && <Link to={`/system/${row._id}/rule-entities`}><Button color='secondary' className='me-2'>{t("systems.table.button4")}</Button></Link>}
                        {role === 'admin' && <Link to={`/system/${row._id}/drivers`}><Button color='secondary' className='me-2'>{t("systems.table.button5")}</Button></Link> }
                        {role === 'admin' && <Link to={`/system/${row._id}/rules`}><Button color='secondary' className='me-2'>{t("systems.table.button6")}</Button></Link> }
                        {TARGET_CODE === 'PLT' && <Link to={`/system/${row._id}/plants`}><Button color='secondary' className='me-2'>{t("systems.table.button8")}</Button></Link>}
                        {TARGET_CODE === 'PLT' && <Link to={`/system/${row._id}/orchard-registers`}><Button color='secondary' className='me-2'>{t("systems.table.button7")}</Button></Link>}
                    </div>
                )
            }
        }, {
            key: 'actions',
            text: t('systems.table.header5'),
            headerStyle: {textAlign: role !== 'admin' ? 'center' : 'left', width: '200px'},
            style: {textAlign: role !== 'admin' ? 'center' : 'right'},
            formatter: (rowContent, row) => {
                return (
                    <div>
                        <Link to={`/system/view/${row._id}`}><Button color='primary' className={role === 'admin' ? 'me-2' : ''}>{t("main.view")}</Button></Link>
                        {role === 'admin' && <Link to={`/system/edit/${row._id}`}><Button color='warning' className='me-2'>{t("main.edit")}</Button></Link> }
                        {role === 'admin' && <Button onClick={()=> setDeleteId(row._id)} color='danger'>{t("main.delete")}</Button> }
                    </div>
                )
            }
        }
    ]

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t('systems.title')}
                    breadcrumbItems={[
                        {path: `systems`, name: t('systems.breadcrumb1')}
                    ]}
                />
                <Card>
                    <CardBody>
                        <SearchBar
                            name="system"
                            btnName={t("systems.add")}
                            link='system'
                            searchValue={systemSearch}
                            setSearchValue={setSystemSearch}
                            addDisabled={role.includes('supervisor')}
                            onSearch={onSystemSearch}
                        />
                        <TableFirst
                            dataList={systemData.systemList}
                            columns={tableColumns}
                            total={systemData.totalSize}
                            getDataList={getDataList}
                            containerClass={TARGET_CODE === 'PLT' ? 'system-table-container-plt' : 'system-table-container'}
                        />
                    </CardBody>
                </Card>
            </div>

            <DeleteModal
                onDelete={onDeleteSystem}
                modalShow={!!deleteId}
                setModalShow={setDeleteId}
            />

        </Layout>
    )
}

export default SystemsList;