import {driverActionTypes} from "../actionTypes/index";
import produce from "immer";

const initialState = {
    availableDrivers:[],
    availableDriverConfigs:[],
    driverList: [],
    driverData: null,
    totalSize: 0
};

const driverData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case driverActionTypes.GET_AVAILABLE_DRIVERS_SUCCEED: {
                state.availableDrivers = payload;
                return state;
            }
            case driverActionTypes.GET_AVAILABLE_DRIVER_CONFIG_SUCCEED: {
                state.availableDriverConfigs = payload;
                return state;
            }
            case driverActionTypes.GET_DRIVER_CONFIG_LIST_SUCCEED: {
                state.driverList = payload.results;
                state.totalSize = payload.totalResults;
                return state;
            }
            case driverActionTypes.GET_DRIVER_CONFIG_DATA_SUCCEED: {
                state.driverData = payload;
                return state;
            }
            case driverActionTypes.CREATE_DRIVER_CONFIG_SUCCEED: {
                state.driverList.push(payload);
                state.totalSize++;
                return state;
            }
            case driverActionTypes.UPDATE_DRIVER_CONFIG_SUCCEED: {
                const updatedId = state.driverList.findIndex((item)=>item._id===payload._id);
                state.driverList[updatedId]=payload;
                return state;
            }
            case driverActionTypes.DELETE_DRIVER_CONFIG_SUCCEED: {
                const deletedId = state.driverList.findIndex((item)=>item._id===payload._id);
                state.driverList.splice(deletedId,1);
                state.totalSize--;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default driverData;
