import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button, Input, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const AltTimeRangeSelect = (props) => {
  const { t } = useTranslation();

  const onChangeHandler = (e) => {
    const { name, value, type } = e.target;

    if (type === 'date') {
      props.setDates({
        ...props.dates,
        [name]: value,
      });
    } else {
      props.setCheckedValue(value);
    }
  };

  return (
    <div
      style={{ width: '100%' }}
      className="d-flex flex-column flex-md-row ps-4 pe-3 justify-content-between align-items-end"
    >
      <div>
        <Form>
          <div className="row radio-container">
            <Form.Check
              type="radio"
              value="today"
              name="time-range"
              label={t('timerange.form.today')}
              onChange={onChangeHandler}
              checked={props.checkedValue === 'today'}
            />
            <Form.Check
              type="radio"
              value="lastsixmonth"
              name="time-range"
              label={t('timerange.form.lastsixmonth')}
              onChange={onChangeHandler}
              checked={props.checkedValue === 'lastsixmonth'}
            />
          </div>
          <div className="row radio-container mt-1">
            <Form.Check
              type="radio"
              value="lastweek"
              name="time-range"
              label={t('timerange.form.lastweek')}
              onChange={onChangeHandler}
              checked={props.checkedValue === 'lastweek'}
            />
            <Form.Check
              type="radio"
              value="lastyear"
              name="time-range"
              label={t('timerange.form.lastyear')}
              onChange={onChangeHandler}
              checked={props.checkedValue === 'lastyear'}
            />
          </div>
          <div className="d-flex row radio-container mt-1">
            <Form.Check
              type="radio"
              value="lastmonth"
              name="time-range"
              label={t('timerange.form.lastmonth')}
              onChange={onChangeHandler}
              checked={props.checkedValue === 'lastmonth'}
            />
            <Form.Check
              type="radio"
              value="custom"
              name="time-range"
              label={t('timerange.form.customrange')}
              onChange={onChangeHandler}
              checked={props.checkedValue === 'custom'}
            />
            <div className="d-flex align-items-center p-0 mt-2 mt-md-0">
              <Input
                type="date"
                name="fromDate"
                id="fromDate"
                disabled={props.checkedValue !== 'custom'}
                onChange={onChangeHandler}
                value={props.dates.fromDate}
              />
              <div
                style={
                  props.checkedValue !== 'custom'
                    ? { background: '#eff2f7' }
                    : {}
                }
              >
                :
              </div>
              <Input
                type="date"
                name="toDate"
                id="toDate"
                disabled={props.checkedValue !== 'custom'}
                onChange={onChangeHandler}
                value={props.dates.toDate}
                min={props.dates.fromDate}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AltTimeRangeSelect;
