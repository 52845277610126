import React, {useState} from "react";
import {Button, Row} from "react-bootstrap";
import {Input} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import axios from "axios";

import Map_with_Polygon from "../common/Map_with_Polygon";
import { config } from "../../config";

const key = config.GOOGLE_API_KEY;

const AreaForm=(props)=>{

    const { t } = useTranslation();
    const params = useParams();

    const [placesState, setPlacesState] = useState({
        latitude: '',
        longitude: ''
    })

    const onChangeHandler=(e, isPlace)=>{
        const { name, value } = e.target;
        if(isPlace){
            setPlacesState({
                ...placesState,
                [name]: value
            })
        } else {
            if(value.length <= 50) {
                props.setFormState({
                    ...props.formState,
                    [name]: value
                })
            }
        }
    }

    const addPath = () => {
        let oldPaths = JSON.parse(JSON.stringify(props.formState.paths));
        oldPaths.push({
            lat: Number(placesState.latitude),
            lng: Number(placesState.longitude),
        })

        props.setFormState({
            ...props.formState,
            paths: oldPaths
        })
        setPlacesState({
            latitude: '',
            longitude: ''
        })
    }

    const searchLocation = () => {
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${props.formState.geoLocation.replaceAll(' ', '+')}&key=${key}`)
            .then((res) => {
                let mapCenter = res?.data?.results[0]?.geometry?.location
                let location = res?.data?.results[0]?.formatted_address
                props.setFormState({
                    ...props.formState,
                    'geoLocation': location,
                    'mapCenter': mapCenter
                })
            })
            .catch((e) => {
                console.log(e)
            })

    }

    return(

        <form onSubmit={props.onSaveClick}>
            <Row>
                <div className='mt-3'>
                    <div>
                        <label className='mb-2 p-0' htmlFor='name'>{t('areas.form.field1.name')} *</label>
                    </div>
                    <div className='form-limitation-container'>
                    <Input
                        type='text'
                        name='name'
                        id='name'
                        className='form-control'
                        value={props.formState.name}
                        onChange={onChangeHandler}
                        placeholder={t('areas.form.field1.placeholder')}
                        required
                    />
                    <div>{props.formState.name.length}/50</div>
                    </div>
                </div>
            </Row>

            <Row className='mt-1'>
                <div className='mt-3 col-md-6'>
                    <form onSubmit={searchLocation} onKeyPress={e => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            searchLocation()
                        }
                    }}>
                        <div>
                            <label className='mb-1 p-0' htmlFor='geoLocation'>{t('areas.form.field2.name')} *</label>
                        </div>
                        <div className='fw-light text-muted mb-2 font-size-11'>{t('areas.form.field2.info')}.</div>
                        <div style={{position: 'relative'}}>
                            <Input
                                type='text'
                                name='geoLocation'
                                id='geoLocation'
                                className='form-control'
                                value={props.formState.geoLocation}
                                onChange={onChangeHandler}
                                required
                            />
                            <div><i onClick={searchLocation}
                                    style={{position: 'absolute', right: '7px', top: '7px', cursor: 'pointer'}}
                                    className='bx bx-search-alt bx-sm'/></div>
                        </div>
                    </form>
                </div>

                <div className='mt-3 col-md-6'>
                    <div><label className='mb-1 p-0' htmlFor='geoLocationPoints'>{t('areas.form.field3.name')} *</label></div>
                    <div className='fw-light font-size-11 text-muted mb-2'>{t('areas.form.field3.info')}.</div>
                    <div className='d-flex'>
                        <div className='me-2' style={{maxWidth: '150px'}}>
                            <Input
                                type='number'
                                name='latitude'
                                id='latitude'
                                className='form-control'
                                value={placesState.latitude}
                                onChange={(e)=> onChangeHandler(e, true)}
                                placeholder={t("systems.form2.field3.placeholder1")}
                            />
                        </div>
                        <div className='me-2' style={{maxWidth: '150px'}}>
                            <Input
                                type='number'
                                name='longitude'
                                id='longitude'
                                className='form-control'
                                value={placesState.longitude}
                                onChange={(e)=> onChangeHandler(e, true)}
                                placeholder={t("systems.form2.field3.placeholder1")}
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-center'><i onClick={addPath} style={{
                            border: '1px solid #DA1021',
                            borderRadius: '4px',
                            padding: '1px 1px',
                            color: '#DA1021',
                            cursor: 'pointer'
                        }} className='bx bx-check bx-sm'/></div>
                    </div>
                </div>
            </Row>

            <Map_with_Polygon
                systemPolygon={
                    (props.systemPolygon && props.systemPolygon.length) ?
                        props.systemPolygon.map((item)=>{
                            return{
                                lat: item[0],
                                lng: item[1]
                            }
                        })
                        : null
                }
                strokeColor={'#ECB753'}
                formState={props.formState}
                setFormState={props.setFormState}
            />

            <div className='col-12 text-end mt-3 form-btn-grp'>
                <Button color='primary' type='submit' className='me-2'>{t('main.save')}</Button>
                <Link to={`/system/${params.systemId}/areas`}><Button type='button' className='btn-secondary'>{t('main.cancel')}</Button></Link>
            </div>

        </form>
    )
}


export default AreaForm;