import React, {useState, useEffect, useRef} from "react";
import {Card, CardBody, Input, Button} from "reactstrap";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { saveAs } from 'file-saver';
import moment from "moment";

// DATA EXPORT MODAL IMPORT
import DataExportModal from '../../components/modals/DataExportModal';

import Layout from "../../layout/layout";
import GraphModal from "../../components/modals/GraphModal";
import Breadcrumb from "../../components/common/Breadcrumb";

import { ReactComponent as CircleIcon } from '../../assets/icons/circleloaderfive.svg'
import { ReactComponent as History } from '../../assets/icons/history.svg'

import {getRuleEntityChartData, getRuleEntityList} from "../../redux/actions/ruleEntityActions";
import request from "../../helpers/requestHelper";
import {ruleEntitiesListTimeOut} from "../../utils/timeoutData";
import getRuleEntityStateLabel from '../../helpers/ruleEntityStateHelper';
import showRuleEntityHistory from "../../helpers/ruleEntityHistoryHelper";
import getGraphType from "../../helpers/ruleEntityGraphTypeHelper";
import getGraphOptions from "../../helpers/ruleEntityGraphOptionsHelper";

function RuleEntitiesList() {
    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const role = useSelector((state) => state.authData?.userDetails?.role)
    const available = useSelector((el) => el.systemData.availableList)
    const ruleEntityData = useSelector((el)=> el.ruleEntityData)

    const [entitySearch, setEntitySearch] = useState('')

    const [isHistoryOpen, setIsHistoryOpen] = useState(false)
    const [isExportDataOpen, setIsExportDataOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({})
    const [filterData, setFilterData] = useState({
        entitySearch: ""
    })

    const openHistory = (item) => {
        dispatch(getRuleEntityChartData(item._id, params.systemId, 'lastyear', null, null))
        setSelectedData(item)
        setIsHistoryOpen(true)
    }

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            if(role === 'customer' || role === 'guest'){
                history.push(`/system/${available[0].id}/rule-entities`)
            } else{
                history.push(`/systems`)
            }
        } else {
            dispatch(getRuleEntityList(params.systemId, entitySearch))
        }
    }, [location.pathname])

    useInterval(() => {
            dispatch(getRuleEntityList(params.systemId, entitySearch))
    }, ruleEntitiesListTimeOut);

    function useInterval(callback, delay) {
        const savedCallback = useRef();

        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    const onEntitySearch=()=>{
        setFilterData({
            ...filterData,
            entitySearch: entitySearch
        })
        dispatch(getRuleEntityList(params.systemId, entitySearch))
    }

    const onKeyHandler = (e: any) => {
        if (e.key === "Enter") {
            dispatch(getRuleEntityList(params.systemId, entitySearch))
        }
    };

    const getGraphData=(value, from, to)=>{
        if(value === 'custom') {
            dispatch(getRuleEntityChartData(selectedData._id, params.systemId, null, from, to))
        } else {
            dispatch(getRuleEntityChartData(selectedData._id, params.systemId, value, null, null))
        }
    }

    const openAllHistory = () => {
        setIsExportDataOpen(true);
    };

    const onExportData= async (value, from, to)=>{
        let filter, start, end;
        if(value === 'custom') {
            start = from;
            end = to;
        } else {
            filter = value;
        }

        const res = await request({
            url: `rulesystem/ui/ruleentities/${selectedData._id}/export?system=${params.systemId}${filter ? '&dateFilter=' +filter : ''}${!filter ? '&startDate='+start : ''}${!filter ? '&endDate='+end : ''}`,
            auth: true,
            method: 'GET',
            responseType: 'arraybuffer'
        });
        const blob = new Blob([res], {
            type: 'text.csv',
        });
        let name = selectedData._id + '_' +(filter ? filter : start+ '_' +end) + '.csv'
        saveAs(blob, name)
    }



    //   EXPORT ALL DATA
    const onExportAllData = async (value, from, to) => {
        let filter, start, end;
        if (value === 'custom') {
            start = from;
            end = to;
        } else {
            filter = value;
        }

        const res = await request({
             url: `/rulesystem/ui/ruleentities/multiple/export?system=${params.systemId}${
            filter ? '&dateFilter=' + filter : ''
            }${!filter ? '&startDate=' + start : ''}${
                !filter ? '&endDate=' + end : ''
            }`,
            auth: true,
            method: 'GET',
            responseType: 'arraybuffer',
        });
        const blob = new Blob([res], {
            type: 'text.csv',
        });
        let name = params.systemId + '_' + (filter ? filter : start + '_' + end) + '.csv';
        saveAs(blob, name);
    };



    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t('ruleEntities.title')}
                    systemData={{id: params.systemId, name: available.find((el) => el._id === params.systemId)?.name}}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/rule-entities`, name: t('ruleEntities.breadcrumb1')}
                    ]}
                />

                <Card>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <Input
                                    type="text"
                                    id="text_search"
                                    placeholder={t("ruleEntities.filter.field.placeholder")}
                                    value={entitySearch}
                                    onChange={(e) => setEntitySearch(e.target.value)}
                                    onKeyDown={onKeyHandler}
                                />
                            </div>
                            <div className="mb-3">
                                <Button onClick={onEntitySearch} color="primary"> {t("main.applyFilters")}</Button>
                            </div>
                           { /* Export button */}
                            <div className="mb-3 ">
                                <Button onClick={openAllHistory} color="success">
                                {t('main.exportAllDataByHour')}
                                </Button>
                            </div>
                        </div>
                        <div>
                            {ruleEntityData.ruleEntityList.map((item, index) => (
                                <div key={"system-rule-entities-" + index} className="data-row-1 row">
                                    <div className='col-5'>
                                        <div className='pe-5'>
                                            <CircleIcon/>
                                        </div>
                                        <div>
                                            <div style={{fontWeight: 600}}> {item.name}</div>
                                            <div> {t('main.lastStateUpdate')}: {item.state?.time ? moment(item.state?.time).format('DD/MM/YYYY HH:mm') : "-" }</div>
                                        </div>
                                    </div>
                                    {/* <div className='col-2 text-center'>{item.state?.value}{item.uom}</div> */}
                                    <div className='col-2 text-center'>{getRuleEntityStateLabel(item.type, item.state?.value, item.state?.attributes, item.uom, t)}</div>
                                    <div className='col-5 text-end'>
                                    {/* For future: hide history for alpha values {showRuleEntityHistory(item.type) && (
                                        <History onClick={() => openHistory(item)}
                                            style={{cursor: 'pointer'}}/>
                                    )} */}
                                    <History onClick={() => openHistory(item)}
                                            style={{cursor: 'pointer'}}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CardBody>
                </Card>
            </div>
            <GraphModal
                isOpen={isHistoryOpen}
                setIsOpen={setIsHistoryOpen}
                title={t("ruleEntities.history.topic")}
                graphType={getGraphType(selectedData.type)}
                graphOptions={getGraphOptions(selectedData.type, selectedData.state?.attributes, t)}
                graphData={{
                    name: "Current",
                    data: ruleEntityData.ruleEntityChart.map((el)=>{
                        return[
                            moment(el.time).valueOf(),
                            el.value
                        ]
                    })
                }}
                getGraphData={getGraphData}
                onExport={onExportData}
                data={
                    <div className='data-row-1 mt-3'>
                        <div className='col-9'>
                            <div className='pe-5'>
                                <CircleIcon/>
                            </div>
                            <div>
                                <div style={{fontWeight: 600}}> {selectedData.name}</div>
                                <div> {t('main.lastStateUpdate')}: {selectedData.state?.time ? moment(selectedData.state?.time).format('DD/MM/YYYY HH:mm') : "-" }</div>
                            </div>
                        </div>
                        <div className='col-3 text-end'>{selectedData.state?.value}{selectedData.uom}</div>
                    </div>
                }
            />
              {/* EXPORT ALL DATA MODAL */}
            <DataExportModal
            isOpen={isExportDataOpen}
            setIsOpen={setIsExportDataOpen}
            title={t('main.exportAllDataByHour')}
            onExportAll={onExportAllData}
            />
        </Layout>
    );
}

export default RuleEntitiesList;
