import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Input} from "reactstrap";
import {Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import Map from "./Map";


const MapWithPolygon = (props) => {

    const {t} = useTranslation();

    const onChangeHandlerPlaces = (e, index) => {
        const {name, value} = e.target;

        let oldPaths = JSON.parse(JSON.stringify(props.formState.paths))
        oldPaths[index][name] = Number(value)
        props.setFormState({
            ...props.formState,
            paths: oldPaths
        })
    }

    const addPath = (e) => {
        props.setFormState({
            ...props.formState,
            paths: e
        })
        console.log(e)
    }

    const deletePath = (id) => {
        let oldPaths = JSON.parse(JSON.stringify(props.formState.paths))
        oldPaths.splice(id, 1)
        props.setFormState({
            ...props.formState,
            paths: oldPaths
        })
    }

    const onDragEnd = (e) => {
        const {destination, source, draggableId} = e;

        if (!destination) {
            return;
        } else if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        } else {
            const newData = JSON.parse(JSON.stringify(props.formState.paths));
            const element = props.formState.paths[Number(draggableId)]
            newData.splice(source.index, 1);
            newData.splice(destination.index, 0, element);

            props.setFormState({
                ...props.formState,
                paths: newData
            })
        }
    }

    return (
        <Row className='mt-3'>
            <div className='mt-3 col-md-6'>
                <Map
                    systemPolygon={props.systemPolygon}
                    strokeColor={props.strokeColor}
                    addNewPaths={addPath}
                    polygonPaths={props.formState.paths}
                    center={props.formState.mapCenter}
                />
            </div>

            <div className='col-md-6 paths-container'>
                <DragDropContext onDragEnd={onDragEnd}>
                    <div>
                        <Droppable droppableId="paths-system">
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {props.formState.paths.map((item, index) => {
                                        return (
                                            <Draggable
                                                draggableId={index.toString()}
                                                index={index}
                                                key={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        key={`paths-${index}`}
                                                        className='d-flex mt-2'
                                                    >
                                                        <div
                                                            className='d-flex align-items-center justify-content-center'>
                                                            <i {...provided.dragHandleProps}
                                                               style={{
                                                                   border: '1px solid #DA1021',
                                                                   borderRadius: '4px',
                                                                   padding: '5px 5px',
                                                                   color: '#DA1021',
                                                                   cursor: 'pointer'
                                                               }}
                                                               className='bx bx-menu me-4'/>
                                                        </div>
                                                        <div className='me-2' style={{maxWidth: '150px'}}>
                                                            <Input
                                                                type='number'
                                                                name='lat'
                                                                id={'lat-' + index}
                                                                className='form-control'
                                                                value={item.lat}
                                                                onChange={(e) => onChangeHandlerPlaces(e, index)}
                                                                placeholder={t("systems.form2.field3.placeholder1")}
                                                            />
                                                        </div>
                                                        <div className='me-3' style={{maxWidth: '150px'}}>
                                                            <Input
                                                                type='number'
                                                                name='lng'
                                                                id={'lng-' + index}
                                                                className='form-control'
                                                                value={item.lng}
                                                                onChange={(e) => onChangeHandlerPlaces(e, index)}
                                                                placeholder={t("systems.form2.field3.placeholder2")}
                                                            />
                                                        </div>
                                                        <div
                                                            className='d-flex align-items-center justify-content-center'>
                                                            <i
                                                                onClick={() => deletePath(index)} style={{
                                                                padding: '5px 5px',
                                                                color: '#DA1021',
                                                                cursor: 'pointer'
                                                            }} className='bx bx-trash'/>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </DragDropContext>
            </div>
        </Row>
    )
}

export default MapWithPolygon;