import React from "react";
import {useTranslation} from "react-i18next";

import Map from "../common/Map";
import { config } from '../../config';


const Plant = (props) => {

    const TARGET_CODE = config.TARGET_CODE;
    const {t} = useTranslation();

    return (

        <div className='pb-3'>
            <div className='view-entity-container'>
                <div>
                    <div>{t("plants.form.field1.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.name}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("plants.form.field2.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.category}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("plants.form.field3.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.species}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("plants.form.field4.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.systemArea}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("plants.form.vegetation_min_temp.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.vegetationMinTemp}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("plants.form.vegetation_max_temp.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.vegetationMaxTemp}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("plants.form.water_ec_max.name")}</div>
                    <div>
                        <div className='form-control'>{props.formState.waterEcMax}</div>
                    </div>
                </div>

                <div className='mt-3'>
                    <div>{t("plants.form.notes.name")}</div>
                    <div>
                        <div className='form-control' style={{minHeight:'70px'}}>{props.formState.notes}</div>
                    </div>
                </div>

                <div className={props.role === 'customer' || props.role === 'guest' ? 'mt-3 col-md-6' : 'mt-3'}>
                    <div>{t("devices.form.field6.name")}</div>
                    <div>
                        <Map
                            isView={true}
                            marker={props.formState.geoLocation ? props.formState.geoLocation : null}
                            systemPolygon={
                                (props.systemPolygon && props.systemPolygon.length) ?
                                    props.systemPolygon.map((item)=>{
                                        return{
                                            lat: item[0],
                                            lng: item[1]
                                        }
                                    })
                                    : null
                            }
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}


export default Plant;