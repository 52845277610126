import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useHistory, useParams} from "react-router-dom";
import {Button, Card, CardBody, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";

import Layout from "../../layout/layout";
import SystemPLTView from "../../components/formViews/SystemPLT";
import SystemVLMView from "../../components/formViews/SystemVLM";
import Breadcrumb from "../../components/common/Breadcrumb";
import request from "../../helpers/requestHelper";

import {config} from '../../config';
import {getSystemData} from "../../redux/actions/systemActions";


const SystemView = (props) => {

    const TARGET_CODE = config.TARGET_CODE;

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const available = useSelector((el) => el.systemData.availableList)
    const systemData = useSelector((el) => el.systemData.systemData)
    const role = useSelector((state) => state.authData?.userDetails?.role)

    const [formState, setFormState] = useState({
        name: '',
        customer: '',
        description: '',
        supervisor: '',
        systemToken: '',
        topView: null,
        sideView: null,
        paths: [],
        mapCenter: null,
        manufacturer: '',
        code: '',
        category: '',
        visibility: '',
        geoLocation: '',
    })

    useEffect(() => {
        if (available.filter((el) => el._id === params.systemId)) {
            dispatch(getSystemData(params.systemId))
        } else {
            history.push('/systems')
        }
    }, [])

    useEffect(() => {
        if (systemData) {
            let data = systemData;
            let commonData = {};

            commonData.name = data.name;
            commonData.description = data.description;
            commonData.supervisor = data.supervisor?.fullname;
            commonData.customer = data.customer?.fullname;
            commonData.systemToken = data.localtoken;

            if (TARGET_CODE === 'PLT') {
                setFormState({
                    ...commonData,
                    visibility: data.metadata?.public ? 'public' : 'private',
                    paths: data.metadata?.geoposition?.coordinates[0]
                })

            } else {
                let savingData = {
                    ...formState,
                    ...commonData,
                    manufacturer: data.metadata?.manufacturer,
                    code: data.metadata?.code,
                    category: data.metadata?.category,
                    topView: data.metadata?.topview,
                    sideView: data.metadata?.sideview,
                }
                setFormState(savingData)
                getPicture(data.metadata?.topview, data.metadata?.sideview, savingData);
            }
        }
    }, [systemData])

    const getPicture = async (topView, sideView, savingData) => {

        for (let i = 0; i < 2; i++) {
            const res = await request({
                url: `core/ui/systems/files/boatviews/${i === 0 ? topView : sideView}`,
                auth: true,
                method: 'GET',
                responseType: 'blob'
            });
            let objectURL = URL.createObjectURL(res.data)
            if(i === 0){
                topView = objectURL
            } else {
                sideView = objectURL;
            }
        }
        setFormState({
            ...savingData,
            topView: topView,
            sideView: sideView
        })
    }

    const copyContents = () => {
        navigator.clipboard.writeText(formState.systemToken);
    }

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t(formState.name)}
                    breadcrumbItems={[
                        {path: "systems", name: t('systems.breadcrumb1')},
                        {path: `system/view/${params.systemId}`, name: t(formState.name)},
                    ]}
                />
                <Card>
                    <CardBody>
                        <div className='view-entity-container'>
                            <Row>
                                <div className='fw-bold'>{t('systems.form1.card.topic')} *</div>
                            </Row>
                            <Row>
                                <div className='fw-light text-muted mt-1 mb-3'>{t('systems.form1.card.subTopic')}.</div>
                            </Row>

                            <div className='row mt-0 mb-0'>
                                <div className='col-md-6'>
                                    <div>{t("systems.form1.field1.name")}</div>
                                    <div>
                                        <div className='form-control'>{formState.name}</div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div>{t("systems.form1.field2.name")}</div>
                                    <div>
                                        <div className='form-control form-select'>{formState.customer}</div>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-0 mb-0'>
                                <div className='col-md-6'>
                                    <div>{t("systems.form1.field3.name")}</div>
                                    <div>
                                        <div className='form-control'>{formState.description}</div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div>{t("systems.form1.field4.name")}</div>
                                    <div>
                                        <div className='form-control form-select'>{formState.supervisor}</div>
                                    </div>
                                </div>
                            </div>


                            {role === 'admin' &&
                                <div className='row mt-0 mb-0'>
                                    <div className='col-md-6'>
                                        <div>{t("systems.form1.field5.name")}</div>
                                        <div style={{position: 'relative'}}>
                                            <div className='form-control'>{formState.systemToken}</div>
                                            <i style={{
                                                position: 'absolute',
                                                right: '8px',
                                                top: '11px',
                                                cursor: 'pointer'
                                            }}
                                               onClick={copyContents} className='bx bxs-copy bx-xs'></i>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        {TARGET_CODE == 'PLT' ?
                            <SystemPLTView formState={formState}/>
                            :
                            <SystemVLMView formState={formState}/>
                        }
                    </CardBody>
                </Card>

                <div className='col-12 pb-5 text-end mt-3 form-btn-grp'>
                    <Link to={`/systems`}><Button type='button'
                                                                                            className='btn-secondary'>{t('main.back')}</Button></Link>
                </div>

            </div>
        </Layout>
    )
}

export default SystemView;