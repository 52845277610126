import {ReactComponent as Droplet} from '../assets/icons/droplet.svg'
import {ReactComponent as Bulb} from '../assets/icons/lightbulb.svg'
import {ReactComponent as Temperature} from '../assets/icons/temperature.svg'
import {ReactComponent as Bolt} from '../assets/icons/bolt.svg'
import { ReactComponent as CircleIcon } from '../assets/icons/circleloaderfive.svg'

const DEFAULT_ICON = <CircleIcon/>

const deviceIcons = {
    "temperature-sensor": () => <Temperature/>,
    "humidity-sensor": () => <Droplet/>,
    "soil-moisture-sensor": () => <Droplet/>,
    "switch": (state) => <Bolt className={state === 'on' ? 'text-new-primary-svg' : 'text-semi-dark-svg'}/>,
    "light": (state) => <Bulb className={state === 'on' ? 'text-new-primary-svg' : 'text-semi-dark-svg'}/>,
}

function getDeviceIcon(type, stateValue) {
    try {
        type = type.toLowerCase().trim();
        stateValue = stateValue?.toLowerCase().trim() || "";

        return deviceIcons[type] ? deviceIcons[type](stateValue) : DEFAULT_ICON;   
    } catch (error) {
        return DEFAULT_ICON;
    }
}

export default getDeviceIcon;