import React, {useState, useEffect} from "react";
import {Card, CardBody, Button} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useParams, Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import SearchBar from "../../components/others/SearchBar";
import TableFirst from "../../components/tables/TableFirst";
import Layout from "../../layout/layout";
import Breadcrumb from "../../components/common/Breadcrumb";
import DeleteModal from "../../components/modals/DeleteModal";
import DriverRuleModal from "../../components/modals/DriverRuleModal";

import {deleteDriver, getAvailableDrivers, getDriverList} from "../../redux/actions/driverActions";
import capitalizeFirstLetter from "../../helpers/capitalizeHelper";
import moment from "moment";

const DriversList = () => {

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const available = useSelector((el) => el.systemData.availableList)
    const driverData = useSelector((el)=> el.driverData)
    const drivers = useSelector((el)=> el.driverData.availableDrivers)

    const [deleteId, setDeleteId] = useState(null)
    const [addModalShow, setAddModalShow] = useState(false)
    const [driverSearch, setDriverSearch] = useState()

    useEffect(() => {
        if(!available.some((el) => el._id === params.systemId)){
            console.log('huhhuhuh')
            history.push(`/systems`)
        } else {
            dispatch(getDriverList(params.systemId,1, null));
            dispatch(getAvailableDrivers());
        }
    }, [location.pathname])

    const getDataList=(page)=>{
        dispatch(getDriverList(params.systemId, page, driverSearch ? driverSearch : null))
    }

    const onDeleteDriver=()=>{
        let id = deleteId;
        setDeleteId(null)
        dispatch(deleteDriver(id, params.systemId))
    }

    const onDriverSearch=()=>{
        dispatch(getDriverList(params.systemId,1, driverSearch))
    }


    const tableColumns = [
        {
            key: "details",
            text: "",
            dataField: "name",
            headerStyle: {backgroundColor: "white"},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex">
                        <div className="me-3 d-flex align-items-center">
                            <i className="bx bxs-cog bx-sm text-semi-dark pe-4" style={{fontSize:'1.4rem'}}/>
                        </div>
                        <div className="row-details">
                            <div className='text-new-dark fw-bold font-size-13'>{row.name}</div>

                            <div className='fw-light text-semi-dark font-size-12'>{row.driver?.name}</div>
                            <div className='fw-light text-semi-dark font-size-12'>{t("main.lastUpdate")}: {moment(row.updatedAt).format('YY/MM/DD H:m')}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            key: "status",
            text: "",
            dataField: "status",
            headerStyle: {backgroundColor: "white"},
            style: {paddingRight: '200px'},
            formatter: (rowContent, row) => {
                return (
                    <div className="d-flex justify-content-center">
                        <div className='text-semi-dark'>{t("main.status")}:</div>
                        <div className='fw-bold ps-1'>{row.status? capitalizeFirstLetter(row.status?.state) : 'None'}</div>
                    </div>
                );
            },
        },
        {
            key: "edit-delete-save",
            text: "",
            dataField: "",
            style: {textAlign: "right"},
            headerStyle: {backgroundColor: "white", boxShadow: "0"},
            formatter: (rowContent, row) => {
                return (
                    <div className="text">
                        <Link to={`/system/${params.systemId}/driver/view/${row._id}`}><Button color="primary">{t("main.view")}</Button></Link>
                        <Link to={`/system/${params.systemId}/driver/edit/${row._id}`}><Button color="warning" className="ms-2">{t("main.edit")}</Button></Link>
                        <Button onClick={() => setDeleteId(row._id)} color="danger" className="ms-2">{t("main.delete")}</Button>
                    </div>
                );
            },
        },
    ];

    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={t('drivers.title')}
                    systemData={{id: params.systemId, name: available.find((el) => el._id === params.systemId)?.name}}
                    breadcrumbItems={[
                        {path: `system/${params.systemId}/dashboard`, name: t(available.find((el) => el._id === params.systemId)?.name)},
                        {path: `system/${params.systemId}/drivers`, name: t('drivers.breadcrumb1')}
                    ]}
                />
                <Card>
                    <CardBody>
                        <div>
                            <div className='font-size-15 fw-medium'>{t("drivers.pageTopic")}</div>
                            <div className='text-semi-dark mt-2 mb-4'>{t("drivers.pageSubTopic")}</div>
                        </div>
                        <div>
                            <SearchBar
                                name="configuration"
                                btnName={t("drivers.add")}
                                link={`system/${params.systemId}/driver`}
                                searchValue={driverSearch}
                                setSearchValue={setDriverSearch}
                                isAddModal={true}
                                setModal={setAddModalShow}
                                onSearch={onDriverSearch}
                            />
                        </div>
                        <TableFirst
                            dataList={driverData.driverList}
                            columns={tableColumns}
                            total={driverData.totalSize}
                            getDataList={getDataList}
                            containerClass={'header-less-table-container drivers-table-container'}
                        />
                    </CardBody>
                </Card>
            </div>

            <DriverRuleModal
                isDriver={true}
                options={drivers}
                modalShow={addModalShow}
                setModalShow={setAddModalShow}
            />

            <DeleteModal
                onDelete={onDeleteDriver}
                modalShow={!!deleteId}
                setModalShow={setDeleteId}
            />

        </Layout>
    );
}

export default DriversList;
