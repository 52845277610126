import React from 'react'
import { Row, Col, Modal, ModalHeader, Input, Button } from 'reactstrap' 

import OverlaySpinner from '../../common/OverlaySpinner';
import {useTranslation} from "react-i18next";


const AlarmSensorModal=(props)=>{

  const {t} = useTranslation();

  const onChangeHandler=(e, item)=>{
    const {name, value} = e.target;
    let DataArray = JSON.parse(JSON.stringify(props.editingConfigurations))

    if(item.notSavedId){
      let index = DataArray.findIndex(el => el.notSavedId === item.notSavedId);
      DataArray[index][name] = Number(value) ? Number(value) : value;

      if(name === 'type' && value === 'threshold')
          DataArray[index].hours = null;
    }
    else{
      if(DataArray.find((el)=> el._id === item._id)){
        let index = DataArray.findIndex(el => el._id === item._id);
        DataArray[index][name] = Number(value) ? Number(value) : value;

        if(name === 'type' && value === 'threshold')
          DataArray[index].variationData = null;
      }
      else{
        let item2 = JSON.parse(JSON.stringify(item));
        item2[name] = Number(value) ? Number(value) : value;

        if(name === 'type' && value === 'threshold'){
          item2.hours = null;
        }
        DataArray.push(item2);
      }
    }

    props.setEditingConfigurations(DataArray);
  }

  const AddNewRow=()=>{
    let DataArray = JSON.parse(JSON.stringify(props.editingConfigurations))
    DataArray.push({
      'type':'threshold', 'value':'', 'condition':'gt', 'level':'info', 'hours':null, 'notSavedId':DataArray.filter((el)=>el.notSavedId).length + 1
    })
    props.setEditingConfigurations(DataArray);    
  }

  return(
      <Modal
        isOpen={props.alarmModal.isOpen}
        role="dialog"
        autoFocus={true}
        tabIndex="-1"
        data-toggle="modal" 
        size='lg' 
        className='alert-modal'    
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <ModalHeader><div className='font-size-22 fw-normal'>{t("dashboard.alertConfig.topic")} "{props.alarmModal.name}"</div></ModalHeader>
          </div>
          <div className="modal-body pt-0">
            <div style={{minHeight:'400px', maxHeight:400, overflowY:'scroll'}}>
              <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>                  
                      <th className="align-middle">#</th>
                      <th className="align-middle">{t("dashboard.alertConfig.header2")}</th>
                      <th className="align-middle">{t("dashboard.alertConfig.header4")}</th>
                      <th className="align-middle">{t("dashboard.alertConfig.header3")}</th>
                      <th className="align-middle">{t("dashboard.alertConfig.header5")}</th>
                      <th className="align-middle">{t("dashboard.alertConfig.header6")}</th>
                      <th className="align-middle">{t("dashboard.alertConfig.header7")}</th>
                    </tr>
                  </thead>
                  <tbody style={{position:'relative'}}>
                    <OverlaySpinner isLoading={props.isLoading} tableLoader={true}/>
                    {props.alertsConfigurations ? props.alertsConfigurations.concat(props.editingConfigurations.filter((el)=> el.notSavedId)).map((el, key) => {
                      
                      if(el){
                        let item;

                        if(el.notSavedId){
                          item = props.editingConfigurations.find((el2)=> el2.notSavedId === el.notSavedId);
                        }
                        else{
                          if(props.editingConfigurations.find((el2)=> el2._id === el._id))
                            item = props.editingConfigurations.find((el2)=> el2._id === el._id);
                          else
                            item = el;
                        }

                        return (
                            <tr key={"_tr_" + key}>                    
                              <td>
                                  #{key + 1}
                              </td>
                              <td style={{minWidth:130}}>
                                <Input
                                  type='select'
                                  name='type'
                                  id={'type' +key}
                                  value={item.type}
                                  className='form-select form-control'
                                  onChange={(e)=>{onChangeHandler(e,item)}}
                                >
                                  <option value='threshold'>{t("dashboard.alertConfig.type.value1")}</option>
                                  <option value='variation'>{t("dashboard.alertConfig.type.value2")}</option>
                                </Input>
                              </td>                              
                              <td style={{minWidth:135}}>
                                <Input
                                  type='select'
                                  name='condition'
                                  id={'condition' +key}
                                  value={item.condition}
                                  className='form-select form-control'
                                  onChange={(e)=>{onChangeHandler(e,item)}}                          
                                >
                                  <option value='gt'>{t("dashboard.alertConfig.condition.value1")}</option>
                                  <option value='lt'>{t("dashboard.alertConfig.condition.value2")}</option>
                                </Input>
                              </td>                    
                              <td style={{minWidth:50}}>
                                <Input
                                  type='text'
                                  name='value'
                                  id={'value' +key}
                                  value={item.value}
                                  className='form-control'
                                  style={{maxWidth:100}}
                                  onChange={(e)=>{onChangeHandler(e,item)}}                          
                                />                          
                              </td>
                              <td style={{minWidth:115}}>
                                <Input
                                  type='select'
                                  name='level'
                                  id={'level' +key}
                                  value={item.level || "info"}
                                  className='form-select form-control'
                                  onChange={(e)=>{onChangeHandler(e,item)}}                          
                                >
                                  <option value='info'>{t("dashboard.alertConfig.level.value1")}</option>
                                  <option value='warning'>{t("dashboard.alertConfig.level.value2")}</option>
                                  <option value='danger'>{t("dashboard.alertConfig.level.value3")}</option>
                                </Input>
                              </td>
                              <td>
                                {item.type === 'variation' ? 
                                  <Row style={{minWidth:120}}>
                                    <Col style={{marginTop:'10px'}} className='pe-1 ps-1'>{t("dashboard.alertConfig.options.value")}</Col>
                                    <Input
                                    type='text'
                                    name='hours'
                                    id={'hours' +key}
                                    value={item.hours ? item.hours : null}
                                    className='form-control'
                                    style={{maxWidth:60}}
                                    onChange={(e)=>{onChangeHandler(e,item)}}                            
                                  />  
                                  </Row>
                                : 
                                  '' 
                                }
                              </td>                              
                              <td style={{minWidth:170}}>                          
                                <Button disabled={(!item.value || (item.type === 'variation' && (!item.hours))) ? true : false} onClick={()=>{props.handleConfigurationSave(item)}} className='btn-success me-2 pt-1 pb-1 pe-2 ps-2'>{t("main.save")}</Button>
                                <Button onClick={()=>{props.handleConfigurationDelete(item)}} className='btn-danger pt-1 pb-1 pe-2 ps-2'>{t("main.delete")}</Button>
                              </td>
                            </tr>
                        )
                      }})
                      :''}
                  </tbody>
                </table>
              </div>
              <Row className='mt-3'>
                <Col>
                  <Button onClick={AddNewRow} color='primary'>{t("dashboard.alertConfig.addNew")}</Button>
                </Col>
              </Row>
            </div>
            <Row className='text-end mt-2'>
              <Col>
                <Button onClick={()=>{
                  props.setAlarmModal({
                    name:'',
                    sensor: null,
                    isOpen:false
                  })
                  props.setEditingConfigurations([])
                }} color='danger'>{t("main.back")}</Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
  )
}

export default AlarmSensorModal;
