import {userActionTypes} from "../actionTypes/index";
import produce from "immer";

const initialState = {
    availableSupervisors:[],
    availableCustomers:[],
    userList: [],
    userData: null,
    totalSize: 0
};

const systemData = produce(
    (state = initialState, action) => {
        const { type, payload } = action;

        switch (type) {

            case userActionTypes.GET_AVAILABLE_SUPERVISORS_SUCCEED: {
                state.availableSupervisors = payload;
                return state;
            }
            case userActionTypes.GET_AVAILABLE_CUSTOMERS_SUCCEED: {
                state.availableCustomers = payload;
                return state;
            }
            case userActionTypes.GET_USER_LIST_SUCCEED: {
                state.userList = payload.results;
                state.totalSize = payload.totalResults;
                return state;
            }
            case userActionTypes.GET_USER_DATA_SUCCEED: {
                state.userData = payload;
                return state;
            }
            case userActionTypes.CREATE_USER_SUCCEED: {
                state.userList.push(payload);
                state.totalSize++;
                return state;
            }
            case userActionTypes.UPDATE_USER_SUCCEED: {
                const updatedId = state.userList.findIndex((item)=>item._id===payload._id);
                state.userList[updatedId]=payload;
                return state;
            }
            case userActionTypes.DELETE_USER_SUCCEED: {
                const deletedId = state.userList.findIndex((item)=>item._id===payload._id);
                state.userList.splice(deletedId,1);
                state.totalSize--;
                return state;
            }

            default: {
                return state;
            }
        }
    }
);

export default systemData;
