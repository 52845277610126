import React, {useEffect, useState} from "react";
import {Card, CardBody, Container} from "reactstrap";
import {useTranslation} from "react-i18next";
import {useHistory, useParams, Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "react-bootstrap";

import Layout from '../../layout/layout'
import Breadcrumb from "../../components/common/Breadcrumb";
import SystemBasic from "../../components/forms/SystemBasic";
import SystemVLM from "../../components/forms/SystemVLM";
import SystemPLT from "../../components/forms/SystemPLT";

import { config } from '../../config';
import request from "../../helpers/requestHelper";
import {addSystem, getSystemData, updateSystem} from "../../redux/actions/systemActions";
import {getAvailableCustomers, getAvailableSupervisors} from "../../redux/actions/userActions";

const SystemAddEdit = ({isEdit}) => {
    const TARGET_CODE = config.TARGET_CODE;

    const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const available = useSelector((el) => el.systemData.availableList)
    const systemData = useSelector((el) => el.systemData.systemData)
    const supervisors = useSelector((el) => el.userData.availableSupervisors)
    const customers = useSelector((el) => el.userData.availableCustomers)

    const [formState, setFormState] = useState({
        name: '',
        customer: '',
        description: '',
        supervisor: '',
        topView: null,
        sideView: null,
        paths: [],
        mapCenter: null,
        manufacturer:'',
        code:'',
        category:'',
        visibility:'',
        geoLocation:'',

    })
    const [preview, setPreview] = useState({
        topView: null,
        sideView: null
    })
    const [isValidated, setIsValidated] = useState(false)

    useEffect(() => {
        if (isEdit) {
            if (available.filter((el) => el._id === params.systemId)) {
                dispatch(getSystemData(params.systemId))
            } else {
                history.push('/systems')
            }
        }
        dispatch(getAvailableCustomers())
        dispatch(getAvailableSupervisors())
    }, [])

    useEffect(() => {
        if (systemData && isEdit) {
            let data = systemData;
            let commonData = {};

            commonData._id = data._id;
            commonData.name = data.name;
            commonData.description = data.description ?  data.description : '';
            commonData.supervisor = data.supervisor? data.supervisor?._id : '';
            commonData.customer = data.customer?._id;
            commonData.systemToken = data.localtoken;

            if (TARGET_CODE === 'PLT') {
                setFormState({
                    ...formState,
                    ...commonData,
                    visibility: data.metadata?.public ? 'public' : 'reserved',
                    paths: data.metadata?.geoposition?.coordinates[0].map((el)=>{
                        return{
                            lat: Number(el[0]),
                            lng: Number(el[1])
                        }
                    })
                })

            } else {
                let savingData = {
                    ...formState,
                    ...commonData,
                    manufacturer: data.metadata?.manufacturer,
                    code: data.metadata?.code,
                    category: data.metadata?.category ? data.metadata?.category : '',
                    topView: data.metadata?.topview,
                    sideView: data.metadata?.sideview,
                }

                setFormState(savingData)
                getPicture(data.metadata?.topview, data.metadata?.sideview, savingData);
            }
        }
    }, [systemData])

    useEffect(()=>{
        if(formState.name && formState.customer){
            if(TARGET_CODE === 'PLT'){
                if(formState.visibility && formState.paths.length){
                    setIsValidated(true)
                } else {
                    setIsValidated(false)
                }
            } else {
                if(formState.manufacturer && formState.code && formState.category && formState.topView && formState.sideView){
                    setIsValidated(true)
                } else {
                    setIsValidated(false)
                }
            }
        } else {
            setIsValidated(false)
        }
    }, [JSON.stringify(formState)])

    const getPicture = async (topView, sideView, savingData) => {

        for (let i = 0; i < 2; i++) {
            const res = await request({
                url: `core/ui/systems/files/boatviews/${i === 0 ? topView : sideView}`,
                auth: true,
                method: 'GET',
                responseType: 'blob'
            });
            let objectURL = URL.createObjectURL(res.data)
            if(i === 0){
                topView = objectURL
            } else {
                sideView = objectURL;
            }
        }
        setFormState({
            ...savingData,
            topView: topView,
            sideView: sideView
        })
    }

    const onSaveClick = (e) => {
        e.preventDefault();

        let data = {};
        data.name = formState.name;
        data.description = formState.description;
        data.customer = formState.customer;
        data.supervisor = formState.supervisor;

        let metadata = {};

        if(TARGET_CODE === 'PLT'){
            let mainCoordinates = [];
            let coordinates = [];
            for(let i=0; i<formState.paths.length; i++){
                let lat = formState.paths[i].lat;
                let lng = formState.paths[i].lng;
                coordinates.push([lat, lng])
            }
            mainCoordinates.push(coordinates)

            metadata.public = formState.visibility === 'public';
            metadata.geoposition = {
                type: 'Polygon',
                coordinates: mainCoordinates
            }
        } else {
            data.topview = formState.topView;
            data.sideview = formState.sideView;
            metadata.manufacturer = formState.manufacturer;
            metadata.code = formState.code;
            metadata.category = formState.category;
        }

        data.metadata = metadata;

        if(formState._id){
            data._id = formState._id
            dispatch(updateSystem(data)).then((res)=>{
                if(res){
                    history.push('/systems')
                }
            })
        } else {
            dispatch(addSystem(data)).then((res)=> {
                if (res) {
                    history.push('/systems')
                }
            })
        }
    }
console.log(formState)
    return (
        <Layout>
            <div>
                <Breadcrumb
                    title={isEdit ? t('systems.editSystem') : t('systems.addSystem')}
                    breadcrumbItems={[
                        {path: "systems", name: t('systems.breadcrumb1')},
                        {
                            path: isEdit ? `system/edit/${params.systemId}` : "system/add",
                            name: t(isEdit ? 'systems.breadcrumbEdit' : 'systems.breadcrumbAdd')
                        }
                    ]}
                />
                <form onSubmit={onSaveClick}>
                    <Card>
                        <CardBody>
                            <SystemBasic
                                formState={formState}
                                setFormState={setFormState}
                                isEdit={isEdit}
                                customers={customers}
                                supervisors={supervisors}
                            />
                        </CardBody>
                    </Card>
                    {TARGET_CODE == 'VLM' ?
                        <Card>
                            <CardBody>
                                <SystemVLM
                                    formState={formState}
                                    setFormState={setFormState}
                                    preview={preview}
                                    setPreview={setPreview}
                                />
                            </CardBody>
                        </Card>
                        :
                        <Card>
                            <CardBody>
                                <SystemPLT
                                    formState={formState}
                                    setFormState={setFormState}
                                />
                            </CardBody>
                        </Card>
                    }

                    <div className='col-12 pb-5 text-end mt-3 form-btn-grp'>
                        <Button disabled={!isValidated} color='primary' type='submit' className='me-2'>{t('main.save')}</Button>
                        <Link to={`/systems`}><Button type='button' className='btn-secondary'>{t('main.cancel')}</Button></Link>
                    </div>

                </form>
            </div>
        </Layout>
    )
}

export default SystemAddEdit;