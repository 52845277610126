import React, {useRef} from "react";
import {Row} from "react-bootstrap";
import {Input} from "reactstrap";
import {useTranslation} from "react-i18next";
import {FileDrop} from "react-file-drop";

import SearchSelect from '../common/SearchSelect'
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg'
import { categoryData } from '../../utils/appData'

const SystemVLM=(props)=>{

    const { t } = useTranslation();
    const fileInputRefTopView = useRef(null);
    const fileInputRefSideView = useRef(null);

    const onChangeHandler=(e, Name)=>{
        let name;
        let value;

        if(Name){
            name = Name;
            value = e.value
        } else {
            name = e.target.name;
            value = e.target.value;
        }

        if(value.length <= 50){
            props.setFormState({
                ...props.formState,
                [name]: value
            })
        }
    }

    const onFileInputChangeImg = (event, isTop) => {
        let name = isTop ? 'topView' : 'sideView'

        const { files } = event.target;
        props.setFormState({
            ...props.formState,
            [name]: files[0]
        })

        const fileReader = new FileReader()
        fileReader.onload = () => {
            props.setPreview({
                ...props.preview,
                [name]:fileReader.result
            })
        }
        fileReader.readAsDataURL(files[0])

    }

    const onFileDrop=(files, isTop) => {
        let name = isTop ? 'topView' : 'sideView'

        for (var i = 0; i < files.length; i++) {
            let exe = files[i].name.split('.').pop()
            if(exe === 'jpg' || exe === 'jpeg' || exe === 'png'){
                props.setFormState({
                    ...props.formState,
                    [name]: files[i]
                })

                const fileReader = new FileReader()
                fileReader.onload = () => {
                    props.setPreview({
                        ...props.preview,
                        [name]:fileReader.result
                    })
                }
                fileReader.readAsDataURL(files[i])
                break;
            }
        }
    }

    const onTargetClick = (isTop) => {
        if(isTop)
            fileInputRefTopView.current.click()
        else
            fileInputRefSideView.current.click()
    }

    return(

        <div className='pb-3'>
            <Row><div className='fw-bold mb-3'>{t('systems.form2.card.topic')}</div></Row>
            <Row>
                <div className='mt-3 col-md-6'>
                    <div><label className='mb-2 p-0' htmlFor='manufacturer'>{t('systems.form2.field1.name')} *</label></div>
                    <div className='form-limitation-container'>
                        <Input
                            type='text'
                            name='manufacturer'
                            id='manufacturer'
                            className='form-control'
                            value={props.formState.manufacturer}
                            onChange={onChangeHandler}
                            required
                        />
                        <div>{props.formState.manufacturer.length}/50</div>
                    </div>
                </div>

                <div className='mt-3 col-md-6'>
                    <div><label className='mb-2 p-0' htmlFor='code'>{t('systems.form2.field2.name')} *</label></div>
                    <div className='form-limitation-container'>
                        <Input
                            type='text'
                            name='code'
                            id='code'
                            className='form-control'
                            value={props.formState.code}
                            onChange={onChangeHandler}
                            required
                        />
                        <div>{props.formState.code.length}/50</div>
                    </div>
                </div>
            </Row>

            <Row>
                <div className='mt-3 col-md-6'>
                    <div><label className='mb-2 p-0' htmlFor='category'>{t('systems.form2.field3.name')} *</label></div>
                    <div>
                        <SearchSelect
                            name="category"
                            placeholder={t('systems.form2.field3.placeholder')}
                            data={categoryData}
                            value={{
                                label: props.formState.category,
                                value: props.formState.category
                            }}
                            onChangeHandler={(e)=> onChangeHandler(e, 'category')}
                        />
                    </div>
                </div>
            </Row>

            <Row>
                <div className='mt-3 col-md-6'>
                    <div><label className='mb-3 p-0 fw-semi-bold' htmlFor='topView'>{t('systems.form2.field4.name')} *</label></div>
                    <div>
                        <input
                            type='file'
                            name='topView'
                            id='topView'
                            accept='.jpg, .jpeg, .png'
                            ref={fileInputRefTopView}
                            onChange={(e) => onFileInputChangeImg(e, true)}
                            style={{display:'none'}}
                        />
                        {(props.formState.topView && props.preview.topView) ?
                            <FileDrop
                                onTargetClick={() => onTargetClick(true)}
                                onDrop={(e) => onFileDrop(e, true)}
                            >
                                <div>
                                    <img style={{maxHeight:'165px'}} src={props.preview.topView} />
                                </div>
                            </FileDrop>

                            :

                            props.formState.topView ?
                                <FileDrop
                                    onTargetClick={() => onTargetClick(true)}
                                    onDrop={(e) => onFileDrop(e, true)}
                                >
                                    <div>
                                        <img style={{maxHeight:'165px'}} src={props.formState.topView} />
                                    </div>
                                </FileDrop>
                                :
                                <FileDrop
                                    onTargetClick={() => onTargetClick(true)}
                                    onDrop={(e) => onFileDrop(e, true)}
                                >
                                    <div>
                                        <div className='text-center'><UploadIcon/></div>
                                        <div>{t('systems.form2.dragMsg')}</div>
                                    </div>
                                </FileDrop>
                        }
                    </div>
                </div>

                <div className='mt-3 col-md-6'>
                    <div><label className='mb-3 p-0 fw-semi-bold' htmlFor='sideView'>{t('systems.form2.field5.name')} *</label></div>
                    <div>
                        <input
                            type='file'
                            name='sideView'
                            id='sideView'
                            accept='.jpg, .jpeg, .png'
                            ref={fileInputRefSideView}
                            onChange={(e) => onFileInputChangeImg(e, false)}
                            style={{display:'none'}}
                        />
                        {(props.formState.sideView && props.preview.sideView) ?
                            <FileDrop
                                onTargetClick={() => onTargetClick(false)}
                                onDrop={(e) => onFileDrop(e, false)}
                            >
                                <div>
                                    <img style={{maxHeight:'165px'}} src={props.preview.sideView} />
                                </div>
                            </FileDrop>

                            :

                            props.formState.sideView ?
                                <FileDrop
                                    onTargetClick={() => onTargetClick(false)}
                                    onDrop={(e) => onFileDrop(e, false)}
                                >
                                    <div>
                                        <img style={{maxHeight:'165px'}} src={props.formState.sideView} />
                                    </div>
                                </FileDrop>
                                :
                                <FileDrop
                                    onTargetClick={() => onTargetClick(false)}
                                    onDrop={(e) => onFileDrop(e, false)}
                                >
                                    <div>
                                        <div className='text-center'><UploadIcon/></div>
                                        <div>{t('systems.form2.dragMsg')}</div>
                                    </div>
                                </FileDrop>
                        }
                    </div>
                </div>
            </Row>

        </div>
    )
}


export default SystemVLM;