import React from "react";
import {Button, Row} from "react-bootstrap";
import {Input} from "reactstrap";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Editor} from "../common/Editor";
import capitalizeFirstLetter from "../../helpers/capitalizeHelper";
import jsYaml from "js-yaml";

const DriverForm = (props) => {

    const {t} = useTranslation();
    const params = useParams();

    const onChangeHandler = (e) => {
        const {name, value} = e.target;

        if (value.length <= 50 || (name === 'configDescription' && value.length <= 500) || (name === 'parameters')) {
            props.setFormState({
                ...props.formState,
                [name]: value
            })
        }
    }

    const setSample = () => {
        props.setFormState({
            ...props.formState,
            parameters: jsYaml.dump(props.formState?.driver?.driverparams_sample)
        })
    }

    return (
        <Row>
            <div className='view-entity-container'>
                <Row>
                    <div className='fw-bold'>{t('drivers.form.card.topic')}</div>
                </Row>
                <Row>
                    <div className='fw-light text-muted mt-1 mb-2'>{t('drivers.form.card.subTopic')}.</div>
                </Row>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='driverName'>{t('drivers.form.field1.name')}</label></div>
                    <div className='form-control disabled'
                         style={{minHeight: '37px'}}>{props.formState.driver?.name}</div>
                </div>

                <div className='mt-3 col-12'>
                    <div><label className='mb-2 p-0' htmlFor='driverDescription'>{t('drivers.form.field2.name')}</label>
                    </div>
                    <div className='form-control'
                         style={{minHeight: '64px'}}>{props.formState.driver?.description}</div>
                </div>

            </div>

            <form onSubmit={props.onSaveClick}>
                <Row>

                    <div className="mt-4 col-12">
                        <div className='fw-bold'>{t("drivers.form.card.config.topic")}</div>
                        <div className='fw-light text-muted mt-1 mb-2'>{t("drivers.form.card.config.subTopic")}</div>
                    </div>

                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0"
                                    htmlFor="configName">{t('drivers.form.field3.name')} {!props.isDisabled && '*'}</label>
                        </div>
                        <div className='form-limitation-container'>
                            <Input
                                type="text"
                                name="configName"
                                id="configName"
                                className="form-control"
                                maxLength='500'
                                value={props.formState.configName}
                                onChange={onChangeHandler}
                                placeholder={t('drivers.form.field3.placeholder')}
                                disabled={props.isDisabled}
                                required
                            />
                            <div>{props.formState.configName.length}/50</div>
                        </div>
                    </div>
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0"
                                    htmlFor="configDescription">{t('drivers.form.field4.name')}</label></div>
                        <div className='form-limitation-container'>
                            <Input
                                type="textarea"
                                name="configDescription"
                                id="configDescription"
                                className="form-control"
                                rows='3'
                                value={props.formState.configDescription}
                                onChange={onChangeHandler}
                                placeholder={props.isDisabled ? "" : t('drivers.form.field4.placeholder')}
                                disabled={props.isDisabled}
                            />
                            <div>{props.formState.configDescription.length}/500</div>
                        </div>
                    </div>
                    <div className="mt-3 col-12">
                        <div><label className="mb-2 p-0"
                                    htmlFor="parameters">{t('drivers.form.field5.name')} {!props.isDisabled && '*'}</label>
                        </div>
                        <div>
                            <Editor
                                setSample={setSample}
                                isDisabled={props.isDisabled}
                                name='parameters'
                                value={props.formState.parameters}
                                onChangeHandler={onChangeHandler}
                            />
                        </div>
                    </div>

                    {props.isDisabled &&
                        <div className="mt-3 col-12">
                            <div className='d-flex'>
                                <div className='me-1'>{t("drivers.view.status")}:</div>
                                <div
                                    className='fw-medium'>{props.formState.status && capitalizeFirstLetter(props.formState.status?.state)}</div>
                            </div>
                            {(props.formState.status && props.formState.status.errormessages && props.formState.status.errormessages.length) &&
                                <div>
                                    <div>{t("drivers.view.messages")}:</div>
                                    <ul className='mt-1'>
                                        {props.formState.status.errormessages.map((el) => {
                                            return (
                                                <li className='fw-light'>{el}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            }
                        </div>
                    }

                </Row>

                <div className='col-12 text-end mt-3 form-btn-grp'>
                    {!props.isDisabled &&
                        <Button disabled={!props.formState.parameters || !props.formState.configName} color="primary"
                                type="submit" className="me-2">{t("main.save")}</Button>}
                    <Link to={`/system/${params.systemId}/drivers`}>
                        <Button type="button"
                                className="btn-secondary">{props.isDisabled ? t("main.back") : t("main.cancel")}</Button>
                    </Link>
                </div>

            </form>
        </Row>
    )
}

export default DriverForm;