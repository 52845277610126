import {orchardRegisterActionTypes} from '../actionTypes/index';

import request from '../../helpers/requestHelper';


export const getOrchardRegisterList = (id, page, search) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.GET_ORCHARD_REGISTER_LIST_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries?system=${id}&page=${page}&limit=5&${search ? 'searchText=' + search : ''}`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: orchardRegisterActionTypes.GET_ORCHARD_REGISTER_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.GET_ORCHARD_REGISTER_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getPlantOrchardRegisterList = (plant, id, page) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.GET_PLANT_ORCHARD_REGISTER_LIST_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries?system=${id}&plant=${plant}&page=${page}&limit=5`,
            auth: true,
            method: 'GET',
        });
        dispatch({
            type: orchardRegisterActionTypes.GET_PLANT_ORCHARD_REGISTER_LIST_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.GET_PLANT_ORCHARD_REGISTER_LIST_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const getOrchardRegisterData = (id, systemId) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.GET_ORCHARD_REGISTER_DATA_REQUEST})

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries/${id}?system=${systemId}`,
            auth: true,
            method: 'GET',
        });

        dispatch({
            type: orchardRegisterActionTypes.GET_ORCHARD_REGISTER_DATA_SUCCEED,
            payload: res
        });
    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.GET_ORCHARD_REGISTER_DATA_FAILED,
            payload: err ? err.data : null
        });
    }
}

export const addOrchardRegister = (Data, id) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.CREATE_ORCHARD_REGISTER_REQUEST});

    let data = new FormData();

    data.append('title', Data.title);
    data.append('plants', JSON.stringify(Data.plants));
    data.append('systemareas', JSON.stringify(Data.systemareas));
    data.append('date', Data.date);
    data.append('notes', Data.notes);

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries?system=${id}`,
            auth: true,
            method: 'POST',
            data: data
        });

        dispatch({
            type: orchardRegisterActionTypes.CREATE_ORCHARD_REGISTER_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.CREATE_ORCHARD_REGISTER_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const updateOrchardRegister = (Data, systemId) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.UPDATE_ORCHARD_REGISTER_REQUEST});

    let data = new FormData();

    data.append('title', Data.title);
    data.append('plants', JSON.stringify(Data.plants));
    data.append('systemareas', JSON.stringify(Data.systemareas));
    data.append('date', Data.date);
    data.append('notes', Data.notes);

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries/${Data._id}?system=${systemId}`,
            auth: true,
            method: 'PATCH',
            data: data
        });

        dispatch({
            type: orchardRegisterActionTypes.UPDATE_ORCHARD_REGISTER_SUCCEED,
            payload: res
        });

        return true;

    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.UPDATE_ORCHARD_REGISTER_FAILED,
            payload: err ? err.data : null
        });

        return false;
    }
}

export const deleteOrchardRegister = (id, systemId) => async dispatch => {
    dispatch({type: orchardRegisterActionTypes.DELETE_ORCHARD_REGISTER_REQUEST});

    try {
        const res = await request({
            url: `plantsystem/ui/registerentries/${id}?system=${systemId}`,
            auth: true,
            method: 'DELETE',
        });

        dispatch({
            type: orchardRegisterActionTypes.DELETE_ORCHARD_REGISTER_SUCCEED,
            payload: res
        });

    } catch (err) {
        dispatch({
            type: orchardRegisterActionTypes.DELETE_ORCHARD_REGISTER_FAILED,
            payload: err ? err.data : null
        });
    }
}
