import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'

import App from './App';
import store from './redux/store';

import "./assets/scss/theme.scss"
import './i18n';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
      <Suspense fallback={<div />}>
          <Provider store={store}>
            <App />
          </Provider>
      </Suspense>,
  document.getElementById('root')
);

